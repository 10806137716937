import { withRouter } from 'react-router-dom';// Import Pages Elements
import Map01 from '../../page_elements/maps/Map01'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import TableTitle from '../../components/TableTitle'
// Component
const Country = (props) => {

  const RegionClick = (e, el) => {
    setTimeout(()=> { Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el) => { el.style.display = 'none' }); },100);
    if (el === 'AM') {
      props.history.push('/country-profiles/armenia')
    }
    if (el === 'AZ') {
      props.history.push('/country-profiles/azerbaijan')
    }
    if (el === 'BA') {
      props.history.push('/country-profiles/bosnia-and-erzegovina')
    }
    if (el === 'GE') {
      props.history.push('/country-profiles/georgia')
    }
    if (el === 'KG') {
      props.history.push('/country-profiles/kyrgyz-republic')
    }
    if (el === 'MK') {
      props.history.push('/country-profiles/republic-of-north-macedonia')
    }
    if (el === 'ME') {
      props.history.push('/country-profiles/montenegro')
    }
    if (el === 'TJ') {
      props.history.push('/country-profiles/tajikistan')
    }
    if (el === 'TR') {
      props.history.push('/country-profiles/turkey')
    }
  }


  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="COUNTRY PROFILES" />
      <article>
        <PageTitle title="Availability of relevant Child and Adolescent Strategy and policies in 9 countries" />
        <div className="article_content">
            <TableTitle
              title={'Map 2'}
              description={ 'Availability of Child and Adolescent Strategies and Policies on unhealthy foods in schools and regulations on marketing to children in 2017' }
            />
          <Map01 RegionClick = { RegionClick }/>
          <div className="my-2 border border-solid border-txt_black_2 text-xs px-2 py-1">
            Select country to view Country Profile.
          </div>
        </div>
      </article>
    </div>
  ) 
}

export default withRouter(Country)
