const Table07_2 = () => {
  return (    
    <table className="allBorders table-auto w-full text-left border border-main_blue xs:text-xs">
      <thead>
        <tr className="text-white">
            <th className="bg-main_blue">Policy/programme document</th>
            <th className="bg-header1">Targets/indicators with specific mention of children aged 7-18 years</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Policies / Strategies</span></td>
        </tr>
        <tr>
          <td>
            <p>Policy for improving child nutrition in the Federation of Bosnia and Herzegovina</p>
            <p className="text-txt_black_1">Government of the Federation of Bosnia and Herzegovina</p>
            <p className="text-txt_black_1">February 2013</p>
          </td>
          <td>
            <p>The policy has special emphasis on pregnant women, nursing mothers, children under five years of age, and children from marginalised groups.</p>
            <p>The policy forms the framework for the development of a Strategy for Improvement of Feeding and Nutritional Status of Children, which is intended to set out detailed objectives, measures and an action plan, as well as indicators for monitoring progress.</p>
            <h3 className="text-main_blue pb-2">Action areas</h3>
            <p>The Policy will be implemented through cross-sectoral coordination and integrated action through the following action areas:</p>
            <p>Advocacy and raising awareness of professionals, decision-makers and the public on the importance of adequate child nutrition for proper growth and development, prevention of diseases, particularly mass non-communicable chronic diseases resulting from inadequate nutrition, as well as achievement of full development potential.</p>
            <p>Ensuring accessible and high-quality services and implementation of integrated programmes and activities for improvement of child nutrition, [..] including nutrition programmes that will be implemented in [..] schools and other institutions in collaboration with the health sector.</p>
            <p>Training and informing professionals, particularly in the health sector, education sector as well as sectors important for food production and trade, media and consumers.</p>
            <p>Providing safe and good quality nutritious foods through sustainable food production and supply system, proper nutritional composition of foods aimed at infants and children.</p>
            <p>Protecting children and their families from the pervasive effect of marketing of foods that are high in salt, sugar and fat, and supporting initiatives to changing existing practices with the aim of improving the nutritional profile of processed foods.</p>
            <p>Strengthening and improving the information system for monitoring and evaluation pertaining to food and nutritional status of children, with a focus on child growth and nutritional status that are related to the dietary habits and behaviours.</p>
          </td>
        </tr>
        <tr className="bg-pale_blue">
          <td>
            <p>Food and nutrition policy of the Federation of Bosnia and Herzegovina</p>
            <p className="text-txt_black_1">Federal Ministry of Health and Institute of Public Health of Federation of Bosnia and Herzegovina,</p>
            <p className="text-txt_black_1">2002</p>
          </td>
          <td>
            <p>The goal of the nutrition policy is to protect and promote health and reduce the burden of diseases related to food and nutrition while contributing to social and economic development and sustainable environment. More specifically, the goal   of the health sector in promoting health through well balanced diet, prevention    of nutritional deficiencies and prevention and control of food borne diseases.</p>
            <p>The context is analysed and considerations presented for the situation with set objectives and strategic approaches to achieve the goals.</p>
            <p>(Information from WHO website – no document available to review)</p>
          </td>
        </tr>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Programmes / Plans</span></td>
        </tr>
        <tr>
          <td>
            <p>Action Plan for the prevention and control of non-communicable diseases in the Republic of Srpska for the period 2019-2026.</p>
            <p className="text-txt_black_1">Government of Republika Srpska. Ministry of Health and Social Welfare</p>
          </td>
          <td>
            <p>The aim of this policy is to improve the health of the population of Republika Srpska (RS): reducing inequalities in the health of the population; investing in health, involving citizens in decision-making on health and the work of healthy local communities; strengthening the health system oriented to the needs of the users; strengthening the capacity of public health.</p>
            <p>It refers to achieving a policy coherence between trade / economic and food / health policies, with nutrition mentioned (sugar, salt in baby food and trans-fats) alongside alcohol and tobacco issues.</p>
            <p>Specific actions pertinent to nutrition include:</p>
            <ul className="bullets">
              <li>Preparation of a social marketing campaign for the promotion of healthy   eating through fiscal and marketing policies (tobacco, alcohol, groceries); pre-formulation and improvement of products (salt, fats and sugars); salt reduction</li>
              <li>Analysis of existing international policy measures which aim to: reduce the daily intake of salt; reduce trans-fat and saturated fat in food and to reduce sugar intake and recommendations for action in RS</li>
              <li>Assess the sustainability of the implementation of the WHO recommendations on food and beverage</li>
              <li>Analysis of existing international measures of comprehensive fiscal policies in Europe that include the availability and acceptance of healthy foodstuffs</li>
              <li>Analysis of the improvement of the interpretative declaration of products on the front of food packaging and make recommendations for action in RS</li>
              <li>Monitor overweight in children in RS through Childhood Obesity Surveillance Initiative (COSI) and give regular recommendations.</li>
            </ul>
            <p>Specific elements of the action plan for children include:</p>
            <ul className="bullets">
              <li>Expansion of the “Healthy life in preschools and schools” programme which commits to:</li>
              <ul>
                <li>Develop Nutrition Standards guidelines and health-promotional  materials; ensure stimulating  environments  for  the  promotion  of  health,  physical activity and nutrition ; strengthen the capacity of the school / preschool institution to recognise health and nutritional problems of children in cooperation  with  family  and  local  self-government  units;  boost  knowledge   of professionals in the health care institutions and the association of local government in the prevention and elimination of health problems related to obesity and malnutrition.</li>
                <li>Prepare and conduct research on the health behaviour of children of school age in RS. (Health Behaviour in School-aged Children - HBSC) and make recommendations.</li>
                <li>Explore solutions and experiences of countries that have a regulation and logo for healthy food marketing; prepare draft regulations and logo layout; promote the importance of the logo of choice in all education institutes and on all presentations (websites etc) where healthy nutrition is mentioned; motivate manufacturers and food distributors to use the logo on declarations through incentives. (N.B. google translation).</li>
              </ul>
            </ul>
          </td>
        </tr>
        <tr className="bg-pale_blue">
          <td>
            <p>Nutrition friendly school/pre- school programme - Standards or rules for foods and beverages available in schools - School age children (SAC)</p>
            <p className="text-txt_black_1">January 2014</p>
            <p>and the Nutrition friendly school/pre-school programme - School milk scheme - School age children (SAC)</p>
            <p className="text-txt_black_1">January 2014</p>
          </td>
          <td>
            <p>Standards or rules apply to foods and beverages served for lunch in school canteens/cafeterias, foods and beverages served at other mealtimes (e.g. breakfast, after-school services). Includes criteria to determine which foods are prohibited, limited or encouraged are based on nutrient content, portion size.</p>
            <p>All children in targeted schools are eligible to participate. Milks provided include low-fat milk.</p>
            <p>This was implemented in 12 kindergartens in 2014, due to be expanded to 16 by 2017.</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Nutrition friendly school/pre- school programme - School fruit and vegetable scheme - School age children (SAC)</p>
            <p className="text-txt_black_1">January 2014</p>
          </td>
          <td>All children at targeted schools are eligible to participate in the school fruit and vegetable scheme, which is free for all children and provided daily. Products provided include: fresh fruit and vegetables, dried fruit, 100% fruit  juices,  tinned or otherwise prepared fruit in water. Fruit and vegetables are procured internationally as well as domestically.</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>
            <p>Nutrition friendly school/pre- school programme - Nutrition education included in school curriculum - School age children (SAC)</p>
            <p className="text-txt_black_1">January 2014</p>
          </td>
          <td>Nutrition education curriculum includes: lessons on healthy diet to prevent undernutrition; lessons on healthy diet to prevent overweight and obesity; lessons on the links between nutrition and health; hands-on cooking skills. Mandatory in     all preschools covered by this programme.</td>
        </tr>
        <tr>
          <td>
            <p>UNICEF: Nutrition Friendly Schools Project (current)</p>
            <p className="text-txt_black_1">Eat Well, Grow Well 2014-2015</p>
          </td>
          <td>
            <p>From 2014-2015, the programme Eat Well, Grow Well  promoted  healthy  eating and healthy lifestyles among children, parents, educators, cooks and other employees of preschool institutions in order to reduce obesity and other disorders  in preschool children caused by improper diet and unhealthy lifestyles. The programme was coordinated by the Federal Ministry of Education and Science, in cooperation with the Federal Ministry of Health, Institute of Public Health, the Association of Chefs, and the NGO Project Hope Bosnia and Herzegovina.</p>
            <p>In the first phase of the project, workshops were held in four primary schools     in Sarajevo and Banja Luka. Workshops were also organised for teachers and parents. The goal of the project is to promote healthy nutrition among children and youth; to influence the adoption of appropriate policies by educational institutions; and to centrally place healthy living habits in the curriculum for continuous promotion in educational institutions.</p>
          </td>
        </tr>
        <tr className="bg-pale_blue">
          <td>
            <p>Reducing Health Risk Factors Project</p>
            <p className="text-txt_black_1">Ministry of Health BIH/ Ministry of Health and Social Welfare of Republika Srpska / Ministry of Civil Affairs of BIH/ World Bank / Swiss Agency for Development and Cooperation / WHO 2017-2019</p>
          </td>
          <td>
            <p>This is a multi-action approach implemented in 8 selected municipalities of the country. The Project aims to assist the Republika Srpska and the Federation of Bosnia and Herzegovina in reducing  selected  non-communicable  disease  (NCD) risk factors by promoting tobacco and alcohol control, and diet and physical   activity. It includes a targeted programme for pre-school children, school children, teachers, health workers and local government employees in four selected local communities (two in RS and two in BIH) to promote healthy diet and physical activity in schools.</p>
            <p>Special focus is placed on reaching disadvantaged and vulnerable groups, as well as youth, to encourage adoption of healthy lifestyles in early adolescence.</p>
          </td>
        </tr>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Guidelines /Legislation</span></td>
        </tr>
        <tr>
          <td>
            <p>Guidelines for Healthy Nutrition of Preschool and School Children</p>
            <p className="text-txt_black_1">Government of Federation of Bosnia and Herzegovina, 2012</p>
            <p className="text-txt_black_1">Federal Ministry of Education and Science</p>
            <p className="text-txt_black_1">Federal Ministry of Health Institute of Public Health of the Federation of Bosnia and Herzegovina</p>
          </td>
          <td>
            <p>Aimed at creating and establishing the conditions for healthy and safe growth and development of children in the country. This was the first step in an effort to improve nutrition of children and youth in the BIH, which set the stage for detailed elaboration and development of practical tools to facilitate the application of these standards</p>
            <p>The specific purpose of these guidelines is to support executives of educational institutions and staff in charge of food preparation, as well as food distributors, municipalities, cantons and entities, parents / carers  and  others  in  charge  of  food and nutrition care of infants, children and youth in BIH, to provide children with a balanced diet, with adequate daily allowances of energy and key nutrients.</p>
            <p>Nutritional requirements are set out by age group for children from 6 months to 18 years, following WHO recommended intakes to establish guidelines on macro- and micronutrient requirements for the school-age and pre-school children.</p>
            <p>Sample servings and quantities for menus are described, and recommendations for water and fluid intake and for limiting salt intake and restricting sugar intake are provided. Recommended meal serving times; recommended nutrient content of meals in institutions by age group; nutrition management in education institutions; and food safety considerations are also included.</p>
          </td>
        </tr>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Promotional materials</span></td>
        </tr>
        <tr>
          <td>
            <p>Get together and Enjoy!</p>
            <p className="text-txt_black_1">(Sabirajte I uzivajte)</p>
            <p>A Manual for children, teenagers and adolescents who suffer from diabetes</p>
            <p className="text-txt_black_1">August 2014, UNICEF</p>
          </td>
          <td>A manual for young people who suffer from diabetes to advise them on healthy nutrition and offer suggestions/guidance on preparing healthy meals. It includes menu ideas and growth charts with advice to track weight and nutritional status.</td>
        </tr>
      </tbody>
    </table>
  ) 
}
export default Table07_2
