import { useState } from 'react'
// Import Components
import TableTitle from '../../components/TableTitle'
import PageTitle from '../../components/PageTitle'
import Footnote from '../../components/Footnote'
import TitleButton from '../../components/TitleButton'
// Import Page Elements
import Table01 from '../../page_elements/tables/Table01'
// Component
const Scope = () => {

  const pageTitle = 'Scope of the review'
  const tableDesc = 'List of 21 countries from ECA region included in the review, with profile countries in bold'

  const [showText, setShowText] = useState(true);
  const [showTable, setShowTable] = useState(false);
  
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable(false)
  }
  function showTableHandler(e) {
    e.preventDefault();
    setShowTable(!showTable)
    setShowText(false)
  }

  return (
    <> 
      <PageTitle title={ pageTitle } >
        {!showText &&
          <TitleButton 
            showHandler = { showTextHandler }
            seeText={ 'See Text: '}
            icon={'icon-text-icon'}
            description={ pageTitle }
          />
        }
        {!showTable &&
          <TitleButton 
            showHandler = { showTableHandler }
            seeText={ 'See Table 1: '}
            icon={'icon-tables-icon'}
            description={ tableDesc }
          />
        }    
        </PageTitle>
      { showTable && 
        <div className="article_content">
          <div className="max-w-400 mx-auto">
            <TableTitle 
              title={'Table 1'}
              description={ tableDesc }
            />
            <Table01 />
            </div>
        </div>
      }
      { showText && 
        <div className="article_content col2">
          <p>This review was conducted during 2018 and split into three components:</p>
          <ol className="list-decimal pl-8">
            <li className="pl-2 pb-6">A literature review<Footnote sup={'5'} text={'A systematic literature review of the nutrition status of adolescent and school-aged children in Europe and Central Asia region. Jan 2020'}/> that examined published literature on the nutritional status of adolescents and children (5-19 years old) in a number of priority countries in ECA region;</li>
            <li className="pl-2 pb-6">A survey  review<Footnote sup={'6'} text={'A survey review of the nutrition status of adolescent and school-aged children in Europe and Central Asia region. Jan 2020'}/> that examined nationally representative survey data on the nutritional status (over- and under-nutrition, micronutrient deficiencies and unhealthy dietary patterns) of children and adolescents (7-18 years) across the ECA region;</li>
            <li className="pl-2 pb-6">A review of the current policy and programming environment for child and adolescent nutrition<Footnote sup={'7'} text={'The nutritional status of school-aged children and adolescents (7-18 years old) in the UNICEF Europe and Central Asia region and the existing policies and programmes to address their nutritional problems: Nine Country Profiles. Jan 2020'}/> that examined the current policy and programming environment for child and adolescent nutrition for nine of the 21 countries across the ECA region listed in Table 1 (the 9 countries of focus are in bold).</li>
            </ol>
        </div>
      }
    </>
  ) 
}

export default Scope
