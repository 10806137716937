import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
// Import Table Components
import LabelBox from '../../table_comp/LabelBox'
import Bar from '../../table_comp/Bar'

// Component
const Figure02_2 = () => {

  const topValue = 70;

  const [showGirls, setShowGirls] = useState(true);
  const handleClickGirls = () => setShowGirls(!showGirls)

  const [showBoys, setShowBoys] = useState(true);
  const handleClickBoys = () => setShowBoys(!showBoys)

  const [showFruits, setShowFruits] = useState(true);
  const handleClickFruits = () => setShowFruits(!showFruits)

  const [showVegetables, setShowVegetables] = useState(true);
  const handleClickVegetables = () => setShowVegetables(!showVegetables)

  const [showSweets, setShowSweets] = useState(true);
  const handleClickSweets = () => setShowSweets(!showSweets)

  const [showDrinks, setShowDrinks] = useState(true);
  const handleClickDrinks = () => setShowDrinks(!showDrinks)

  const [showExercises, setShowExercises] = useState(true);
  const handleClickExercises = () => setShowExercises(!showExercises)

  const labelFruits = 'Daily fruit\nconsumption (%)'
  const labelVegetables = 'Daily vegetable\nconsumption (%)'
  const labelSweets = 'Daily sweets\nconsumption (%)'
  const labelDrinks = 'Daily soft drink\nconsumption (%)'
  const labelExercises = 'Moderate\nexercise (%)*'


  return (
    <>
      <div className="barChartBox">
        <div className="barChart">
          {/* FRUITS */}
          {showFruits &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox 
                label={ labelFruits }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
              {/* GIRLS */}
              {showGirls &&
                <div className="pb-1">
                  <Bar 
                    barColor = {'#00AEEF'}
                    barWidth = { (51.4 * 100) / topValue }
                    barHeight = { '12px' }
                    dataTipId = { 'g10_01' }
                    tipLabel = { labelFruits }
                    tipSubLabel = { 'Girls 2010' }
                    tipValue = { 51.4 }
                  />
                  <Bar 
                    barColor = {'#3b4395'}
                    barWidth = { (62.4 * 100) / topValue }
                    barHeight = { '12px' }
                    dataTipId = { 'g14_01' }
                    tipLabel = { labelFruits }
                    tipSubLabel = { 'Girls 2014' }
                    tipValue = { 62.4 }
                  />
                </div>
              }
              {showBoys &&
                <div>
                  <Bar 
                    barColor = {'#00AEEF'}
                    barWidth = { (45.7 * 100) / topValue }
                    barHeight = { '12px' }
                    dataTipId = { 'b10_01' }
                    tipLabel = { labelFruits }
                    tipSubLabel = { 'Boys 2010' }
                    tipValue = { 45.7 }
                  />
                  <Bar 
                    barColor = {'#3b4395'}
                    barWidth = { (51.8 * 100) / topValue }
                    barHeight = { '12px' }
                    dataTipId = { 'b14_01' }
                    tipLabel = { labelFruits }
                    tipSubLabel = { 'Boys 2014' }
                    tipValue = { 51.8 }
                  />
                </div>
              }
            </div>
            </div>
          }
          {/* VEGETABLES */}
          {showVegetables &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox
                label={ labelVegetables }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
                {/* GIRLS */}
                {showGirls &&
                  <div className="pb-1">
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (51.4 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g10_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Girls 2010' }
                      tipValue = { 51.4 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (62.4 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g14_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Girls 2014' }
                      tipValue = { 62.4 }
                    />
                  </div>
                }
                {showBoys &&
                  <div>
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (45.7 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b10_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Boys 2010' }
                      tipValue = { 45.7 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (51.8 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b14_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Boys 2014' }
                      tipValue = { 51.8 }
                    />
                  </div>
                }
              </div>
            </div>
          }
          {/* SWEETS */}
          {showSweets &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox
                label={ labelSweets }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
                {/* GIRLS */}
                {showGirls &&
                  <div className="pb-1">
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (60.3 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g10_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Girls 2010' }
                      tipValue = { 60.3 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (56.3 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g14_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Girls 2014' }
                      tipValue = { 56.3 }
                    />
                  </div>
                }
                {showBoys &&
                  <div>
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (54 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b10_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Boys 2010' }
                      tipValue = { 54 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (49.5 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b14_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Boys 2014' }
                      tipValue = { 49.5 }
                    />
                  </div>
                }
              </div>
            </div>
          }
          {/* DRINKS */}
          {showDrinks &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox
                label={ labelDrinks }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
                {/* GIRLS */}
                {showGirls &&
                  <div className="pb-1">
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (26.2 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g10_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Girls 2010' }
                      tipValue = { 26.2 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (21.9 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g14_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Girls 2014' }
                      tipValue = { 21.9 }
                    />
                  </div>
                }
                {showBoys &&
                  <div>
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (28.9 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b10_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Boys 2010' }
                      tipValue = { 28.9 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (23.2 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b14_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Boys 2014' }
                      tipValue = { 23.2 }
                    />
                  </div>
                }
              </div>
            </div>
          }
          {/* EXERCISES */}
          {showExercises &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox
                label={ labelExercises }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
                {/* GIRLS */}
                {showGirls &&
                  <div className="pb-1">
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (17.1 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g10_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Girls 2010' }
                      tipValue = { 17.1 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (17.9 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g14_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Girls 2014' }
                      tipValue = { 17.9 }
                    />
                  </div>
                }
                {showBoys &&
                  <div>
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (30.1 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b10_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Boys 2010' }
                      tipValue = { 30.1 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (27.7 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b14_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Boys 2014' }
                      tipValue = { 27.7 }
                    />
                  </div>
                }
              </div>
            </div>
          }
        </div>
        {/* BACK LINES */}
        <div className="lines" aria-hidden="true">
          <div className="labelBox solidLine"><span>0</span></div>
          <div className="dotLines">
            <div><span>10</span></div>
            <div><span>20</span></div>
            <div><span>30</span></div>
            <div><span>40</span></div>
            <div><span>50</span></div>
            <div><span>60</span></div>
            <div><span>70</span></div>
          </div>
        </div>
      </div>
      <div className="infoBox">
        <span>*</span>Moderate exercise is defined as 60 minutes of moderate exercise or more daily
      </div>
      
      {/* SORT CHECKBOXES */}
      <div className="flex text-main_blue text-sm">Select to sort</div>
      <div className="sortBox">
        {/* LEFT BOX */}
        <div className="left_box sortBtnBox">
          {/* CHECKBOX FRUITS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelFruits }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickFruits} defaultChecked={showFruits} type="checkbox" id="fruits"/>
                <label htmlFor="fruits"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX VEGETABLES */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelVegetables }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickVegetables} defaultChecked={showFruits} type="checkbox" id="vegetables"/>
                <label htmlFor="vegetables"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX SWEETS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelSweets }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickSweets} defaultChecked={showFruits} type="checkbox" id="sweets"/>
                <label htmlFor="sweets"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX DRINKS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelDrinks }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickDrinks} defaultChecked={showFruits} type="checkbox" id="drinks"/>
                <label htmlFor="drinks"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX EXERCISES */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelExercises }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickExercises} defaultChecked={showFruits} type="checkbox" id="exercises"/>
                <label htmlFor="exercises"></label>
              </div>
            </div>
          </div>
        </div>
        {/* RIGHT BOX */}
        <div className="right_box">
          {/* CHECKBOX GIRLS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Girls</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickGirls} defaultChecked={showGirls} type="checkbox" id="girls"/>
                <label htmlFor="girls"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX BOYS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Boys</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickBoys} defaultChecked={showBoys} type="checkbox" id="boys"/>
                <label htmlFor="boys"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Figure02_2 
