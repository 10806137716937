const GoTop = () => {

  const scrollTop = () => window.scrollTo(0, 0)  

  return (
    <div className="fixed left-1/2 bottom-4 z-50">
      <button 
        className="
          relative flex flex-col justify-center items-center w-7 h-7 m-1 
          bg-main_blue hover:bg-sec_blue rounded-full ani03s
        "
        onClick={() => scrollTop()}
      >
          <span className="absolute top-0.5 text-white text-lg icon-gotop"></span>
        </button>
    </div>
  )
}

export default GoTop