// COMPONET
const BaseRow = ({ row, supTxt, txtBlue, circle }) => {

  return (
    <tr {...row.getRowProps()} key={row.id}>
      {row.cells.map(cell => {
        return (
          <>
          <td {...cell.getCellProps()}
            className={`
              ${txtBlue && cell.column.Header === 'Country' ? 'text-main_blue' : ''}
              ${circle ? 'pr-6 relative' : ''}
            `}
           >
            
            { cell.render('Cell')}

            { supTxt && cell.column.Header === 'Country' ? <span className="sup">{supTxt}</span> : ''}
            { circle && 
              <>
                {cell.column.id === 'female1' &&
                  <div className={`tableCircle ${row.original.cf1}`}></div>
                }
                {cell.column.id === 'male1' &&
                  <div className={`tableCircle ${row.original.cm1}`}></div>
                }
                {cell.column.id === 'female2' &&
                  <div className={`tableCircle ${row.original.cf2}`}></div>
                }
                {cell.column.id === 'male2' &&
                  <div className={`tableCircle ${row.original.cm2}`}></div>
                }
                {cell.column.id === 'female3' &&
                  <div className={`tableCircle ${row.original.cf3}`}></div>
                }
                {cell.column.id === 'male3' &&
                  <div className={`tableCircle ${row.original.cm3}`}></div>
                }
                {cell.column.id === 'female4' &&
                  <div className={`tableCircle ${row.original.cf4}`}></div>
                }
                {cell.column.id === 'male4' &&
                  <div className={`tableCircle ${row.original.cm4}`}></div>
                }
                {cell.column.id === 'female5' &&
                  <div className={`tableCircle ${row.original.cf5}`}></div>
                }
                {cell.column.id === 'male5' &&
                  <div className={`tableCircle ${row.original.cm5}`}></div>
                }
                {cell.column.id === 'female6' &&
                  <div className={`tableCircle ${row.original.cf6}`}></div>
                }
                {cell.column.id === 'male6' &&
                  <div className={`tableCircle ${row.original.cm6}`}></div>
                }
                {cell.column.id === 'female7' &&
                  <div className={`tableCircle ${row.original.cf7}`}></div>
                }
                {cell.column.id === 'male7' &&
                  <div className={`tableCircle ${row.original.cm7}`}></div>
                }
              </>
            }
          </td>
          </>
        )
      })}
    </tr>       
  )
}

BaseRow.defaultProps = {
  txtBlue: false,   
  circle: false
}

export default BaseRow