import { useState } from 'react'
// Import Flag
import Flag from '../../images/armenia.svg'
// Import Page Elements
import Figure02_2 from '../../page_elements/figures/Figure02_2'
import Table02_2 from '../../page_elements/tables/Table02_2'
import Table03_2 from '../../page_elements/tables/Table03_2'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TableTitle from '../../components/TableTitle'
import TitleLink from '../../components/TitleLink'
import Footnote from '../../components/Footnote'
// Component
const Armenia = () => {

  const pageTitle = 'Armenia'
  const table1Desc = 'Status of the nutrition of school-aged children and adolescents in Armenia'
  const table2Desc = 'Status of Policy and programming environment in Armenia'
  const figureDesc = 'Eating practices among adolescents in Armenia in 2010 and 2014'

  const [showText, setShowText] = useState(true);
  const [showTable1, setShowTable1] = useState(false);
  const [showTable2, setShowTable2] = useState(false);
  const [showFigure, setShowFigure] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
    setShowTable2(false)
    setShowFigure(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowTable2(false)
    setShowText(false)
    setShowFigure(false)
  }
  function showTable2Handler(e) {
    e.preventDefault();
    setShowTable2(!showTable2)
    setShowTable1(false)
    setShowText(false)
    setShowFigure(false)
  }
  function showFigureHandler(e) {
    e.preventDefault();
    setShowFigure(!showFigure)
    setShowText(false)
    setShowTable1(false)
    setShowTable2(false)
  }

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="COUNTRY PROFILES" />
      <article>
        <PageTitle title = { pageTitle }  flag = { Flag } flagAlt = { 'Armenia Flag' }>
          <TitleLink 
            linkTo = { '/country-profiles' }
            seeText = { 'See Map: '}
            icon = {'icon-map-icon'}
            description = { 'Country Profiles' }
          />
          {!showText &&
            <TitleButton 
              showHandler = { showTextHandler }
              seeText = { 'See Text: '}
              icon = {'icon-text-icon'}
              description = { pageTitle }
            />
          }
          {!showFigure &&
            <TitleButton 
              showHandler = { showFigureHandler }
              seeText = { 'See Figure 2: '}
              icon = {'icon-chart-icon'}
              description = { figureDesc }
            />
          }
          {!showTable1 &&
            <TitleButton 
              showHandler = { showTable1Handler }
              seeText = { 'See Table 2: '}
              icon = {'icon-tables-icon'}
              description = { table1Desc }
            />
          }
          {!showTable2 &&
            <TitleButton 
              showHandler = { showTable2Handler }
              seeText = { 'See Table 3: '}
              icon = {'icon-tables-icon'}
              description = { table2Desc }
            />
          }
        </PageTitle>
        {/* TEXT */}
        { showText && 
          <div className="article_content col2">
            <p>In Armenia, the latest DHS survey (2015/16) reported that 0.7 percent<Footnote sup={'13'} text={'Demographic Health Surveys (DHS) of 2015/16, classified using WHO BMI-for-age thresholds'}/> of girls aged 15-19 years were thin, while 10.6 percent were stunted. While DHS shows 11.1 percent overweight in girls aged 15-19, in the broader age group of 10-24 years, 20.8 percent of girls and 22.5 percent of boys were reported overweight using IOTF thresholds<Footnote sup={'14'} text={'Azzopardi et al 2019. IHME data 2016. Prevalence estimated using the International Obesity Task Force (IOTF) thresholds and modelled data from surveys between 1990 and 2016.'}/>.</p>
            <p>The prevalence of anaemia<Footnote sup={'15'} text={'WHO/UNICEF/UNU 2001 definition is used throughout this report for anaemia prevalence.'}/> in girls aged 15-19 years was 8.0 percent in the DHS 2000 and has risen to 17.1 percent in the 2015/16 DHS. For boys aged 10-24 years IHME reports 19.3 percent.</p>
            <p>The median urinary iodine concentration (UIC) of school children aged 8-10 years in 2017 was 242 µg/L, which indicates an ‘adequate’ intake.</p>
            <p>In terms of eating practices, results of two Health Behaviour in School-aged Children (HSBC) surveys<Footnote sup={'16'} text={'Health Behaviour in School-aged Children. World Health Organisation Collaborative Cross-National Survey. WHO 2010.'}/> are shown below<Footnote sup={'17'} text={'The sample included girls and boys aged 11, 13 and 15.'}/>. This suggests that fruit and vegetable consumption may be improving.</p>
            <p>However, one-third of adolescents have been reported to have multiple health complaints including unhealthy eating habits and sedentary behaviour<Footnote sup={'18'} text={'The Child and Adolescent Health Strategy 2016-2020 citing National Research on school-aged children’s health behaviour (SACHB) 2013-2014'}/>. The 2016 Policy to promote Health Nutrition and Physical Activity in Schools observes that unhealthy eating habits and sedentary behaviour are common amongst adolescents, with many school age children skipping breakfast and not consuming enough vegetables. The policy also notes worrying trends in weight gain amongst this age group.</p>
            <h3 className="text-main_blue pb-3">Conclusion</h3>
            <p>The high prevalence of overweight and anaemia in this age group are of concern and appear to be on the rise. Consumption of fruit and vegetables by school-aged children and adolescents was reportedly low, while consumption of sugary foods and drinks was high. It is encouraging to see a specific strategy focused on the health of children and adolescents but the focus on nutrition within it is limited. The policy and programming environment appears to be primarily focused on schools, which is a strong starting point. However, coordinated strategies involving other sectors and delivery systems, such as the health and food systems, as well as community approaches targeting additional aspects of children’s and adolescent’s lives, will be required, to ensure a comprehensive approach to tackle and reverse the overweight burden, in particular.</p>
          </div>
        }
        {/* TABLE */}
        { showTable1 && 
          <div className="article_content">
            <TableTitle
              title={'Table 2'}
              description={ table1Desc }
            /><Table02_2 />
          </div>
        }
        {/* TABLE */}
        { showTable2 && 
          <div className="article_content">
            <TableTitle
              title={'Table 3'}
              description={ table2Desc }
            />
            <Table03_2 />
          </div>
        }
        {/* FIGURE */}
        { showFigure && 
          <div className="article_content">
            <TableTitle
              title={'Figure 2'}
              description={ figureDesc }
            />
            <div className="
              flex flex-nowrap justify-end
              border-b-2 border-main_blue
              pt-2 text-sm pb-1">
                <div className="text-main_blue">Legend:</div>
                <div className="w-3 h-3 bg-girls ml-2 mr-1 mt-1 
                "></div>
                <div>2010</div>
                <div className="w-3 h-3 bg-boys ml-3 mr-1 mt-1 
                "></div>
                <div>2014</div>
            </div>
            <Figure02_2 />
          </div>
        }
      </article>
    </div>
  ) 
}
export default Armenia
