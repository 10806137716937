import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
// IMPORT COMPONENTS
import NavItem from './NavItem'
import NavItemLink from './NavItemLink'
import NavSubItem from './NavSubItem'
// COMPONENT
const NavBar = ({ showNav, setShowNav }) => {
    
  // CONSTANTS
  const location = useLocation();
  const mainItem = location.pathname;
  const secItem = location.hash;

  console.log(mainItem);
  console.log(secItem);

  // RETURNING
  return (
    <div className={`
      absolute min-w-460 xs:min-w-0 top-0 xs:w-full right-0 p-2 z-10`
    }> 
      <nav className={`
        w-full
        flex flex-col bg-sec_blue
        justify-center items-start
        py-3 shadow`
      } role="navigation" aria-label="main navigation">
          <NavItem
              title={'INTRODUCTION'}
              navActive={mainItem === '/introduction' ? true : false}>
              <NavSubItem
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Adolescent & school age nutrition in the lifecycle'}
                  linkTo={'/introduction#adolescent-school-age-nutrition-in-the-lifecycle'}
                  navSubActive={secItem === '#adolescent-school-age-nutrition-in-the-lifecycle' ? true : false}
                  hashValue={'#adolescent-school-age-nutrition-in-the-lifecycle'}
              />
              <NavSubItem
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Adolescent & school age nutrition in ECA'}
                  linkTo={'/introduction#adolescent-school-age-nutrition-in-eca'}
                  navSubActive={secItem === '#adolescent-school-age-nutrition-in-eca' ? true : false}
                  hashValue={'#adolescent-school-age-nutrition-in-eca'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Scope of the review'}
                  linkTo={'/introduction#scope-of-the-review'}
                  navSubActive={secItem === '#scope-of-the-review' ? true : false}
                  hashValue={'#scope-of-the-review'}
              />
          </NavItem>
          <NavItem 
              title={'METHODS'}
              navActive={mainItem === '/methods' ? true : false}>
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Definitions'}
                  linkTo={'/methods#definitions'}
                  navSubActive={secItem === '#definitions' ? true : false}
                  hashValue={'#definitions'}
              />
              <NavSubItem
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Methods'}
                  linkTo={'/methods#methods'}
                  navSubActive={secItem === '#methods' ? true : false}
                  hashValue={'#methods'}
              />
              <NavSubItem
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Limitations'}
                  linkTo={'/methods#limitations'}
                  navSubActive={secItem === '#limitations' ? true : false}
                  hashValue={'#limitations'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Data sources'}
                  linkTo={'/methods#data-sources'}
                  navSubActive={secItem === '#data-sources' ? true : false}
                  hashValue={'#data-sources'}
              />
          </NavItem>
          <NavItem
              title={'FINDINGS'}
              navActive={mainItem === '/findings' ? true : false}>
              <NavSubItem 
                  setShowNav ={ setShowNav }
                  title={'Overview of key findings'}
                  linkTo={'/findings#overview-of-key-findings'}
                  navSubActive={secItem === '#overview-of-key-findings' ? true : false}
                  hashValue={'#overview-of-key-findings'}
              />
              <NavSubItem
                  setShowNav={ setShowNav }
                  title={'Overweight or obesity'}
                  linkTo={'/findings#overweight-or-obesity'}
                  navSubActive={secItem === '#overweight-or-obesity' ? true : false}
                  hashValue={'#overweight-or-obesity'}
              />
              <NavSubItem 
                  setShowNav={ setShowNav }
                  title={'Stunting'}
                  linkTo={'/findings#stunting'}
                  navSubActive={secItem === '#stunting' ? true : false}
                  hashValue={'#stunting'}
              />
              <NavSubItem 
                  setShowNav={ setShowNav }
                  title={'Micronutrient status'}
                  linkTo={'/findings#micronutrient-status'}
                  navSubActive={secItem === '#micronutrient-status' ? true : false}
                  hashValue={'#micronutrient-status'}
              />
              <NavSubItem 
                  setShowNav={ setShowNav }
                  title={'Dietary habits and physical exercise'}
                  linkTo={'/findings#dietary-habits-and-physical-exercise'}
                  navSubActive={secItem === '#dietary-habits-and-physical-exercise' ? true : false}
                  hashValue={'#dietary-habits-and-physical-exercise'}
              />
              <NavSubItem 
                  setShowNav={ setShowNav }
                  title={'Programme and policy environment'}
                  linkTo={'/findings#programme-and-policy-environment'}
                  navSubActive={secItem === '#programme-and-policy-environment' ? true : false}
                  hashValue={'#programme-and-policy-environment'}
              />
          </NavItem>
          <NavItemLink 
            setShowNav ={ setShowNav }
            title={'REGIONAL FRAMEWORKS & STRATEGIES ON NUTRITION'}
            navActive={secItem === 'regional-frameworks-strategies-on-nutrition' ? true : false}
            linkTo={'/regional-frameworks-strategies-on-nutrition'}
          />
          <NavItemLink 
            setShowNav ={ setShowNav }
            title={'Country Profiles'}
            navActive={secItem === 'country-profiles' ? true : false}
            linkTo={'/country-profiles'}
          />
          <NavItem
              title={'CONCLUSIONS'}
              navActive={mainItem === '/conclusions' ? true : false}>
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'VOL 1. Conclusions'}
                  linkTo={'/conclusions#vol1-conclusions'}
                  navSubActive={secItem === '#vol1-conclusions' ? true : false}
                  hashValue={'#vol1-conclusions'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'VOL 2. Conclusions'}
                  linkTo={'/conclusions#vol2-conclusions'}
                  navSubActive={secItem === '#vol2-conclusions' ? true : false}
                  hashValue={'#vol2-conclusions'}
              />
          </NavItem>
      </nav> 
  
    </div>
  )
}
// PROPTYPES
NavBar.propTypes = {
  showNav: PropTypes.bool.isRequired,
}
// EXPORT
export default NavBar
