import PropTypes from 'prop-types'

const Breadcrumbs = ({ breadcrumb }) => {
    return (
        <div className="px-1 bg-txt_black_2 text-white text-sm inline font-bold">
            { breadcrumb }
        </div>
    )
}

Breadcrumbs.propTypes = {
    breadcrumb: PropTypes.string.isRequired,
}

export default Breadcrumbs
