// Import Image
import Image from '../../images/findings_2.jpg'
// Import Components
import CoverImg from '../../components/CoverImg'
import PageTitle from '../../components/PageTitle'
// Component
const Stunting = () => {
  // Return  
  return (
    <>
      <PageTitle title="Stunting" />
      <CoverImg SrcImg = { Image } />
      <div className="article_content col2">
        <p>The prevalence of stunted girls was less than 10% in all eleven countries in the region where data was available. Data on boys aged 12-15 years was only available for five countries (North Macedonia, Tajikistan, Azerbaijan, Armenia, Turkey).  Four countries reported a prevalence less than 10%, while prevalence in Turkey was 17.9%.</p>
      </div>
    </>
  ) 
}

export default Stunting
