const Table16_2 = () => {
  return (
    <>  
      <div className="table-scroll relative">
        <table className="tableRightBorder table-auto w-full text-left xs:text-xs">
          <thead>
            <tr className="text-white bg-main_blue">
              <th className="bg-main_blue">
                <div className="min-w-80">Survey</div>
              </th>
              <th>
                <div className="min-w-80">Age group</div>
              </th>
              <th>
                <div className="min-w-80">Boys/Girls (non-pregnant)</div>
              </th>
              <th>
                <div className="min-w-100">{`Thin % (BMI for age <-2SD)`}</div>
              </th>
              <th>
                <div className="min-w-100">{`HAZ% <- 2SD`}</div>
              </th>
              <th>
                <div className="min-w-100">Overweight or obese % (95% C.I.)</div>
              </th>
              <th>
                <div className="min-w-100">Anaemia % (95% C.I.)</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-main_blue bg-white">DHS 2012*</td>
              <td>15-19 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">3.8 (2.9, 4.9)</td>
              <td className="text-right">9.9 (7.9, 12.2)</td>
              <td className="text-right">8.0 (6.7, 9.5)</td>
              <td className="text-right">-</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="text-main_blue bg-pale_blue">DHS 2017*</td>
              <td>17-19 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">33.3</td>
            </tr>
            <tr>
              <td rowSpan="2" className="text-main_blue bg-white">COSI-4 2015-2017</td>
              <td rowSpan="2">7 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">6</td>
              <td className="text-right">-</td>
            </tr>
            <tr>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">11</td>
              <td className="text-right">-</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="text-main_blue bg-pale_blue">IHME</td>
              <td>10-24 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">11.7</td>
              <td className="text-right">25.8</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="bg-pale_blue">
                <p>2016</p>
                <p>(modelled data from 1990-2016)</p>
                <p>(IOTF) (Azzopardi 2018)</p>
              </td>
              <td>10-24 y</td>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">18.5</td>
              <td className="text-right">21.3</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="infoBox">
        <span>*</span>DHS data is as reported in Benedict 2018, reanalysed using BMI-for-age
      </div>
    </>
  ) 
}
export default Table16_2
