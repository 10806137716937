// Import Components
import PageTitle from '../../components/PageTitle'
import Footnote from '../../components/Footnote'
// Component
const Definitions = () => {
  return (
    <> 
      <PageTitle title="Adolescent & school age nutrition in ECA" />
      <div className="article_content col2">
          <p>The ECA region is a large and diverse group of 21 countries that have been through economic and nutrition transitions over the last 20 years which are likely to have affected the nutritional status of adolescents and school-aged children. The region exhibits three broad sub-sets of nutrition profile: Central and Eastern Europe<Footnote sup={'1'} text={'Albania, Bulgaria, Croatia, Romania, Turkey (apart from NE), Belarus, Bosnia & Herzegovina, Kosovo, North Macedonia, Moldova, Montenegro, Ukraine'}/>, where overweight and obesity are major concerns; the Caucasus<Footnote sup={'2'} text={'Armenia, Azerbaijan, Georgia, Turkey (North-Eastern)'}/>, where overweight and micronutrient deficiencies are issues; and Central Asia<Footnote sup={'3'} text={'Kazakhstan, Kyrgyzstan, Tajikistan, Turkmenistan, and Uzbekistan'}/>, where stunting and micronutrient deficiencies have long been high and overweight is emerging more recently as a challenge.</p>
          <p>More than 55 percent of adults in ECA region are overweight or obese, while the number of overweight children under-5-years-old in the region has more than doubled between 2000 and 2017, from 2.1 to 4.5 million, representing the largest increase globally<Footnote sup={'4'} text={'Global figures were sourced from the WB/WHO/UNICEF Joint Malnutrition Estimates, which are produced annually'}/> (FAO, 2017).</p>
          <p>Analysis of trends of malnutrition among school aged and adolescent boys and girls in the region is largely absent from global reports, but the data discussed above suggests that multiple burdens of under- and overnutrition exist in this group across all countries in the region and that malnutrition in all its forms.</p>
      </div>
    </>
  ) 
}

export default Definitions
