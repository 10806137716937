import { useState } from 'react'
// Import Flag
import Flag from '../../images/bosnia-and-herzegovina.svg'
// Import Page Elements
import Table06_2 from '../../page_elements/tables/Table06_2'
import Table07_2 from '../../page_elements/tables/Table07_2'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TitleLink from '../../components/TitleLink'
import TableTitle from '../../components/TableTitle'
import Footnote from '../../components/Footnote'
// Component
const Bosnia = () => {

  const pageTitle = 'Bosnia and Herzegovina'
  const table1Desc = 'Status of the nutrition of school-aged children and adolescents in Bosnia and Herzegovina'
  const table2Desc = 'Policy and programming environment in Bosnia and Herzegovina'

  const [showText, setShowText] = useState(true);
  const [showTable1, setShowTable1] = useState(false);
  const [showTable2, setShowTable2] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
    setShowTable2(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowTable2(false)
    setShowText(false)
  }
  function showTable2Handler(e) {
    e.preventDefault();
    setShowTable2(!showTable2)
    setShowTable1(false)
    setShowText(false)
  }

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="COUNTRY PROFILES" />
      <article>
        <PageTitle title = { pageTitle }  flag = { Flag } flagAlt = { 'Bosnia and Herzegovina Flag' }>
          <TitleLink 
            linkTo = { '/country-profiles' }
            seeText = { 'See Map: '}
            icon = {'icon-map-icon'}
            description = { 'Country Profiles' }
          />
          {!showText &&
            <TitleButton 
              showHandler = { showTextHandler }
              seeText = { 'See Text: '}
              icon = {'icon-text-icon'}
              description = { pageTitle }
            />
          }
          {!showTable1 &&
            <TitleButton 
              showHandler = { showTable1Handler }
              seeText = { 'See Table 6: '}
              icon = {'icon-tables-icon'}
              description = { table1Desc }
            />
          }
          {!showTable2 &&
            <TitleButton 
              showHandler = { showTable2Handler }
              seeText = { 'See Table 7: '}
              icon = {'icon-tables-icon'}
              description = { table2Desc }
            />
          }
        </PageTitle>
        {/* TEXT */}
        { showText && 
          <div className="article_content col2">
            <p>{'Boys aged 10-24 years had a higher prevalence of overweight, at 22.5 percent, compared with 17.7 percent in girls. Prevalence of anaemia was also higher in boys than girls:18.5 percent and 15.0 percent respectively'}<Footnote sup={'16'} text={'The anaemia cut off used for boys ≥15 years is 10g/L higher than for girls of the same age.'}/>.</p>
            <p>{'The median urinary iodine concentration of school children aged 8-10 years in 2005 was 157 µg/L, which indicates an ‘adequate’ intake.'}</p>
            <p>A 2014 study reported overweight prevalence in children aged 7 to 16 years at 14 percent in boys and 16 percent in girls, based on WHO 2009 data using IOTF BMI-for-age criteria<Footnote sup={'17'} text={'Bodzsar et al., 2014'}/>{'. A large school-based survey in Sarajevo Canton in 2008 found that almost 20 percent of children between 6 and 18 years were either overweight or obese (BMI>85th percentile), and 12.5 percent were undernourished (BMI<5th percentile) (based on CDC reference data)'}<Footnote sup={'18'} text={'Hasanbegovic et al., 2010'}/>. The study also presented a range of diet and lifestyle indicators. It found that during school hours, 42 percent of students were fed bakery products and snacks, and 21 percent consumed thickened juices. Fifty-eight percent of children consumed sweets on a daily basis, while only a third of students practised sports on a daily basis. Twenty-eight percent of students spent two hours or more sitting by the computer or TV. Sedentary lifestyle, frequent consumption of sweets and thickened juices, and unsuitable nutrition during school hours were associated with excess weight. Elementary school students were the most inactive and had higher proportions of excess weight, while secondary school students consumed the most sweets. The study proposed that targeting these lifestyle factors in schools could improve nutritional status of this age group.</p>
            <p>A study of children attending an outpatient clinic in 2013 found that 73 percent of those under 18 years are experiencing a degree of Vitamin D deficiency; 25 percent of those under 18 years had vitamin D insufficiency, 29 percent vitamin D deficiency, and 19 percent severe deficiency<Footnote sup={'19'} text={'Sokolovic et al., 2017'} />. As these children were attending a health setting, they may not be representative of the general population. However, these values are reportedly similar to findings from other central European countries, but more research is needed to clarify the causes (e.g. diet, sun exposure) and consider appropriate interventions.</p>
            <h3 className="text-main_blue pb-3">Conclusion</h3>
            <p>There was a considerable prevalence of overweight and anaemia reported in this age group in Bosnia and Herzegovina and studies have shown that sedentary lifestyles and poor diet are likely contributors. The child nutrition policy of 2013 represents a strong, positive step to address the challenges via a cross-sectoral approach through the health, education, food production and trade sectors, as well as through consideration of marketing regulations and population awareness-raising. Advocacy measures, training of health and education professionals and information generation and management are also appropriately incorporated in both the BIH Policy and the Action Plan for reduction of NCDs in RS. In addition, a range of programmes and regulations have been implemented through the school and pre-school systems to encourage active lifestyles and healthy eating and to restrict availability of unhealthy foods for young people.  Taken together these policies and programmes set out a comprehensive framework for starting to tackle the nutrition challenges in school-aged children and adolescents. It is important that these programmes are scaled up, not only in terms of coverage but also through targeting the second decade of life. The relatively high prevalence of Vitamin D deficiency among school age children and adolescents requires deeper investigation and intervention, in order to prevent the early onset of NCD risk factors.</p>

          </div>
        }
        {/* TABLE */}
        { showTable1 && 
          <div className="article_content">
            <TableTitle
              title={'Table 6'}
              description={ table1Desc }
            />
            <Table06_2 />
          </div>
        }
        {/* TABLE */}
        { showTable2 && 
          <div className="article_content">
            <TableTitle
              title={'Table 7'}
              description={ table2Desc }
            />
            <Table07_2 />
          </div>
        }
      </article>
    </div>
  ) 
}
export default Bosnia
