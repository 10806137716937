const Table15_2 = () => {
  return (    
    <table className="allBorders table-auto w-full text-left border border-main_blue xs:text-xs">
      <thead>
        <tr className="text-white">
            <th className="bg-main_blue">Policy/programme document</th>
            <th className="bg-header1">Targets/indicators with specific mention of children aged 7-18 years</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Programmes /Strategies/ Plans</span></td>
        </tr>
        <tr>
          <td>
            <p>A programme of measures to improve the state of nutrition in Montenegro with the Action Plan 2019-2020</p>
            <p className="text-txt_black_1">Montenegro Ministry of Health, June 2019</p>
          </td>
          <td>
            <p>The goals and timeframe related to chronic non-communicable diseases associated with food and nutrition are:</p>
            <ul className="bullets">
              <li>30% reduction in sodium / salt intake by 2025;</li>
              <li>Reduction of physical inactivity by 10% by 2025;</li>
              <li>Stopping the trend of increasing childhood obesity by 2025;</li>
              <li>The cessation of all forms of malnutrition by 2030</li>
            </ul>
            <p>With reference to children 7-18 years of age, the programme includes:</p>
            <ul className="bullets">
              <li>Promotion of national nutrition guidelines for pupils in pre-school institutions and primary schools</li>
              <li>Development and implementation of the Guidelines on Reduction of Food Advertising for Children with Calories, Saturated Fats, Trans fats, Sugars and Salts</li>
              <li>Promotion of the Decision to ban foods rich in sugar, saturated and trans fats and salts near primary schools and vending machines in schools.</li>
              <li>Implementation of school scheme of fruits, vegetables and milk in schools    in Montenegro</li>
              <li>Education of teaching staff in schools about proper nutrition through a programme of professional development</li>
              <li>Regular monitoring of sanitary and hygienic conditions in schools in order to improve the environment that promotes proper nutrition</li>
              <li>Continuous education about the importance of proper nutrition, food safety and physical activity, as part of the school curriculum</li>
              <li>Setting national targets for reducing obesity rates for children based on research results and strategic documents (to strengthen cross-sectoral cooperation) – establishment of a working group and draft a strategy for tackling child obesity.</li>
              <li>Preparation of national research on micronutrient deficiency anaemia in children and pregnant women</li>
              <li>Preparation and participation of the second round of research “COSI Initiative for Children” in Montenegro</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Promotional materials</span></td>
        </tr>
        <tr className="bg-pale_blue">
          <td>
            <p>Nutrition – UNICEF document</p>
            <p className="text-txt_black_1">2018</p>
          </td>
          <td>
            <p>Overview document on recent initiatives/activities in nutrition. It references 59 educational/promotional activities of which a quarter are dedicated to children in different ways: media, television broadcasts, radio shows, colourful statements for portals or newspapers, marking significant dates or lectures in institutions.</p>
            <ul className="bullets">
              <li>A specialist clinic for nutrition helps with nutrition problems; a fifth of 2018 patients were children and young people</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <p>“Healthy Life Styles” Campaign, Ministry of Health and Institute of Public health</p>
            <p className="text-txt_black_1">January 2009</p>
            <p className="text-txt_black_1">(from WHO database)</p>
          </td>
          <td>
            <p>Media campaign implemented through primary schools. Objectives:</p>
            <ul className="bullets">
              <li>Raise population awareness on health effects of high intake of fats, sugars and salt/sodium;</li>
              <li>Raise population awareness on how to consume healthier diets, including how    to include more fruits and vegetables in the diet.</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  ) 
}
export default Table15_2
