import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import SortableData from '../../constants/SortableData'

// Component
const Figure02 = () => {

  const topValue = 35;
  const charData = React.useMemo( () => [
    { 
      id: 1,
      country: 'Albania',
      classItem: 'albania',
      girls: 19.0,
      boys: 28.2,
      girlsPerc: (19.0 * 100) / topValue,
      boysPerc: (28.2 * 100) / topValue,
      girlsId: 'girls01',
      boysId: 'boys01',
    },
    { 
      id: 2,
      country: 'Armenia',
      classItem: 'armenia',
      girls: 20.8,
      boys: 22.5, 
      girlsPerc: (20.8 * 100) / topValue,
      boysPerc: (22.5 * 100) / topValue,
      girlsId: 'girls02',
      boysId: 'boys02', 
    },
    { 
      id: 3,
      country: 'Azerbaijan',
      classItem: 'azerbaijan',
      girls: 24.7,
      boys: 22.7, 
      girlsPerc: (24.7 * 100) / topValue,
      boysPerc: (22.7 * 100) / topValue,
      girlsId: 'girls03',
      boysId: 'boys03',
    },
    { 
      id: 4,
      country: 'Belarus',
      classItem: 'belarus',
      girls: 11.6,
      boys: 21.5, 
      girlsPerc: (11.6 * 100) / topValue,
      boysPerc: (21.5 * 100) / topValue, 
      girlsId: 'girls04',
      boysId: 'boys04',
    },
    { 
      id: 5,
      country: 'Bosnia & Herzegovina',
      classItem: 'bosnia',
      girls: 17.7, 
      boys: 22.5, 
      girlsPerc: (17.7 * 100) / topValue,
      boysPerc: (22.5 * 100) / topValue, 
      girlsId: 'girls05',
      boysId: 'boys05',
    },
    { 
      id: 6,
      country: 'Bulgaria',
      classItem: 'bulgaria',
      girls: 23.2,
      boys: 27.6, 
      girlsPerc: (23.2 * 100) / topValue,
      boysPerc: (27.6 * 100) / topValue, 
      girlsId: 'girls06',
      boysId: 'boys06',
    },
    { 
      id: 7,
      country: 'Croatia',
      classItem: 'croatia',
      girls: 20.9,
      boys: 30.3, 
      girlsPerc: (20.9 * 100) / topValue,
      boysPerc: (30.3 * 100) / topValue, 
      girlsId: 'girls07',
      boysId: 'boys07',
    },
    { 
      id: 8,
      country: 'Georgia',
      classItem: 'georgia',
      girls: 20.8,
      boys: 33.6, 
      girlsPerc: (20.8 * 100) / topValue,
      boysPerc: (33.6 * 100) / topValue, 
      girlsId: 'girls08',
      boysId: 'boys08',
    },
    { 
      id: 9,
      country: 'Kazakhstan',
      classItem: 'kazakhstan',
      girls: 15.3,
      boys: 17.4, 
      girlsPerc: (15.3 * 100) / topValue,
      boysPerc: (17.4 * 100) / topValue, 
      girlsId: 'girls09',
      boysId: 'boys09',
    },
    { 
      id: 10,
      country: 'Kyrgyztan',
      classItem: 'kyrgyztan',
      girls: 13.1,
      boys: 11.6, 
      girlsPerc: (13.1 * 100) / topValue,
      boysPerc: (11.6 * 100) / topValue, 
      girlsId: 'girls10',
      boysId: 'boys10',
    },
    { 
      id: 11,
      country: 'North Macedonia',
      classItem: 'macedonia',
      girls: 19.5,
      boys: 29.3, 
      girlsPerc: (19.5 * 100) / topValue,
      boysPerc: (29.3 * 100) / topValue, 
      girlsId: 'girls11',
      boysId: 'boys11',
    },
    { 
      id: 12,
      country: 'Moldava',
      classItem: 'moldava',
      girls: 17.1,
      boys: 25.0, 
      girlsPerc: (17.1 * 100) / topValue,
      boysPerc: (25.0 * 100) / topValue, 
      girlsId: 'girls12',
      boysId: 'boys12',
    },
    { 
      id: 13,
      country: 'Montenegro',
      classItem: 'montenegro',
      girls: 22.9,
      boys: 32.1, 
      girlsPerc: (22.9 * 100) / topValue,
      boysPerc: (32.1 * 100) / topValue, 
      girlsId: 'girls13',
      boysId: 'boys13',
    },
    { 
      id: 14,
      country: 'Romania',
      classItem: 'romania',
      girls: 19.4,
      boys: 22.9, 
      girlsPerc: (19.4 * 100) / topValue,
      boysPerc: (22.9 * 100) / topValue, 
      girlsId: 'girls14',
      boysId: 'boys14',
    },
    { 
      id: 15,
      country: 'Servia',
      classItem: 'servia',
      girls: 20.9,
      boys: 22.7, 
      girlsPerc: (20.9 * 100) / topValue,
      boysPerc: (22.7 * 100) / topValue, 
      girlsId: 'girls15',
      boysId: 'boys15',
    },
    { 
      id: 16,
      country: 'Tajikistan',
      classItem: 'Ttjikistan',
      girls: 11.7,
      boys: 18.5, 
      girlsPerc: (11.7 * 100) / topValue,
      boysPerc: (18.5 * 100) / topValue, 
      girlsId: 'girls16',
      boysId: 'boys16',
    },
    { 
      id: 17,
      country: 'Turkey',
      classItem: 'turkey',
      girls: 25.5,
      boys: 28.0, 
      girlsPerc: (25.5 * 100) / topValue,
      boysPerc: (28.0 * 100) / topValue, 
      girlsId: 'girls17',
      boysId: 'boys17',
    },
    { 
      id: 18,
      country: 'Turkmenistan',
      classItem: 'turkmenistan',
      girls: 27.5,
      boys: 23.8, 
      girlsPerc: (27.5 * 100) / topValue,
      boysPerc: (23.8 * 100) / topValue, 
      girlsId: 'girls18',
      boysId: 'boys18',
    },
    { 
      id: 19,
      country: 'Ukraine',
      classItem: 'ukraine',
      girls: 15.7,
      boys: 20.5, 
      girlsPerc: (15.7 * 100) / topValue,
      boysPerc: (20.5 * 100) / topValue, 
      girlsId: 'girls19',
      boysId: 'boys19',
    },
    { 
      id: 20,
      country: 'Belarus',
      classItem: 'belarus',
      girls: 26.0,
      boys: 26.3, 
      girlsPerc: (26.0 * 100) / topValue,
      boysPerc: (26.3 * 100) / topValue, 
      girlsId: 'girls20',
      boysId: 'boys20',
    },
  ])

  const [showGirls, setShowGirls] = useState(true);
  const handleClickGirls = () => setShowGirls(!showGirls)

  const [showBoys, setShowBoys] = useState(true);
  const handleClickBoys = () => setShowBoys(!showBoys)
    
  const { items, requestSort, sortConfig } = SortableData(charData);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <>
      <div className="barChartBox">
        <div className="barChart">
          {items.map(row => {
            return (
              <div className="chartRow" id={row.id} key={row.id}>
                <div className="labelBox">{ row.country }</div>
                <div className="bars">
                  {/* GIRLS */}
                  {showGirls && 
                    <>
                      <a
                        className="bar"
                        style={{
                          backgroundColor: "#00AEEF",
                          width: `${row.girlsPerc}%`,
                          height: "12px",
                        }}
                        data-tip data-for={row.girlsId}
                      ></a>
                      <ReactTooltip id={row.girlsId} className='barTooltipe' aria-haspopup='true'>
                        <h3>{row.country}</h3>
                        <p>Girls 2019</p>
                        <p className="tollValue">{row.girls}</p>
                      </ReactTooltip>
                    </>
                  }
                  {/* BOYS */}
                  {showBoys && 
                    <>
                      <a
                        className="bar"
                        style={{
                          backgroundColor: "#3b4395",
                          width: `${row.boysPerc}%`,
                          height: "12px",
                        }}
                        data-tip data-for={row.boysId}
                      ></a>
                      <ReactTooltip id={row.boysId}  className='barTooltipe' aria-haspopup='true'>
                        <h3>{row.country}</h3>
                        <p>Boys 2019</p>
                        <p className="tollValue">{row.boys}</p>
                      </ReactTooltip>
                    </>
                  }

                </div>
              </div>
            )
          })}
        </div>
        {/* BACK LINES */}
        <div className="lines" aria-hidden="true">
          <div className="labelBox solidLine"><span>0</span></div>
          <div className="dotLines">
            <div><span>5</span></div>
            <div><span>10</span></div>
            <div><span>15</span></div>
            <div><span>20</span></div>
            <div><span>25</span></div>
            <div><span>30</span></div>
            <div><span>35</span></div>
          </div>
        </div>
      </div>
      
      {/* SORT CHECKBOXES */}
      <div className="flex text-main_blue text-sm">Select to sort</div>
      <div className="sortBox">
        {/* LEFT BOX */}
        <div className="left_box sortBtnBox">
          {/* SORT COUNTRY */}
          <button
            className={`${getClassNamesFor('country')} mr-6 ani03s`}
            type="button"
            onClick={() => requestSort('country')}
          >Country</button>
          {!showBoys || !showGirls ? 
            <>
              {/* SORT BOYS */}
              {showBoys && 
                <button
                  className={`${getClassNamesFor('boys')} ani03s`}
                  type="button"
                  onClick={() => requestSort('boys')}
                >Boys</button>
              }
              {showGirls &&
                <button
                  className={`${getClassNamesFor('girls')} mr-6 ani03s`}
                  type="button"
                  onClick={() => requestSort('girls')}
                >Girls</button> 
              }
            </> : ''
          }
        </div>
        {/* RIGHT BOX */}
        <div className="right_box">
          {/* CHECKBOX GIRLS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Girls</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickGirls} defaultChecked={showGirls} type="checkbox" id="girls"/>
                <label htmlFor="girls"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX BOYS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Boys</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickBoys} defaultChecked={showBoys} type="checkbox" id="boys"/>
                <label htmlFor="boys"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Figure02 
