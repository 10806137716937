const Table12_2 = () => {
  return (
    <>  
      <div className="table-scroll relative">
        <table className="tableRightBorder table-auto w-full text-left xs:text-xs">
          <thead>
            <tr className="text-white bg-main_blue">
              <th>
                <div className="bg-main_blue min-w-80">Survey</div>
              </th>
              <th>
                <div className="min-w-80">Age group</div>
              </th>
              <th>
                <div className="min-w-80">Boys/Girls (non-pregnant)</div>
              </th>
              <th>
                <div className="min-w-80">{`Thin % (BMI for age <-2SD)`}</div>
              </th>
              <th>
                <div className="min-w-80">{`HAZ% <- 2SD`}</div>
              </th>
              <th>
                <div className="min-w-80">Overweight or obese % (95% C.I.)</div>
              </th>
              <th>
                <div className="min-w-80">Anaemia % (95% C.I.)</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-pale_blue">
              <td rowSpan="2" className="text-main_blue bg-pale_blue">GSHS 2007</td>
              <td rowSpan="2">12-15 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">3.3 (2.2, 4.9)</td>
              <td className="text-right">3.9 (2.1, 7.2)</td>
              <td className="text-right">14.6 (12.6, 16.9)</td>
              <td className="text-right">-</td>
            </tr>
            <tr className="bg-pale_blue">
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">2.6 (1.6, 4.3)</td>
              <td className="text-right">3.4 (2.0, 5.9	28.2</td>
              <td className="text-right">(23.5, 33.4)</td>
              <td className="text-right">-</td>
            </tr>
            <tr>
              <td rowSpan="2" className="text-main_blue bg-white">COSI-2 2009/10</td>
              <td rowSpan="2">7 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">39.5</td>
              <td className="text-right">-</td>
            </tr>
            <tr>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">52.2</td>
              <td className="text-right">-</td>
            </tr>
            <tr className="bg-pale_blue">
              <td rowSpan="2" className="text-main_blue bg-pale_blue">COSI-3 2013</td>
              <td rowSpan="2">6 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">26.0</td>
              <td className="text-right">-</td>
            </tr>
            <tr className="bg-pale_blue">
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">28.0</td>
              <td className="text-right">-</td>
            </tr>
            <tr>
              <td rowSpan="2" className="text-main_blue bg-white">COSI-4 2015-2017</td>
              <td rowSpan="2">7 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">42</td>
              <td className="text-right">-</td>
            </tr>
            <tr>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">47</td>
              <td className="text-right">-</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="bg-pale_blue text-main_blue">IHME</td>
              <td>10-24 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">19.5</td>
              <td className="text-right">15.5</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="bg-pale_blue">
                <p>2016</p>
                <p>(modelled data from 1990-2016)</p>
                <p>(IOTF) (Azzopardi 2018)</p>
              </td>
              <td>10-24 y</td>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">29.3</td>
              <td className="text-right">19.2</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  ) 
}
export default Table12_2
