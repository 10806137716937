import PropTypes from 'prop-types'
import { useState } from 'react'
// Component
const Modal = ({ textLink, children}) => {
  
  const [show, setShow] = useState(false);
  const closeModalHandler = () => setShow(false);

  return (
    <>
      <button 
        onClick={() => setShow(true)}
        className="modalBtn text-sm hover:text-sec_blue ani03s">{ textLink }
      </button>
      { show && 
        <span
          onClick={closeModalHandler}
          style={{ opacity: show ? 1 : 0 }}
          className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
          <span className="px-8 py-6 bg-white relative max-w-700">
            <span
              onClick={closeModalHandler}
              className="icon-close absolute top-1 right-1 cursor-pointer">
            </span>
            { children }
          </span>
        </span>
      }
    </>
  )
}

Modal.propTypes = {
  textLink: PropTypes.string.isRequired,
}

export default Modal
