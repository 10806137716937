import { useState } from "react";
import PropTypes from 'prop-types'

const NavItem = ({title, navActive, children}) => {

    const [open, setOpen] = useState(navActive);

    return (
        <div 
            className="
                text-sm text-left text-white w-full
                pt-1 pb-2"
            >
            <div 
                className={`
                    ani03s cursor-pointer uppercase px-3 pb-1 inline-block
                    ${open || navActive ? 'text-main_blue' : 'hover:text-main_blue'}
                `}
                onClick={e => setOpen(!open)}>
                { title } 
                <span className={`inline-block icon-down text-xs
                ${open ? 'transform rotate-180 pr-3' : 'pl-3'}`}></span>
            </div>
        
            { open && <ul 
                className="
                    w-full px-3 py-2 bg-main_blue
                ">{children}</ul>}
        </div>
    )
}

NavItem.propTypes = {
    title: PropTypes.string.isRequired,
}

export default NavItem
