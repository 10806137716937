import { useState } from 'react'
// Import Flag
import Flag from '../../images/georgia.svg'
// Import Page Elements
import Table08_2 from '../../page_elements/tables/Table08_2'
import Table09_2 from '../../page_elements/tables/Table09_2'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TitleLink from '../../components/TitleLink'
import TableTitle from '../../components/TableTitle'
import Footnote from '../../components/Footnote'
// Component
const Georgia = () => {

  const pageTitle = 'Georgia'
  const table1Desc = 'Status of the nutrition of school-aged children and adolescents in Georgia'
  const table2Desc = 'Policy and programming environment in Georgia'

  const [showText, setShowText] = useState(true);
  const [showTable1, setShowTable1] = useState(false);
  const [showTable2, setShowTable2] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
    setShowTable2(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowTable2(false)
    setShowText(false)
  }
  function showTable2Handler(e) {
    e.preventDefault();
    setShowTable2(!showTable2)
    setShowTable1(false)
    setShowText(false)
  }

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="COUNTRY PROFILES" />
      <article>
        <PageTitle title = { pageTitle }  flag = { Flag } flagAlt = { 'Georgia Flag' }>
          <TitleLink 
            linkTo = { '/country-profiles' }
            seeText = { 'See Map: '}
            icon = {'icon-map-icon'}
            description = { 'Country Profiles' }
          />
          {!showText &&
            <TitleButton 
              showHandler = { showTextHandler }
              seeText = { 'See Text: '}
              icon = {'icon-text-icon'}
              description = { pageTitle }
            />
          }
          {!showTable1 &&
            <TitleButton 
              showHandler = { showTable1Handler }
              seeText = { 'See Table 8: '}
              icon = {'icon-tables-icon'}
              description = { table1Desc }
            />
          }
          {!showTable2 &&
            <TitleButton 
              showHandler = { showTable2Handler }
              seeText = { 'See Table 9: '}
              icon = {'icon-tables-icon'}
              description = { table2Desc }
            />
          }
        </PageTitle>
        {/* TEXT */}
        { showText && 
          <div className="article_content col2">
            <p>Prevalence of overweight and obesity was high among children and adolescents aged 10-24 years in Georgia, affecting more than one in every five girls and a third of boys. Anaemia affected around a fifth of girls and boys.</p>
            <p>Median UIC in school-aged children was 298mg<Footnote sup={'20'} text={'IGN Global Scorecard 2019'}/>  categorising Georgia as ‘adequate’ in terms of iodine intake.</p>
            <p>An upwards trend in registered cases of Type 2 diabetes mellitus has been reported in recent years: 33 cases were registered in children under 15 in 2017 and a 2015 survey<Footnote sup={'21'} text={'The National Centre for Disease and Public Health (NCDC) survey of Youth and Adolescent Health in 2015'}/> found that 7.4 percent of the sample of 15-19 years olds had high blood glucose. Among primary risk factors for adolescents and young people, iron deficiency contributed to 3 percent of Disability-Adjusted Life Years.</p>
            <p>A cross-sectional survey of schools in Tbilisi found that of the children aged 6 – 8 years 3.1 percent were underweight, 12.3 percent overweight and 1.4 percent obese<Footnote sup={'22'} text={'Kherkheulidze et al., 2012 using weight-for-age criteria.'}/>. A dietary assessment revealed that intake of fruits and vegetables among this group was quite low, as was consumption of milk and milk products, while intake of bread, pastry and sweets was high. Daily consumption of sweets was significantly higher in girls (64.2 percent) than in boys (47.5 percent). Only 62 percent of children ate breakfast, and most children (60.4 percent) viewed TV during mealtimes or played computer games.</p>
            <p>A 2015 survey among children aged 10-17 years of age<Footnote sup={'23'} text={'National Nutrition Research in Georgia. Oxfam 2015'}/>, found that children’s dietary diversity scores were slightly lower than that of adults (4.68 vs 4.90) and that more than half the children didn’t consume sufficient protein and iron-rich products: meat, poultry, fish, seafood, eggs and legumes were consumed regularly only by a low proportion of the sample. The study also found that children consumed fewer vegetables, oils and fats and milk/dairy products than adults. The majority of children in the sample reported eating bread every day and consuming sweets and fizzy drinks several times a week. A key policy recommendation from this study was to increase the role of the Ministry of Education in integrating the principles of healthy eating into the school curriculum, developing nutrition standards for schools and raising awareness to help children make healthy food choices. The report notes that there is currently no integrated government policy on healthy nutrition in schools and no official documents, guidelines and standards to regulate pupils’ nutrition in schools.</p>
            <h3 className="text-main_blue pb-3">Conclusion</h3>
            <p>There was a high prevalence of overweight and anaemia reported in this age group in Georgia and a particularly worrying level of overweight in boys. The limited evidence available suggests that sedentary lifestyles, poor diet and eating practices amongst children and adolescents are likely contributors. Very few policies or programmes could be found that are aimed at addressing these issues: one document describing recommendations for healthy feeding in schools and one educational document on nutrition for secondary school pupils. There appears to be a huge gap in the policy and regulatory framework to address the urgent nutrition challenges among school age children and adolescents in Georgia. In Georgia there is no legislation for Universal Flour Fortification and given the relatively high prevalence of anaemia in the country, alternative strategies to prevent anaemia should be adopted.</p>
          </div>
        }
        {/* TABLE */}
        { showTable1 && 
          <div className="article_content">
            <TableTitle
              title={'Table 8'}
              description={ table1Desc }
            />
            <Table08_2 />
          </div>
        }
        {/* TABLE */}
        { showTable2 && 
          <div className="article_content">
            <TableTitle
              title={'Table 9'}
              description={ table2Desc }
            />
            <Table09_2 />
          </div>
        }
      </article>
    </div>
  ) 
}
export default Georgia
