import PropTypes from 'prop-types'
// COMPONENT
const TableTitle = ({ title, description }) => {
  return (
    <div className="w-full flex pb-1">
      <div className="
        max-w-100 min-w-100 xs:max-w-80 xs:min-w-80
      bg-main_blue text-white font-bold text-sm
        xs:text-xs p-1 align-top uppercase
      ">{title}</div>
      <div className="px-2 py-1 leading-5 flex-grow xs:text-sm">{description}</div>
    </div>      
  )
}
// PROPTYPES
TableTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
// EXPORT
export default TableTitle