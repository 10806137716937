// Import Image
import Image from '../../images/methods_2.jpg'
// Import Components
import CoverImg from '../../components/CoverImg'
import PageTitle from '../../components/PageTitle'
// Component
const Limitations = () => {
  return (
      <>
        <PageTitle title="Limitations" />
        <CoverImg SrcImg = { Image } />
        <div className="article_content col2">
          <p>The prevalence of malnutrition amongst the adolescent population of ECA Region is difficult to assess. This is because representative and comparable data on the nutritional status of adolescents is severely lacking. Data that do exist generally cannot be compared because of differences between the age groups studied or reported, the different reference populations used for comparison purposes, and differences in thresholds used to classify children.</p>
        </div>
      </>
  ) 
}
export default Limitations
