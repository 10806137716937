import { useState } from 'react'
import Footnote from '../../components/Footnote'
// Import Flag
import Flag from '../../images/tajikistan.svg'
// Import Page Elements
import Table16_2 from '../../page_elements/tables/Table16_2'
import Table17_2 from '../../page_elements/tables/Table17_2'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TitleLink from '../../components/TitleLink'
import TableTitle from '../../components/TableTitle'
// Component
const Tajikistan = () => {

  const pageTitle = 'Tajikistan'
  const table1Desc = 'Status of the nutrition of school-aged children and adolescents in Tajikistan'
  const table2Desc = 'Policy and programming environment in Tajikistan'

  const [showText, setShowText] = useState(true);
  const [showTable1, setShowTable1] = useState(false);
  const [showTable2, setShowTable2] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
    setShowTable2(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowTable2(false)
    setShowText(false)
  }
  function showTable2Handler(e) {
    e.preventDefault();
    setShowTable2(!showTable2)
    setShowTable1(false)
    setShowText(false)
  }

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="COUNTRY PROFILES" />
      <article>
        <PageTitle title = { pageTitle }  flag = { Flag } flagAlt = { 'Tajikistan Flag' }>
          <TitleLink 
            linkTo = { '/country-profiles' }
            seeText = { 'See Map: '}
            icon = {'icon-map-icon'}
            description = { 'Country Profiles' }
          />
          {!showText &&
            <TitleButton 
              showHandler = { showTextHandler }
              seeText = { 'See Text: '}
              icon = {'icon-text-icon'}
              description = { pageTitle }
            />
          }
          {!showTable1 &&
            <TitleButton 
              showHandler = { showTable1Handler }
              seeText = { 'See Table 16: '}
              icon = {'icon-tables-icon'}
              description = { table1Desc }
            />
          }
          {!showTable2 &&
            <TitleButton 
              showHandler = { showTable2Handler }
              seeText = { 'See Table 17: '}
              icon = {'icon-tables-icon'}
              description = { table2Desc }
            />
          }
        </PageTitle>
        {/* TEXT */}
        { showText && 
          <div className="article_content col2">
            <p>In Tajikistan, the DHS survey of 2012 reported a low prevalence of thin girls aged 15-19 but a higher prevalence of stunted girls (9.9 percent). The prevalence of overweight and obesity in girls aged 15-19 was relatively low compared to other countries in this Region at 8.0 percent.<p>
            </p>Institute of Health Metrics and Evaluation (IHME) data using IOTF thresholds reported a higher prevalence of overweight of 11.7 percent in girls and 18.5 percent in boys aged 10-24 years.</p>
            <p>The prevalence of anaemia for girls 15-19 years was 33.3 percent in the 2012 DHS survey. IHME reported approximately one in four adolescents aged 10-24 years as being anaemic with prevalence of 25.8 percent for girls and 21.3 percent for boys. By either standard these rates are of concern.</p>
            <p>The median urinary iodine concentration of school-aged children in 2009 was 139 µg/L, which indicates an ‘adequate’ intake. However, in a survey conducted in 2016 on under-5 children the UIC was at 87.5139 µg/L, although measuring UIC in this age group is not common, this level might indicate that the level is ‘inadequate’.</p>
            <h3 className="text-main_blue pb-2">Conclusion</h3>
            <p>The prevalence of stunting in girls aged 15-19 is high for the region, at almost 10 percent, reflecting that Tajikistan continues to experience challenges of undernutrition alongside overweight and micronutrient deficiency, with anaemia prevalence a key challenge – a triple burden of malnutrition. While prevalence of overweight is one of the lowest in the region, it is likely that this could increase and therefore, preventative measures and activities that address both underweight and overweight (“double-duty actions”<Footnote 
              sup={'35'} 
              text={'  double-duty actions” refer to interventions, programmes, and policies that have the ability to simultaneously reduce the risk or burden of both undernutrition and overweight, obesity, or diet-related NCDs. Examples include promotion of exclusive breastfeeding and school nutrition/food policies.'}
              linkTxt = {'link'}
              linkUrl = {'https://apps.who.int/iris/bitstream/handle/10665/255414/WHO-NMH-NHD-17.2-eng.pdf'}
            />
            ) are relevant for the country. The policy framework is as yet under-developed for this age group, with the main focus being on school meals. Coordinated strategies involving additional sectors and delivery systems - such as the health system, particularly for anaemia prevention, and food systems for regulating and improving marketing of healthy diets - as well as community approaches targeting additional aspects of children’s and adolescent’s lives, will be required to ensure a comprehensive approach to tackle and reverse the malnutrition challenges. The Universal Salt Iodization program still requires maintenance and quality improvements in order to reach successful and sustainable results and the newly approved legislation on food fortification of staple food products requires effective enforcement to significantly reduce the prevalence of anaemia in the country.
</p>
          </div>
        }
        {/* TABLE */}
        { showTable1 && 
          <div className="article_content">
            <TableTitle
              title={'Table 16'}
              description={ table1Desc }
            />
            <Table16_2 />
          </div>
        }
        {/* TABLE */}
        { showTable2 && 
          <div className="article_content">
            <TableTitle
              title={'Table 17'}
              description={ table2Desc }
            />
            <Table17_2 />
          </div>
        }
      </article>
    </div>
  ) 
}
export default Tajikistan
