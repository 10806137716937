import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip';
// Component
const Bar = ({ barColor, barWidth, barHeight, dataTipId, tipLabel, tipSubLabel, tipValue }) => {
  return (
    <>
      <a
        className="bar"
        style={{
          backgroundColor: `${ barColor }`,
          width: `${barWidth}%`,
          height: `${ barHeight }`,
        }}
        data-tip data-for={ dataTipId }
      ></a>
      <ReactTooltip id={ dataTipId } className='barTooltipe' aria-haspopup='true'>
        <h3>{ tipLabel }</h3>
        <p>{ tipSubLabel }</p>
        <p className="tollValue">{ tipValue }</p>
      </ReactTooltip>
    </>
  )
}
// PROPTYPES
Bar.propTypes = {
  barColor: PropTypes.string.isRequired,
  barWidth: PropTypes.number.isRequired,
  barHeight: PropTypes.string.isRequired,
  dataTipId: PropTypes.string.isRequired,
  tipLabel: PropTypes.string.isRequired,
  tipSubLabel: PropTypes.string.isRequired,
  tipValue: PropTypes.number.isRequired,
}
export default Bar 
