import React, { useState } from 'react';
// Import Pages Elements
import Map01 from '../../page_elements/maps/Map01'
// Import Image
import Image from '../../images/findings_3.jpg'
// Import Components
import CoverImg from '../../components/CoverImg'
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TableTitle from '../../components/TableTitle'
// Component
const Programme = () => {

  const pageTitle = 'Programme and policy environment'
  const figureDesc = 'Availability of Child and Adolescent Health Strategy, Policy on unhealthy foods in schools and regulations on marketing to children in 2017 (source: WHO 2018)'

  const [showText, setShowText] = useState(true);
  const [showFigure, setShowFigure] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowFigure(false)
  }
  function showFigureHandler(e) {
    e.preventDefault();
    setShowFigure(!showFigure)
    setShowText(false)
  }

  return (
    <>
      <PageTitle title = { pageTitle } >
        {!showText &&
          <TitleButton 
            showHandler = { showTextHandler }
            seeText = { 'See Text: '}
            icon = {'icon-text-icon'}
            description = { pageTitle }
          />
        }
        {!showFigure &&
          <TitleButton 
            showHandler = { showFigureHandler }
            seeText = { 'See Map 1: '}
            icon = {'icon-map-icon'}
            description = { figureDesc }
          />
        }
        </PageTitle>
        { showText && 
          <>
            <CoverImg SrcImg = { Image } />
            <div className="article_content col2">
              <p>The programme policy review found that countries in the ECA region are increasingly putting in place measures for controlling the presence of unhealthy food in the schools, however countries are less advanced in applying approaches to tackle wider marketing beyond the school environment.</p>
            </div> 
          </>
        }
        { showFigure && 
          <div className="article_content">
            <TableTitle
              title={'Map 1'}
              description={ figureDesc }
            />
            <Map01 />
          </div> 
        }
    </>
  ) 
}

export default Programme
