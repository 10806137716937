import { useEffect, useState, useRef } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
// Import Components
import SocialLink from './SocialLink'
// COMPONET
const Social = () => {

  const nodeSocial = useRef();

  // const shareUrl = window.location.href;
  const shareUrl = 'http://e-bulletin-1.formatoverde.pt/';
  const title = 'e-bulletin 4';

  const [showShare, setShowShare] = useState(false);

  const handleClick = e => {
    if (nodeSocial.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setShowShare(false);
  };

  useEffect(() => {
      document.addEventListener("mousedown", handleClick);
      return () => {
      document.removeEventListener("mousedown", handleClick);
      };
  }, []);

  return (
    <div
      ref={nodeSocial} 
      className="
        flex flex-col 
        items-baseline fixed 
        bottom-4 right-6 z-50">
      {showShare && 
        <div className="flex flex-col self-end pr-1">
          <FacebookShareButton
            url={shareUrl}
            quote={title}
          >
            <FacebookIcon size={30} round />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={title}
          >
            <TwitterIcon size={30} round />
          </TwitterShareButton>
          <LinkedinShareButton
            url={shareUrl}
          >
            <LinkedinIcon size={30} round />
          </LinkedinShareButton>
        </div>
      }
      <div className="flex flex-row">
          <SocialLink 
            socialIcon={`icon-facebook-icon`}
            socialUrl={`https://www.facebook.com/unicef/`}
          />
          <SocialLink 
            socialIcon={`icon-twitter-icon`}
            socialUrl={`https://twitter.com/unicef`}
          />
          <SocialLink 
            socialIcon={`icon-web-icon`}
            socialUrl={`https://www.unicef.org/`}
          />
          <div 
            onClick={e => setShowShare(!showShare)}
            className="relative flex flex-col justify-center items-center w-7 h-7 m-1 bg-main_blue hover:bg-sec_blue outline-none rounded-full ani03s">
            <span className="absolute icon-share-icon top-0.5 text-white text-lg"></span>
          </div>  
        </div>
    </div>
  )
}


export default Social