import PropTypes from 'prop-types'
// Component
const LabelBox = ({ label, showGirls, showBoys }) => {
  return (
    <div className="labelBox flex justify-end items-stretch">
      <div className="self-center">{ label }</div>
      <div className="flex flex-col items-stretch pl-2">
          {showGirls &&
            <div className="flex-grow flex m-2">
              <span className="icon-female self-center"></span>
            </div>
          }
          {showBoys &&
            <div className="flex-grow flex m-2">
              <span className="icon-male self-center"></span>
            </div>
          }
      </div>
    </div>
  )
}
// PROPTYPES
LabelBox.propTypes = {
  label: PropTypes.string.isRequired,
}
// EXPORT
export default LabelBox 