const Table11_2 = () => {
  return (    
    <table className="allBorders table-auto w-full text-left border border-main_blue xs:text-xs">
      <thead>
        <tr className="text-white">
            <th className="bg-main_blue">Policy/programme document</th>
            <th className="bg-header1">Targets/indicators with specific mention of children aged 7-18 years</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Programmes /Strategies/ Plans</span></td>
        </tr>
        <tr>
          <td>Food Security and Nutrition Programme (FSNP), Kyrgyz Republic 2019-2025</td>
          <td>
            <p>Under improving quality and coverage of nutrition specific measures, the following activities and targets are mentioned:</p>
            <ul className="bullets">
              <li>Creation of a regulatory framework to provide vulnerable groups with anaemia (children under 2, adolescent girls 15-17, pregnant and lactating women) with iron and folic acid supplementation and enrichment of home-made food with Micronutrient Powders</li>
              <li>Provision of supplementation with iron and folic acid medication for adolescent girls: by 2021 all adolescent girls with established anaemia  (35%  of  all adolescent girls) will be provided with iron and  folic  acid.  By  2023,  at  least 80% of all adolescent girls are given iron and folic acid</li>
              <li>Lessons for school children on food safety and prevention of contamination</li>
              <li>Improvement of school meals: amendments to normative act re school meals in accordance with best practice, training of 230 school cooks, improved equipment and facilities -`{'>'}` improved school meal quality</li>
              <li>Improved training in nutrition, food security, food safety for teachers in primary and secondary school</li>
              <li>Approve a curriculum on nutrition and food safety/hygiene for primary and secondary teachers</li>
              <li>Development of messages for school age children on nutrition</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Guidelines /Legislation</span></td>
        </tr>
        <tr>
          <td>Training of trainer modules on Healthy Nutrition (in Russian language)</td>
          <td>
            <p>The training package consists of 4 modules – Food/nutrition culture, the food ‘pyramid’, food security and eating disorders</p>
            <p>he aim of the training guide is to improve skills in working with adults to support   VHCs with nutrition campaign activities, implementation of nutrition  programmes  in the school system, social mobilisation of the population and specific target groups in nutrition campaign, strengthen media engagement in nutrition promotion, monitoring and analysis of nutrition campaign implementation.</p>
            <p>The following components are relevant to adolescent nutrition:</p>
            <ul className="bullets">
              <li>Nutritional requirements at important life stages, including during adolescence and consequences of eating too little or too much are emphasised.</li>
              <li>Diet of growing children and  adolescents  emphasised.  ‘Free  schedule’ meals (i.e. not eating at fixed times with families) common with teenagers, are discouraged and eating at table with family, coupled with cultural table behaviours/habits are promoted.</li>
              <li>In the session on the roles of nutrients, the importance of regular protein intake  in childhood and adolescence is emphasised – a mixture of both vegetable and animal proteins – share of animal proteins 50% of the diet</li>
              <li>The session on unhealthy foods notes that the main consumers of harmful foods are often children and teenagers and highlights the attractiveness of food packaging such as crisps, sugary drinks, fast food etc. targeted to this group. A list of most frequently consumed harmful products is provided.</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  ) 
}
export default Table11_2
