import { Link } from "react-router-dom";
// Component
const Table19_2 = () => {
  return (
    <>
      <table className="allBorders table-auto w-full text-left border border-main_blue xs:text-xs">
        <thead>
          <tr className="text-white">
              <th className="bg-main_blue">Policy/programme document</th>
              <th className="bg-header1">Targets/indicators with specific mention of children aged 7-18 years</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center bg-header2" colspan="2"><span className="text-white">Programmes /Strategies/ Plans</span></td>
          </tr>
          <tr>
            <td>
              <p>Turkey Health, Nutrition and Active Life Programme (2014-2017)</p>
              <p className="text-txt_black_1">Ministry of Health</p>
            </td>
            <td>
              <p>The aim of this programme was to combat obesity. In relation to children aged 7-18 years, the goal of the programme is:</p>
              <p>Formal and non-formal education programmes to contribute to the acquisition of adequate and balanced nutrition and regular physical activity habits for preschool and school children and adolescents.</p>
              <p>Strategies included:</p>
              <ol className="list-decimal pl-8">
                <li className="pl-2 pb-6">Inform preschool and school age children, teachers and parents how to fight obesity</li>
                <li className="pl-2 pb-6">Ensure the improvement of adequate and balanced nutrition and physical activity curriculum in preschool, primary, secondary and university.</li>
                <li className="pl-2 pb-6">Identify at-risk (undernourished, overweight and obese) students in pre-school, primary, secondary and universities.</li>
                <li className="pl-2 pb-6">For adequate and balanced nutrition in childhood and adolescence; conduct nutritional programmes to encourage the consumption of nutrients;</li>
              </ol>
              <p>It mentions a commitment to nutrition research for school-aged children, amongst other groups.</p>
            </td>
          </tr>
          <tr className="bg-pale_blue">
            <td>Nutrition Friendly School Programme (since 2010)</td>
            <td>
              <p>The programme aims to create environments for healthy nutrition and raise awareness. It aims to fight obesity, promote healthy diets and physical activity.</p>
              <p>1423 schools had been certified with the “certificate of nutrition friendly school” through the Nutrition Friendly Schools Programme as of 2015</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>School Milk Programme (since 2012</p>
              <p>Raisins Programme (since 2015) <span className="text-txt_black_1">(no detailed information available)</span></p>
            </td>
            <td>
              <p>The School Milk Programme, aims to boost drinking milk and consuming healthy and balanced nutrition habits of primary school students.</p>
              <p>200 ml milk packs distributed to 6 million students in 32, 000 schools under     the programme with the aim of providing better nutrition for them. 2017/2018 school year.</p>
              <a className="contentLink ani03s" href="https://www.saglik.gov.tr/EN,32386/the-school-milk-program-at-7th-year.html" target="_blank">https://www.saglik.gov.tr/EN,32386/the-school-milk-program-at-7th-year.html</a>
            </td>
          </tr>
          <tr>
            <td className="text-center bg-header2" colspan="2"><span className="text-white">Guidelines /Legislation</span></td>
          </tr>
          <tr className="bg-pale_blue">
            <td>
              <p>Turkey Dietary Guidelines</p>
              <p className="text-txt_black_1">Public Health Agency of Turkey Ministry of Health</p>
              <p className="text-txt_black_1">2016</p>
            </td>
            <td>
              <p>A series of recommendations composed to describe all conditions related to nutrition and to provide information on adequate and balanced nutrition to the population. The document includes practical ways to achieve nutritional goals and helps development of healthy lifestyles. The guidelines consider traditional eating habits and draw attention to the perspectives needed to change. They also pay attention to the ecological environment, socioeconomic and cultural factors, and the biological and physical environment in which the population lives.</p>
              <p>The document is updated from the 2004 version, within the framework of the “Healthy Nutrition and Active Life Programme”. A specific section on Nutrition for school-age (6-12 years) and adolescence (12-18) makes recommendations around school-based promotion of: dietary diversity; daily consumption of fruits, vegetables, protein source foods, dairy products (particularly for adolescent girls), water; limiting consumption of soft drinks; hygiene practices.</p>
              <p>Schools must support the development of children and help them gain the habit of healthy nutrition. Children from low-income households are of particular concern.</p>
              <p>Highlighted nutritional problems of concern are calcium and Vitamin D deficiency (proposed action of eating dairy products and sunbathing for 15-20 minute per day); anaemia (eat haem sources of food and consume non-haem sources with vitamin C, as well as seeking prompt diagnosis and treatment for anaemia); iodine deficiency (reduce salt intake but use iodised salt); obesity (balanced diet and physical activity). Eating disorders and tooth decay are also mentioned.</p>
              <p>Multiple references to adolescents and children, including recommendations of reference nutrient intakes for each group.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="text-txt_black_1">Ministry of Education, Ministry of Health (2016). KANTIN YONETMELİĞİ; Mesleki Ve Teknik Eğitim Genel Müdürlüğü, Okul Kantinlerinde Satilacak Gidalar Ve Eğitim Kurunlarindaki Gida Işletmelerinin Hijyen Yönünden Değerlendirilmes [Canteen management; The General Directorate of Vocational and Technical Education, the prices to be sold in the school canteens and the evaluation of the Gida establishments in the educational institutions from the hygiene point of view]. Ankara: Ministry of Education and Ministry of Health (in Turkish).</p>
              <p className="text-txt_black_1">Ministry of Education, Ministry of Health (2016). Turkish food and beverage standards in schools. Ankara: Ministry of</p>
              <p className="text-txt_black_1">Education and Ministry of Health.</p>
            </td>
            <td>
              <p>Regulations prohibiting marketing in schools</p>
              <p>Sets standards and regulations for schools. Banned chocolates, chips and confectionaries in schools; recommends healthy products/foods for schools.</p>
            </td>
          </tr>
          <tr className="bg-pale_blue">
            <td>
              <p>Nutrition Profile Guideline for Food Marketing to Children:</p>
              <p className="text-txt_black_1">Turkey Radio and Television Supreme Council (2018).Aşırı Tüketimi Tavsiye Edilmeyen Gıda Maddelerinin Ticari İletişimine İlişkin Yeni Düzenleme [New regulation regarding commercial communication of foods not recommended for overconsumption].</p>
              <p className="text-txt_black_1">Ankara: Turkey Radio and Television Supreme Council*</p>
            </td>
            <td>Regulations on advertising of foods to children in broadcast media (radio and TV) have been in place since 2011 (Turkey Radio and Television Supreme Council, 2011). Article 9, Paragraph 7, subparagraph (a) specifically prohibits the marketing of foods and beverages containing ingredients that are not recommended for excessive consumption in general diets, such as trans fats, salt or sodium and sugar, before, during and after children’s programming; and subparagraph (b), amended on 4 April 2016, further stipulates that, if commercial communications about such foods and beverages are included in programmes other than children’s programmes, easily readable health promotion messages encouraging physical activity and a healthy diet should be shown in a banner on the lower part of the screen</td>
          </tr>
        </tbody>
      </table>
      <div className="border border-txt_black_1 p-2 my-2 text-xs">
        <span>* </span><a href="https://www.rtuk.gov.tr/duyurular/3788/5536/asirituketimi-tavsiye-edilmeyen-gida-maddelerinin-ticari-iletisimine-iliskin-ye- ni-duzenleme.html" target="_blank">https://www.rtuk.gov.tr/duyurular/3788/5536/asirituketimi-tavsiye-edilmeyen-gida-maddelerinin-ticari-iletisimine-iliskin-ye- ni-duzenleme.html</a>
      </div>
    </>
  ) 
}
export default Table19_2
