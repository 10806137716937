import { useState } from 'react'
// Import Flag
import Flag from '../../images/montenegro.svg'
// Import Page Elements
import Table14_2 from '../../page_elements/tables/Table14_2'
import Table15_2 from '../../page_elements/tables/Table15_2'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TitleLink from '../../components/TitleLink'
import TableTitle from '../../components/TableTitle'
import Footnote from '../../components/Footnote'
// Component
const Montenegro = () => {

  const pageTitle = 'Montenegro'
  const table1Desc = 'Status of the nutrition of school-aged children and adolescents in Montenegro'
  const table2Desc = 'Policy and programming environment in Montenegro'

  const [showText, setShowText] = useState(true);
  const [showTable1, setShowTable1] = useState(false);
  const [showTable2, setShowTable2] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
    setShowTable2(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowTable2(false)
    setShowText(false)
  }
  function showTable2Handler(e) {
    e.preventDefault();
    setShowTable2(!showTable2)
    setShowTable1(false)
    setShowText(false)
  }

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="COUNTRY PROFILES" />
      <article>
        <PageTitle title = { pageTitle }  flag = { Flag } flagAlt = { 'Montenegro Flag' }>
          <TitleLink 
            linkTo = { '/country-profiles' }
            seeText = { 'See Map: '}
            icon = {'icon-map-icon'}
            description = { 'Country Profiles' }
          />
          {!showText &&
            <TitleButton 
              showHandler = { showTextHandler }
              seeText = { 'See Text: '}
              icon = {'icon-text-icon'}
              description = { pageTitle }
            />
          }
          {!showTable1 &&
            <TitleButton 
              showHandler = { showTable1Handler }
              seeText = { 'See Table 14: '}
              icon = {'icon-tables-icon'}
              description = { table1Desc }
            />
          }
          {!showTable2 &&
            <TitleButton 
              showHandler = { showTable2Handler }
              seeText = { 'See Table 15: '}
              icon = {'icon-tables-icon'}
              description = { table2Desc }
            />
          }
        </PageTitle>
        {/* TEXT */}
        { showText && 
          <div className="article_content col2">
            <p>There was a very high prevalence of overweight and obesity among those aged 7 years, affecting half boys and more than a third of girls; in those aged 10-24 years, a slightly lower prevalence was seen of 22.9 percent in girls and almost a third of boys. The prevalence of anaemia in the 10-24 year age group was 12.5 percent for girls and 15.1 percent for boys.</p>
            <p>The median urinary iodine concentration of school children aged 6-11 years in 2007 was 174 µg/L, which indicates an ‘adequate’ intake.</p>
            <p>A study of 7 to 13-year-olds estimated overweight to be 23 percent, of whom 5.3 percent were obese<Footnote sup={'34'} text={'Martinovic et al., 2015'}/>. Obesity was higher in boys (7.0 percent) than in girls (3.5 percent. Underweight was 3 percent among boys and 4 percent among girls. Obesity was positively associated with parental obesity, paternal employment, maternal smoking, obesity at birth, and computer game playing; being female, having more siblings, having a higher birth order and being older were protective against obesity risk. The study concludes that overweight prevalence has increased by 35 percent in the last 10 years. More time spent on computers by Montenegrin boys might explain some of the gender differences in obesity.</p>
            <p>The authors also suggest that social transitions that have occurred due to economic changes in the country may explain the rise in obesity and overweight among children in this setting. These include alterations in family life such as elevated maternal employment coupled with inadequate day-care provision, more affordable and readily available high-density food and greater use of computers. Considering these factors may help design successful, preventative interventions.</p>
            <h3 className="text-main_blue pb-3">Conclusion</h3>
            <p>The prevalence of overweight was high in school aged children and adolescents, particularly in boys where more than half of seven-year-olds and almost a third of those aged 10-24 were reportedly overweight. The Action Plan 2019-2020 sets out a reasonably comprehensive set of actions to address the nutritional challenges in this age group from different angles and through a variety of sectors and approaches. These include education and awareness-raising of teachers and pupils in schools; introducing guidelines for school meals/nutrition with regulations on the types of foods to be permitted in school environments; development of guidelines to restrict food advertising for children in relation to saturated fats, trans fats, sugars and salts; alongside the setting of targets to reduce obesity in children and development of a strategy to reach them; research on anaemia and committing to further rounds of COSI to keep abreast of the current situation. The Action Plan is complemented by public awareness-raising campaigns.</p>
          </div>
        }
        {/* TABLE */}
        { showTable1 && 
          <div className="article_content">
            <TableTitle
              title={'Table 14'}
              description={ table1Desc }
            />
            <Table14_2 />
          </div>
        }
        {/* TABLE */}
        { showTable2 && 
          <div className="article_content">
            <TableTitle
              title={'Table 15'}
              description={ table2Desc }
            />
            <Table15_2 />
          </div>
        }
      </article>
    </div>
  ) 
}
export default Montenegro
