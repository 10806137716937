import { useState } from 'react'
// Import Flag
import Flag from '../../images/turkey.svg'
// Import Page Elements
import Table18_2 from '../../page_elements/tables/Table18_2'
import Table19_2 from '../../page_elements/tables/Table19_2'
import Figure04_2 from '../../page_elements/figures/Figure04_2'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TableTitle from '../../components/TableTitle'
import TitleLink from '../../components/TitleLink'
import Footnote from '../../components/Footnote'
// Component
const Turkey = () => {

  const pageTitle = 'Turkey'
  const table1Desc = 'Status of the nutrition of school-aged children and adolescents in Turkey'
  const table2Desc = 'Status of Policy and programming environment in Turkey'
  const figureDesc = 'Dietary habits among school-aged children and adolescents, HBSC, Turkey 2006 and 2010'

  const [showText, setShowText] = useState(true);
  const [showTable1, setShowTable1] = useState(false);
  const [showTable2, setShowTable2] = useState(false);
  const [showFigure, setShowFigure] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
    setShowTable2(false)
    setShowFigure(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowTable2(false)
    setShowText(false)
    setShowFigure(false)
  }
  function showTable2Handler(e) {
    e.preventDefault();
    setShowTable2(!showTable2)
    setShowTable1(false)
    setShowText(false)
    setShowFigure(false)
  }
  function showFigureHandler(e) {
    e.preventDefault();
    setShowFigure(!showFigure)
    setShowText(false)
    setShowTable1(false)
    setShowTable2(false)
  }

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="COUNTRY PROFILES" />
      <article>
        <PageTitle title = { pageTitle }  flag = { Flag } flagAlt = { 'Turkey Flag' }>
          <TitleLink 
            linkTo = { '/country-profiles' }
            seeText = { 'See Map: '}
            icon = {'icon-map-icon'}
            description = { 'Country Profiles' }
          />
          {!showText &&
            <TitleButton 
              showHandler = { showTextHandler }
              seeText = { 'See Text: '}
              icon = {'icon-text-icon'}
              description = { pageTitle }
            />
          }
          {!showFigure &&
            <TitleButton 
              showHandler = { showFigureHandler }
              seeText = { 'See Figure 4: '}
              icon = {'icon-chart-icon'}
              description = { figureDesc }
            />
          }
          {!showTable1 &&
            <TitleButton 
              showHandler = { showTable1Handler }
              seeText = { 'See Table 18: '}
              icon = {'icon-tables-icon'}
              description = { table1Desc }
            />
          }
          {!showTable2 &&
            <TitleButton 
              showHandler = { showTable2Handler }
              seeText = { 'See Table 19: '}
              icon = {'icon-tables-icon'}
              description = { table2Desc }
            />
          }
        </PageTitle>
        {/* TEXT */}
        { showText && 
          <div className="article_content col2">
            <p>In Turkey, the GSHS of 2006 found a low prevalence of thin girls aged 12-15, but a higher prevalence (8.6 percent) of thin boys. The prevalence of stunted boys (17.9 percent) was significantly higher than stunted girls (8.5 percent), although of note is that these results are from 2006. Overweight and obesity rates were also reported to be almost double in boys than in girls, affecting one in five boys.</p>
            <p>The 2013 COSI-3 reported a high prevalence of overweight and obesity in both girls and boys, with a quarter of boys aged 7 and 8 being overweight; this increased dramatically in COSI-4 to 40% of boys and a third of girls.</p>
            <p>IHME surveys using IOTF thresholds reported a similar prevalence of overweight: 25.5 percent in girls and 28.8 percent in boys aged 10-24 years; and a higher prevalence of anaemia in girls than boys, affecting one in five girls.</p>
            <p>The median urinary iodine concentration of school children aged 6-14 years in 2007 was 107 µg/L, which indicates an ‘adequate’ intake, however there has been no follow up survey for more than 10 years.</p>
            <p>In terms of dietary habits, two rounds of HBSC in 2006 and 2010 provided information for girls and boys aged 11, 13 and 15, as shown in the figure below:</p>
            <p>There has been little change in fruit and vegetable consumption between the two rounds, but daily sweet consumption appears to have decreased, especially for girls.</p>
            <p>Additionally, a few studies examining the prevalence of underweight and stunting in this age group<Footnote sup={'41'} text={'Ozguven et al., 2010; Gur et al., 2006'}/> found significant relationships between underweight and age, sex, number of family members, and monthly family income, and concluded that undernutrition was still a common problem among primary school children in Istanbul. Another study revealed that appetite, house size, elevator use and paternal education significantly predicted underweight<Footnote sup={'42'} text={'Cicek et al., 2009'}/>.</p>
            <p>A number of studies have explored risk factors associated with overweight and obesity. Computer use, TV viewing (more than 3 hours per day), physical activity, being female, being in the younger category of adolescence, living in an urban area, sleep duration, skipping breakfast and family factors have all been reported as important risk factors for obesity in Turkish adolescents<Footnote sup={'43'} text={'Ercan et al., 2012; Etiler et al., 2011; Cicek et al., 2009; Dundar et al., 2012'}/>. Overweight has been found to be more prevalent in private schools (40 percent) compared to public schools (25 percent)<Footnote sup={'44'} text={'Cinar et al., 2011'}/>; another study found greater overweight and obesity prevalence in Turkish children from higher SES than in lower SES groups<Footnote sup={'45'} text={'Discigil et al., 2009'}/>.</p>
            <p>With regard to dietary patterns, studies have found that 24 percent of Turkish adolescents aged 10 to 12 years consumed fizzy drinks and 26 percent fast food, more than three times per week; 55 percent snacked on fruit daily between meals<Footnote sup={'46'} text={'Cinar et al., 2008'}/>. Several studies reported on daily breakfast consumption, with rates estimated to be between 54 and 78 percent<Footnote sup={'47'} text={'Ayranci et al., 2010; Erenoglu et al., 2006'}/>. Skipping breakfast is associated with higher risk of obesity.</p>
            <p>A study of educational activities provided to students and their families reported a significant reduction in the prevalence of overweight but no reduction in average BMI scores<Footnote sup={'48'} text={'Akdemir et al., 2017'}/>. A 12-month health education programme (T-COPE) saw improvements in nutritional behaviour, physical activity and stress management, but again, no impact on BMI<Footnote sup={'49'} text={'Ardic et al., 2017'}/>. In studies that only targeted obese children and adolescents, there appeared to be greater success at reducing BMI; for example, an exercise programme accompanied by an activity video had a positive effect on the obesity status in obese adolescents, as well as on the psychosocial status and self-esteem of participants<Footnote sup={'50'} text={'Duman et al., 2016'}/>.</p>
            <p>Multiple studies have explored the micronutrient status of school-age children and adolescents in Turkey. A cross-sectional survey of 12 to 17-year olds, which assessed dietary intake using 3-day self-reported food records, found that calcium, iron, magnesium, folic acid, vitamins A, E, thiamine, and fibre intakes were lower than recommended<Footnote sup={'51'} text={'Garipagaoglu et al., 2008'}/>. Protein, riboflavin, vitamin C, phosphorus and zinc were found to be adequate in the majority of subjects. Vitamin D deficiency seemed to be a problem for adolescents in the winter, and iron-deficiency anaemia affected adolescent girls<Footnote sup={'52'} text={'Erol et al., 2015; Kilinc et al., 2002'}/>. Eighty percent of children aged 3-17 years were found to have vitamin D deficiency at the end of winter, and girls seemed to have greater vitamin D deficiency than boys during the summer due to lower sunlight exposure<Footnote sup={'53'} text={'Erol et al., 2015'}/>. Children with sufficient dairy product consumption had significantly higher vitamin D levels in both “end of summer” and “end of winter” samples. Vitamin D deficiency has important functional consequences, and limb pain was frequently reported in children with low vitamin D levels in the winter samples. Another study found that girls who wear covered dress had significantly lower serum 25(OH)D concentrations than other girls<Footnote sup={'54'} text={'Hatun et al., 2005'}/>.</p>
            <p>Vitamin A deficiency<Footnote sup={'55'} text={'Measured as serum retinol level (SRL) <0.7 mmol/L'}/> was not detected in any children in a 2009 survey of 5-16-year olds. However, serum retinol levels were suboptimal in 2 percent of children, which put them at high risk of developing vitamin A deficiency<Footnote sup={'56'} text={'Vuralli et al., 2014'}/>. The prevalence of subclinical zinc deficiency in children 5-16 years in a 2009 survey in Ankara was found to be 28 percent<Footnote sup={'57'} text={'Ibid.'}/>.</p>
            <p>A study of children aged 6 to 14 years in urban areas in the province of Manisa found 8 percent goitre prevalence by palpitation. Children detected to have palpable goitre were thinner and from poorer backgrounds. Another study in the province of Duzce of the West Black Sea Region of Turkey on 6-19-year olds found goitre prevalence by palpitation to be 23 percent<Footnote sup={'58'} text={'Uzun et al., 2014'}/>. Goitre is a sign of historic, rather than current iodine deficiency, but these results indicate that iodine deficiency problems may still exist in some areas of Turkey, particularly in the more rural areas.</p>
            <p>A 2018 WHO report  showed that food was the most heavily advertised product category on television in Turkey, accounting for 32.1 percent of total advertisements, and most food advertisements on television and online were for energy-dense, high in fat, sugar and salt (HFSS) foods, or restaurant advertisements that did not comply with the WHO nutrient profile model (NPM).</p>
            <p className="text-main_blue">Conclusion</p>
            <p>Surveys from Turkey showed concerning rates of underweight in 2006, while anaemia, combined with high overweight are more evident in more recent reports, illustrating potentially a triple burden of malnutrition. Boys were disproportionately affected, with a higher prevalence of stunting and overweight. In addition to anaemia, several vitamin and mineral deficiencies were notable, particularly Vitamin D in the winter months and pockets of iodine deficiency and iodine nutritional status needs up-to-date data that can also capture geographical inequalities. Studies on eating habits and dietary patterns in Turkish children and adolescents point to excessive consumption of fizzy drinks and fast food, as well as skipping breakfast, as being some of the issues implicated in malnutrition of this age group. Turkey has implemented programmes, guidelines and regulations to try to address these issues. These include targets for the reduction of overweight and obesity in children and adolescents and a strong focus on the school environment: training of staff, awareness-raising and education of students towards healthy diets and lifestyles, as well as regulation of school meals and the provision of foods in schools. Alongside this, regulations are in place to restrict the marketing of unhealthy foods to children through the media. The policy framework represents a strong base from which to build and develop a holistic package of interventions to address the nutritional challenges in this age group.</p>
          </div>
        }
        {/* TABLE */}
        { showTable1 && 
          <div className="article_content">
            <TableTitle
              title={'Table 18'}
              description={ table1Desc }
            />
            <Table18_2 />
          </div>
        }
        {/* TABLE */}
        { showTable2 && 
          <div className="article_content">
            <TableTitle
              title={'Table 19'}
              description={ table2Desc }
            />
            <Table19_2 />
          </div>
        }
        {/* FIGURE */}
        { showFigure && 
          <div className="article_content">
            <TableTitle
              title={'Figure 4'}
              description={ figureDesc }
            />
            <div className="
              flex flex-nowrap justify-end
              border-b-2 border-main_blue
              pt-2 text-sm pb-1">
                <div className="text-main_blue">Legend:</div>
                <div className="w-3 h-3 bg-girls ml-2 mr-1 mt-1 
                "></div>
                <div>2006</div>
                <div className="w-3 h-3 bg-boys ml-3 mr-1 mt-1 
                "></div>
                <div>2010</div>
            </div>
            <Figure04_2 />
          </div>
        }
      </article>
    </div>
  ) 
}
export default Turkey
