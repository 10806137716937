import React, { useState } from 'react';
// Import Table Components
import LabelBox from '../../table_comp/LabelBox'
import Bar from '../../table_comp/Bar'

// Component
const Figure04_2 = () => {

  const topValue = 50;

  const [showGirls, setShowGirls] = useState(true);
  const handleClickGirls = () => setShowGirls(!showGirls)

  const [showBoys, setShowBoys] = useState(true);
  const handleClickBoys = () => setShowBoys(!showBoys)

  const [showFruits, setShowFruits] = useState(true);
  const handleClickFruits = () => setShowFruits(!showFruits)

  const [showVegetables, setShowVegetables] = useState(true);
  const handleClickVegetables = () => setShowVegetables(!showVegetables)

  const [showSweets, setShowSweets] = useState(true);
  const handleClickSweets = () => setShowSweets(!showSweets)

  const [showDrinks, setShowDrinks] = useState(true);
  const handleClickDrinks = () => setShowDrinks(!showDrinks)

  const [showExercises, setShowExercises] = useState(true);
  const handleClickExercises = () => setShowExercises(!showExercises)

  const labelFruits = 'Daily fruit\nconsumption (%)'
  const labelVegetables = 'Daily vegetable\nconsumption (%)'
  const labelSweets = 'Daily sweets\nconsumption (%)'
  const labelDrinks = 'Daily soft drink\nconsumption (%)'
  const labelExercises = 'Moderate\nexercise (%)*'

  
  return (
    <>
      <div className="barChartBox">
        <div className="barChart">
          {/* FRUITS */}
          {showFruits &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox 
                label={ labelFruits }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
              {/* GIRLS */}
              {showGirls &&
                <div className="pb-1">
                  <Bar 
                    barColor = {'#00AEEF'}
                    barWidth = { (43.8 * 100) / topValue }
                    barHeight = { '12px' }
                    dataTipId = { 'g06_01' }
                    tipLabel = { labelFruits }
                    tipSubLabel = { 'Girls 2006' }
                    tipValue = { 43.8 }
                  />
                  <Bar 
                    barColor = {'#3b4395'}
                    barWidth = { (40.9 * 100) / topValue }
                    barHeight = { '12px' }
                    dataTipId = { 'g10_01' }
                    tipLabel = { labelFruits }
                    tipSubLabel = { 'Girls 2010' }
                    tipValue = { 40.9 }
                  />
                </div>
              }
              {showBoys &&
                <div>
                  <Bar 
                    barColor = {'#00AEEF'}
                    barWidth = { (29.8 * 100) / topValue }
                    barHeight = { '12px' }
                    dataTipId = { 'b06_01' }
                    tipLabel = { labelFruits }
                    tipSubLabel = { 'Boys 2006' }
                    tipValue = { 29.8 }
                  />
                  <Bar 
                    barColor = {'#3b4395'}
                    barWidth = { (31.5 * 100) / topValue }
                    barHeight = { '12px' }
                    dataTipId = { 'b10_01' }
                    tipLabel = { labelFruits }
                    tipSubLabel = { 'Boys 2010' }
                    tipValue = { 31.5 }
                  />
                </div>
              }
            </div>
            </div>
          }
          {/* VEGETABLES */}
          {showVegetables &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox
                label={ labelVegetables }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
                {/* GIRLS */}
                {showGirls &&
                  <div className="pb-1">
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (43.8 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g06_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Girls 2006' }
                      tipValue = { 43.8 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (40.9 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g10_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Girls 2010' }
                      tipValue = { 40.9 }
                    />
                  </div>
                }
                {showBoys &&
                  <div>
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (23.1 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b06_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Boys 2006' }
                      tipValue = { 23.1 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (24.6 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b10_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Boys 2010' }
                      tipValue = { 24.6 }
                    />
                  </div>
                }
              </div>
            </div>
          }
          {/* SWEETS */}
          {showSweets &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox
                label={ labelSweets }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
                {/* GIRLS */}
                {showGirls &&
                  <div className="pb-1">
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (32.1 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g06_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Girls 2006' }
                      tipValue = { 32.1 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (24.4 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g10_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Girls 2010' }
                      tipValue = { 24.4 }
                    />
                  </div>
                }
                {showBoys &&
                  <div>
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (23.1 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b06_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Boys 2006' }
                      tipValue = { 23.1 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (19.4 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b10_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Boys 2010' }
                      tipValue = { 19.4 }
                    />
                  </div>
                }
              </div>
            </div>
          }
          {/* DRINKS */}
          {showDrinks &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox
                label={ labelDrinks }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
                {/* GIRLS */}
                {showGirls &&
                  <div className="pb-1">
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (18.1 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g06_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Girls 2006' }
                      tipValue = { 18.1 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (16.7 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g10_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Girls 2010' }
                      tipValue = { 16.7 }
                    />
                  </div>
                }
                {showBoys &&
                  <div>
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (21.6 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b06_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Boys 2006' }
                      tipValue = { 21.6 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (20.7 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b10_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Boys 2010' }
                      tipValue = { 20.7 }
                    />
                  </div>
                }
              </div>
            </div>
          }
          {/* EXERCISES */}
          {showExercises &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox
                label={ labelExercises }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
                {/* GIRLS */}
                {showGirls &&
                  <div className="pb-1">
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (16.8 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g06_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Girls 2006' }
                      tipValue = { 16.8 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (13.2 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g10_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Girls 2010' }
                      tipValue = { 13.2 }
                    />
                  </div>
                }
                {showBoys &&
                  <div>
                    <Bar 
                      barColor = {'#00AEEF'}
                      barWidth = { (22.3 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b06_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Boys 2006' }
                      tipValue = { 22.3 }
                    />
                    <Bar 
                      barColor = {'#3b4395'}
                      barWidth = { (22.3 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b10_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Boys 2010' }
                      tipValue = { 22.3 }
                    />
                  </div>
                }
              </div>
            </div>
          }
        </div>
        {/* BACK LINES */}
        <div className="lines" aria-hidden="true">
          <div className="labelBox solidLine"><span>0</span></div>
          <div className="dotLines">
            <div className="sm:hidden"><span>5</span></div>
            <div><span>10</span></div>
            <div className="sm:hidden"><span>15</span></div>
            <div><span>20</span></div>
            <div className="sm:hidden"><span>25</span></div>
            <div><span>30</span></div>
            <div className="sm:hidden"><span>35</span></div>
            <div><span>40</span></div>
            <div className="sm:hidden"><span>45</span></div>
            <div><span>50</span></div>
          </div>
        </div>
      </div>
      <div className="infoBox">
        <span>*</span>Moderate exercise is defined as 60 minutes of moderate exercise or more daily
      </div>
      
      {/* SORT CHECKBOXES */}
      <div className="flex text-main_blue text-sm">Select to sort</div>
      <div className="sortBox">
        {/* LEFT BOX */}
        <div className="left_box sortBtnBox">
          {/* CHECKBOX FRUITS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelFruits }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickFruits} defaultChecked={showFruits} type="checkbox" id="fruits"/>
                <label htmlFor="fruits"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX VEGETABLES */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelVegetables }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickVegetables} defaultChecked={showFruits} type="checkbox" id="vegetables"/>
                <label htmlFor="vegetables"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX SWEETS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelSweets }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickSweets} defaultChecked={showFruits} type="checkbox" id="sweets"/>
                <label htmlFor="sweets"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX DRINKS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelDrinks }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickDrinks} defaultChecked={showFruits} type="checkbox" id="drinks"/>
                <label htmlFor="drinks"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX EXERCISES */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelExercises }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickExercises} defaultChecked={showFruits} type="checkbox" id="exercises"/>
                <label htmlFor="exercises"></label>
              </div>
            </div>
          </div>
        </div>
        {/* RIGHT BOX */}
        <div className="right_box">
          {/* CHECKBOX GIRLS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Girls</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickGirls} defaultChecked={showGirls} type="checkbox" id="girls"/>
                <label htmlFor="girls"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX BOYS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Boys</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickBoys} defaultChecked={showBoys} type="checkbox" id="boys"/>
                <label htmlFor="boys"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Figure04_2 
