import { useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
// Import Pages
import Lifecycle from '../../pages/introduction/Lifecycle'
import Eca from '../../pages/introduction/Eca'
import Scope from '../../pages/introduction/Scope'

// Component
const Introduction = () => {

  const lifecycle = useRef();
  const eca = useRef();
  const scope = useRef();

  const location = useLocation();

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   

  useEffect(() => {
    if(location.hash === '#adolescent-school-age-nutrition-in-eca') {
      scrollToRef(eca);
    }
    if(location.hash === '#scope-of-the-review') {
      scrollToRef(scope);
    }
  });

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="INTRODUCTION" />
      <article>
        <section id='lifecycle' ref={lifecycle} > 
          <Lifecycle />
        </section>
        <section id='eca' ref={eca} > 
          <Eca />
        </section>
        <section id='scope' ref={scope} > 
          <Scope />
        </section>
      </article>
    </div>
  ) 
}

export default Introduction
