import { useState } from 'react'
// Import Flag
import Flag from '../../images/republic-of-macedonia.svg'
// Import Page Elements
import Table12_2 from '../../page_elements/tables/Table12_2'
import Table13_2 from '../../page_elements/tables/Table13_2'
import Figure03_2 from '../../page_elements/figures/Figure03_2'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TitleLink from '../../components/TitleLink'
import TableTitle from '../../components/TableTitle'
import Footnote from '../../components/Footnote'
// Component
const Macedonia = () => {

  const pageTitle = 'Republic of North Macedonia'
  const table1Desc = 'Status of the nutrition of school-aged children and adolescents in Republic of North Macedonia'
  const table2Desc = 'Policy and programming environment in Republic of North Macedonia'
  const figureDesc = 'Eating practice among adolescents in Republic of North Macedonia during 2002-2014'

  const [showText, setShowText] = useState(true);
  const [showTable1, setShowTable1] = useState(false);
  const [showTable2, setShowTable2] = useState(false);
  const [showFigure, setShowFigure] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
    setShowTable2(false)
    setShowFigure(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowTable2(false)
    setShowText(false)
    setShowFigure(false)
  }
  function showTable2Handler(e) {
    e.preventDefault();
    setShowTable2(!showTable2)
    setShowTable1(false)
    setShowText(false)
    setShowFigure(false)
  }
  function showFigureHandler(e) {
    e.preventDefault();
    setShowFigure(!showFigure)
    setShowText(false)
    setShowTable1(false)
    setShowTable2(false)
  }

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="COUNTRY PROFILES" />
      <article>
        <PageTitle title = { pageTitle }  flag = { Flag } flagAlt = { 'Republic of North Macedonia Flag' }>
          <TitleLink 
            linkTo = { '/country-profiles' }
            seeText = { 'See Map: '}
            icon = {'icon-map-icon'}
            description = { 'Country Profiles' }
          />
          {!showText &&
            <TitleButton 
              showHandler = { showTextHandler }
              seeText = { 'See Text: '}
              icon = {'icon-text-icon'}
              description = { pageTitle }
            />
          }
          {!showTable1 &&
            <TitleButton 
              showHandler = { showTable1Handler }
              seeText = { 'See Table 12: '}
              icon = {'icon-tables-icon'}
              description = { table1Desc }
            />
          }
          {!showTable2 &&
            <TitleButton 
              showHandler = { showTable2Handler }
              seeText = { 'See Table 13: '}
              icon = {'icon-tables-icon'}
              description = { table2Desc }
            />
          }
          {!showFigure &&
            <TitleButton 
              showHandler = { showFigureHandler }
              seeText = { 'See Figure 3: '}
              icon = {'icon-chart-icon'}
              description = { figureDesc }
            />
          }
        </PageTitle>
        {/* TEXT */}
        { showText && 
          <div className="article_content col2">
            <p>In North Macedonia, the 2007 Global School Health Survey (GSHS) reported a low prevalence of thinness amongst girls and boys aged 12-15 and also a low prevalence of stunting. Overweight and obesity rates on the other hand were of concern and much higher for boys than girls.</p>
            <p>COSI surveys reported higher rates of overweight in younger children: half of the 7-year old boys were reported to be overweight or obese in 2009 and 39.5 percent of girls aged seven. In 2013 the prevalence of overweight 6-year old children was lower at 26 percent for girls and 28 percent for boys, but very high prevalence was reported again in 2015-2017 of 42 percent in girls and 47 percent in boys aged seven.</p>
            <p>The prevalence of anaemia in those aged 10-24 years was 15.5 percent for girls and 19.2 percent for boys.</p>
            <p>The median urinary iodine concentration of school children in 2007 was 241 µg/L, which indicates an ‘adequate’ intake.</p>
            <p>In terms of dietary habits, the four rounds of HBSC in 2002, 2006, 2010 and 2014 provide some trend information for girls and boys aged 11, 13 and 15, depicted in the figure below.</p>
            <p>Encouraging decreasing trends for both sexes were seen in the daily consumption of sweets and soft drinks since 2006, alongside a gradual increase in exercise from 2002 onwards.</p>
            <p>Published papers on child and adolescent nutrition in North Macedonia suggest that risk of overweight is greater in boys than in girls in this setting, and that prevalence of overweight has been increasing over the past decade<Footnote sup={'27'} text={'Ahluwalia et al., 2015; Due et al., 2009'}/>.</p>
            <p>As well as boys being taller and heavier than girls, a study also found that they had higher systolic blood pressure<Footnote sup={'28'} text={'Gontarev et al., 2017'}/>. The same study found that 17 percent of young adolescents (11-14 years) were hypertensive.</p>
            <p>Prevalence of underweight, overweight and obesity did not appear to be significantly different between socioeconomic status (SES) groups in general. However, when focusing on adolescent girls only, the lowest SES group had higher prevalence of obesity than middle or high groups. The lowest SES group also had higher prevalence of underweight<Footnote sup={'29'} text={'Bojadzieva et al., 2014'}/>. A study that compared Roma children with non-Roma children, found greater risk of underweight in the Roma children, but no greater risk of overweight<Footnote sup={'30'} text={'Spiroski et al., 2011'}/>.</p>
            <p>Several studies present data on dietary patterns and lifestyle factors of children and adolescents, largely drawing on HBSC 2010 survey data. Fifty-seven percent of schoolchildren reported consuming breakfast daily; a proportion which has been increasing since the surveys started in 2002<Footnote sup={'31'} text={'Lazzeri et al., 2016'}/>. One study found that those most likely to watch TV were boys, 13-year-olds and pupils of lower SES, and those who watched more TV were more likely to consume sweets and soft drinks on a daily basis and less likely to consume fruit and vegetables daily<Footnote sup={'32'} text={'Vereecken et al., 2006'}/>. Another study of 13 and 15-year olds found electronic media use to be associated with increased BMI z-scores and risk of overweight among both boys and girls who were physically inactive<Footnote sup={'33'} text={'Melkevik et al., 2015'}/>. With increasing trends in overweight in adolescents in North Macedonia, it is important to understand potential causes and research successful preventative interventions, especially among low SES groups. Results of studies so far have found different associations among boys and girls, suggesting that gender-specific interventions might be helpful.</p>
            <h3 className="text-main_blue pb-3">Conclusion</h3>
            <p>Overweight is the main concern among children and adolescents in North Macedonia, particularly among school aged children and adolescent boys. Anaemia is of concern among both sexes. Dietary habits and choices appear to be improving slightly among children and adolescents in recent years, moving towards greater consumption of fruits and vegetables, however, this is still far below the optimal level. Lack of physical activity appears to be a contributing factor to rates of overweight, while studies suggest gendered approaches to address healthy lifestyle and nutrition issues may be a promising area for operational research. The Health Strategy and Youth Strategy offer tentative entry points for developing nutrition strategies and programmes, though they appear weighted towards education and awareness-raising. The only regulatory documents available are those pertaining to school meals provision. There is a gap with regards to strategies and policies regulating the production and marketing of unhealthy foods and diets for children and engaging the wider community in healthy eating and promotion of improved nutrition. The National Food and Nutrition Action Plan would be a useful initiative to take forward to address the nutrition challenges holistically.</p>
          </div>
        }
        {/* TABLE */}
        { showTable1 && 
          <div className="article_content">
            <TableTitle
              title={'Table 12'}
              description={ table1Desc }
            />
            <Table12_2 />
          </div>
        }
        {/* TABLE */}
        { showTable2 && 
          <div className="article_content">
            <TableTitle
              title={'Table 13'}
              description={ table2Desc }
            />
            <Table13_2 />
          </div>
        }
        {/* FIGURE */}
        { showFigure && 
          <div className="article_content">
            <TableTitle
              title={'Figure 3'}
              description={ figureDesc }
            />
            <div className="
              flex flex-nowrap justify-end
              border-b-2 border-main_blue
              pt-2 text-sm pb-1">
                <div className="text-main_blue">Legend:</div>
                <div className="w-3 h-3 bg-pale3_blue ml-2 mr-1 mt-1 
                "></div>
                <div>2002</div>
                <div className="w-3 h-3 bg-girls ml-3 mr-1 mt-1 
                "></div>
                <div>2006</div>
                <div className="w-3 h-3 bg-sec_blue ml-2 mr-1 mt-1 
                "></div>
                <div>2010</div>
                <div className="w-3 h-3 bg-boys ml-2 mr-1 mt-1 
                "></div>
                <div>2014</div>
            </div>
            <Figure03_2 />
          </div>
        }
      </article>
    </div>
  ) 
}
export default Macedonia
