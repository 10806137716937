const Table18_2 = () => {
  return (
    <>  
      <div className="table-scroll relative">
        <table className="tableRightBorder table-auto w-full text-left xs:text-xs">
          <thead>
            <tr className="text-white bg-main_blue">
              <th>
                <div className="bg-main_blue min-w-80">Survey</div>
              </th>
              <th>
                <div className="min-w-80">Age group</div>
              </th>
              <th>
                <div className="min-w-80">Boys/Girls (non-pregnant)</div>
              </th>
              <th>
                <div className="min-w-80">{`Thin % (BMI for age <-2SD)`}</div>
              </th>
              <th>
                <div className="min-w-80">{`HAZ% <- 2SD`}</div>
              </th>
              <th>
                <div className="min-w-80">Overweight or obese % (95% C.I.)</div>
              </th>
              <th>
                <div className="min-w-80">Anaemia % (95% C.I.)</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-main_blue bg-white">GSHS 2006</td>
              <td rowSpan="2">12-15 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">2.3 (0.5, 9.7)</td>
              <td className="text-right">8.5 (6.9, 10.4)</td>
              <td className="text-right">11.1 (9.4, 13.1)</td>
              <td className="text-right">-</td>
            </tr>
            <tr>
              <td className="bg-white"></td>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">8.6 (7.0, 10.4)</td>
              <td className="text-right">17.9 (15.6, 20.5)</td>
              <td className="text-right">21.2 (18.6, 26.0)</td>
              <td className="text-right">-</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="text-main_blue bg-pale_blue">COSI-3 2013</td>
              <td rowSpan="2">7 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">23</td>
              <td className="text-right">-</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="bg-pale_blue"></td>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">25</td>
              <td className="text-right">-</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="bg-pale_blue"></td>
              <td rowSpan="2">8 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">22</td>
              <td className="text-right">-</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="bg-pale_blue"></td>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">25</td>
              <td className="text-right">-</td>
            </tr>
            <tr>
              <td className="text-main_blue bg-white">COSI-4 2015-2017</td>
              <td rowSpan="2">7 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">34</td>
              <td className="text-right">-</td>
            </tr>
            <tr>
              <td className="bg-white"></td>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">40</td>
              <td className="text-right">-</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="bg-pale_blue text-main_blue">IHME</td>
              <td>10-24 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">25.5</td>
              <td className="text-right">20.1</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="bg-pale_blue">
                <p>2016</p>
                <p>(modelled data from 1990-2016)</p>
                <p>(IOTF) (Azzopardi 2018)</p>
              </td>
              <td>10-24 y</td>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">28.0</td>
              <td className="text-right">13.9</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  ) 
}
export default Table18_2
