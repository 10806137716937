import PropTypes from 'prop-types'
import { useState } from 'react'

const Footnote = ({ sup, text, linkTxt, linkUrl }) => {
  
  const [show, setShow] = useState(false);
  const closeFootnoteHandler = () => setShow(false);

  return (
    <>
      <button 
        onClick={() => setShow(true)}
        className="
          footnote text-maim_blue hover:text-sec_blue outline-none focus:outline-none hover:outline-none ani03s
        "><sup>{ sup }</sup>
      </button>
      { show && 
        <span
          onClick={closeFootnoteHandler}
          style={{ opacity: show ? 1 : 0 }}
          className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col ani03s z-20">
          <span className="fixed bottom-5 left-16 md:left-12 xs:left-8 w-96 xs:w-80 px-8 py-6 bg-white max-w-sm text-xs">
            <span
              onClick={closeFootnoteHandler}
              className="icon-close absolute top-1 right-1 cursor-pointer">
            </span>
            <sup>{ sup }</sup> { text }
            {linkUrl &&
              <a className="pl-2 contentLink" herf={ linkUrl } target="_blank">{ linkTxt }</a>
            }
          </span>
        </span>
      }
    </>
  )
}

Footnote.propTypes = {
  sup: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Footnote
