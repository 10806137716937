import { useState } from 'react'
// Import Page Elements
import Figure05_2 from '../../page_elements/figures/Figure05_2'
// Import Components
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
// Component
const Conclusions2 = () => {

  const pageTitle = 'Vol 2. Conclusions'
  const figureDesc = 'The Innocenti Framework on Food Systems for Children and Adolescents'

  const [showFigure, setShowFigure] = useState(false);
  const [showText, setShowText] = useState(true);
  function showFigureHandler(e) {
    e.preventDefault();
    setShowFigure(!showFigure)
    setShowText(false)
  }
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowFigure(false)
  }

  return (
    <>
      <PageTitle title = { pageTitle } >
        {!showText &&
          <TitleButton 
            showHandler = { showTextHandler }
            seeText={ 'See Text: '}
            icon={'icon-text-icon'}
            description={ pageTitle }
          />
        }
        {!showFigure &&
          <TitleButton 
            showHandler = { showFigureHandler }
            seeText={ 'See Figure 5: '}
            icon={'icon-chart-icon'}
            description={ figureDesc }
          />
        }     
      </PageTitle>
      { showFigure && 
        <div className="article_content">
          <Figure05_2 />
        </div>
      }
      { showText && 
        <div className="article_content col2">
          <p>The policy and programme review reveals that, while several of the nine countries are making progress in their steps to address the nutrition challenges of this age group through national policy and programmes, there are gaps in the policy framework for prevention of obesity and Iron Deficiency Anaemia in all countries and some have focused very little attention on this area to date.</p>
          <p>The majority of which did not contain a specific mention of adolescent nutrition or targets on overweight / obesity for this age group. National nutrition programmes tend to have a strong focus on infants and children under-five and women of reproductive age.</p>
          <p>Strategies focusing on child and adolescent health or on youth generally did not include a detailed nutrition component for this age group and tend to include generalised content on healthy lifestyles.</p>
          <p>Inclusion of strategies on prevention or reduction of micronutrient deficiencies in this age group is completely lacking.</p>
          <p>All countries have guidance, and many have legislation on school meals / nutrition promotion in schools, with varying content and this is clearly a focus area for countries, well-supported by WHO and UNICEF approaches in Nutrition Friendly Schools programmes. However, the scale of implementation and level of regulation or enforcement of regulations around school meals is questioned in several studies, as well as the competing issues of marketing ‘unhealthy’ foods within and around school premises.</p>
          <p>Only four countries have any policy or regulation on marketing to children in place (Armenia, Bosnia and Herzegovina, Kyrgyz Republic and Turkey).</p>
          <p>Nutrition education and healthy lifestyles promotion is largely limited to schools for this age group, with little focus on a family / household approach. This is despite a growing body of evidence that suggests that many nutrition habits are rooted in the home environment and that food systems exert an influence through many different routes, as depicted in below Figure 5.</p>
          <p>The European Food and Nutrition Action Plan 2015-2020 set out five key areas for action, which link well with the Innocenti Framework. These provide a useful framework within which support to child and adolescent nutrition can be situated:</p>
          <ol className="list-decimal pl-4 pb-6">
            <li className="pl-2 pb-1">Strengthen multi-system approach to promote healthy diets.</li>
            <ol className="alphaList pl-4">
              <li className="pl-2 pb-1">
                <p>Food systems for healthy food and drink environments.</p>
                <p>Few of the nine countries have legislation in place with regard to marketing of foods to children, and most literature relating to healthy food and drink environments relates to the school environment only, not to the home /health / social environment.</p>
                <p>Insufficient health and nutrition literacy and competing media pressure has been cited as a constraint to families and individuals making informed choices about their diet; there is more that can be done to promote and incentivise healthy food choices and better inform parents, adolescents and children on nutrition.</p>
              </li>
              <li className="pl-2 pb-1">
                <p>Reinforce health systems to promote healthy diets.</p>
                <p>Adolescent nutrition does not feature prominently (and sometimes not at all) in national health strategies or systems.</p>
              </li>
            </ol>
            <li>
              <p>Promote the gains of a healthy diet throughout life, especially for the most vulnerable groups.</p>
              <p>Most countries have general guidelines on healthy diet and healthy lifestyles, though not all include specific elements for children/adolescents, or the focus is restricted to children under 5 and/or women of reproductive age</p>
            </li>
            <li>
              <p>Support surveillance, monitoring, evaluation and research.</p>
              <p>Consensus is urgently required, accompanied by robust surveys with comparable data so that systematic data collection and analysis can be improved and coordinated at both national and regional level. Very little data exists on micronutrient deficiencies in this age group, beyond anaemia and iodine and there is a lack of over-arching research strategies in the region / by country in order to understand more about nutritional deficiencies in this age group and tailor policy, strategy and programmes to address issues.</p>
            </li>
            <li>
              <p>Strengthen governance, alliances and networks to ensure a health-in-all-policies approach.</p>
              <p>Although increasingly school meal guidance and regulations are being published at a national level, there remains concern around their implementation and adoption and hence their coverage. Greater emphasis on monitoring and enforcement of regulations needs to accompany legislation.</p>
              <p>Countries to develop methods for monitoring compliance with and action on marketing restrictions and formally adopt a Nutrient Profile Model (NPM), which would make more explicit the list of foods for which marketing would be restricted.</p>
              <p>Collaboration with the food industry by health, education and other government departments is an essential component of protecting children and adolescents from inappropriate marketing which is harmful to their health.</p>
              <p>Each Member State develop, implement and/or evaluate its own national action plan on childhood overweight and obesity. Within these and beyond, regional, joined-up approaches will be required to successfully tackle marketing, advertising and promotion of foods to children and ensure children and adolescents access healthy diets.</p>
            </li>
          </ol>
          
        </div> 
      }
    </>
  ) 
}
export default Conclusions2
