import React, { useState } from 'react';
import { useTable, useSortBy } from 'react-table'
import BaseRow from '../../components/BaseRow'
// Component
const Table04 = () => {

  const [showAlbania, setShowAlbania] = useState(true);
  const handleClickAlbania = () => setShowAlbania(!showAlbania)

  const [showArmenia, setShowArmenia] = useState(true);
  const handleClickArmenia = () => setShowArmenia(!showArmenia)

  const [showAzerbaijan, setShowAzerbaijan] = useState(true);
  const handleClickAzerbaijan = () => setShowAzerbaijan(!showAzerbaijan)

  const [showBelarus, setShowBelarus] = useState(true);
  const handleClickBelarus = () => setShowBelarus(!showBelarus)

  const [showBosnia, setShowBosnia] = useState(true);
  const handleClickBosnia = () => setShowBosnia(!showBosnia)

  const [showBulgaria, setShowBulgaria] = useState(true);
  const handleClickBulgaria = () => setShowBulgaria(!showBulgaria)

  const [showCroatia, setShowCroatia] = useState(true);
  const handleClickCroatia = () => setShowCroatia(!showCroatia)

  const [showGeorgia, setShowGeorgia] = useState(true);
  const handleClickGeorgia = () => setShowGeorgia(!showGeorgia)

  const [showKazakhstan, setShowKazakhstan] = useState(true);
  const handleClickKazakhstan = () => setShowKazakhstan(!showKazakhstan)

  const [showKyrgyztan, setShowKyrgyztan] = useState(true);
  const handleClickKyrgyztan = () => setShowKyrgyztan(!showKyrgyztan)

  const [showMacedonia, setShowMacedonia] = useState(true);
  const handleClickMacedonia = () => setShowMacedonia(!showMacedonia)

  const [showMoldava, setShowMoldava] = useState(true);
  const handleClickMoldava = () => setShowMoldava(!showMoldava)

  const [showMontenegro, setShowMontenegro] = useState(true);
  const handleClickMontenegro = () => setShowMontenegro(!showMontenegro)

  const [showRomania, setShowRomania] = useState(true);
  const handleClickRomania = () => setShowRomania(!showRomania)

  const [showServia, setShowServia] = useState(true);
  const handleClickServia = () => setShowServia(!showServia)

  const [showTajikistan, setShowTajikistan] = useState(true);
  const handleClickTajikistan = () => setShowTajikistan(!showTajikistan)

  const [showTurkey, setShowTurkey] = useState(true);
  const handleClickTurkey = () => setShowTurkey(!showTurkey)

  const [showTurkmenistan, setShowTurkmenistan] = useState(true);
  const handleClickTurkmenistan = () => setShowTurkmenistan(!showTurkmenistan)

  const [showUkraine, setShowUkraine] = useState(true);
  const handleClickUkraine = () => setShowUkraine(!showUkraine)

  const [showUzbekistan, setShowUzbekistan] = useState(true);
  const handleClickUzbekistan = () => setShowUzbekistan(!showUzbekistan)

  const [showCountries, setShowCountries] = useState(false);

  const data = React.useMemo( () => [
      { 
        country: 'Albania',
        female1: 335, male1: 368,
        female2: 19.0, male2: 28.2, 
        female3: 64, male3: 104, total: 167
      },
      { 
        country: 'Armenia',
        female1: 288, male1: 305,
        female2: 20.8, male2: 22.5,
        female3: 60, male3: 69, total:129
      },
      { 
        country: 'Azerbaijan',
        female1: 1077, male1: 1175,
        female2: 24.7, male2: 22.7,
        female3: 266, male3: 267, total:533
      },
      { 
        country: 'Belarus',
        female1: 731, male1: 772,
        female2: 11.6, male2: 21.5,
        female3: 85, male3: 166, total:251
      },
      { 
        country: 'Bosnia & Herzegovina',
        female1: 298, male1: 314,
        female2: 17.7, male2: 22.5,
        female3: 53, male3: 71, total:123
      },
      { 
        country: 'Bulgaria',
        female1: 494, male1: 525,
        female2: 23.2, male2: 27.6,
        female3: 115, male3: 145, total:260
      },
      { 
        country: 'Croatia',
        female1: 334, male1: 352,
        female2: 20.9, male2: 30.3,
        female3: 70, male3: 107, total:176
      },
      { 
        country: 'Georgia',
        female1: 335, male1: 386,
        female2: 20.8, male2: 33.6,
        female3: 74, male3: 130, total:204
      },
      {
        country: 'Kazakhstan',
        female1: 1841, male1: 1907,
        female2: 15.3, male2: 17.4,
        female3: 282, male3: 332, total:613
      },
      { 
        country: 'Kyrgyztan',
        female1: 784, male1: 815,
        female2: 13.1, male2: 11.6,
        female3: 103, male3: 95, total:197
      },
      {
        country: 'North Macedonia',
        female1: 198, male1: 210,
        female2: 19.5, male2: 29.3,
        female3: 39, male3: 62, total:100
      },
      {
        country: 'Moldava',
        female1: 380, male1: 398,
        female2: 17.1, male2: 25.0,
        female3: 64, male3: 100, total:164
      },
      {
        country: 'Montenegro',
        female1: 60, male1: 66,
        female2: 22.9, male2: 32.1,
        female3: 14, male3: 21, total:35
      },
      {
        country: 'Romania',
        female1: 1563, male1: 1674,
        female2: 19.4, male2: 22.9,
        female3: 303, male3: 383, total:687
      },
      {
        country: 'Servia',
        female1: 778, male1: 832,
        female2: 20.9, male2: 22.7,
        female3: 163, male3: 189, total:351
      },
      {
        country: 'Tajikistan',
        female1: 1229, male1: 1286,
        female2: 11.7, male2: 18.5,
        female3: 144, male3: 238, total:382
      },
      {
        country: 'Turkey',
        female1: 9683, male1: 10009,
        female2: 25.5, male2: 28.0,
        female3: 2469, male3: 2803, total:5272
      },
      {
        country: 'Turkmenistan',
        female1: 747, male1: 763,
        female2: 27.5, male2: 23.8,
        female3: 205, male3: 182, total:387
      },
      {
        country: 'Ukraine',
        female1: 3347, male1: 3530,
        female2: 15.7, male2: 20.5,
        female3: 525, male3: 724, total:1249
      },
      {
        country: 'Uzbekistan',
        female1: 4168, male1: 4361,
        female2: 26.0, male2: 26.3,
        female3: 1084, male3: 1147, total:2231
      },
    ],
    []
  )

  const columns = React.useMemo(
    () => [
      { Header: 'Country', Footer: 'Total', accessor: 'country'},
      { 
        Header: 'Total (10-24 years)',
        Footer: 'Total (10-24 years)',
        columns: [
          { Header: 'Female', Footer: '28690', accessor: 'female1' },
          { Header: 'Male', Footer: '30048', accessor: 'male1' },
        ]
      },
      { 
        Header: 'Estimated prevalence O/wt or Obese (10-24 years)',
        Footer: 'Estimated prevalence O/wt or Obese (10-24 years)',
        columns: [
          { 
            Header: 'Female',
            Footer: '-', 
            accessor: 'female2',
            Cell: props => props.value.toFixed(1)
          },
          { 
            Header: 'Male',
            Footer: '-',
            accessor: 'male2',
            Cell: props => props.value.toFixed(1)
          },
        ]
      },
      { 
        Header: 'Estimated number of O/wt or Obese (10-24 years)',
        Footer: 'Estimated number of O/wt or Obese (10-24 years)',
        columns: [
          { Header: 'Female', Footer: '6180', accessor: 'female3' },
          { Header: 'Male', Footer: '7330', accessor: 'male3' },
          { Header: 'Total', Footer: '13511', accessor: 'total' },
        ]
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    allColumns,
  } = useTable({ columns, data }, useSortBy)

  const checkItems = React.useMemo( () => [
    { 
      country: 'Albania',
      classItem: 'albania',
      showItem: showAlbania,
      handleItem: handleClickAlbania, 
    },
    { 
      country: 'Armenia',
      classItem: 'armenia',
      showItem: showArmenia,
      handleItem: handleClickArmenia,
    },
    { 
      country: 'Azerbaijan',
      classItem: 'azerbaijan',
      showItem: showAzerbaijan,
      handleItem: handleClickAzerbaijan,
    },
    { 
      country: 'Belarus',
      classItem: 'belarus',
      showItem: showBelarus,
      handleItem: handleClickBelarus,
    },
    { 
      country: 'Bosnia & Herzegovina',
      classItem: 'bosnia',
      showItem: showBosnia,
      handleItem: handleClickBosnia,
    },
    { 
      country: 'Bulgaria',
      classItem: 'bulgaria',
      showItem: showBulgaria,
      handleItem: handleClickBulgaria,
    },
    { 
      country: 'Croatia',
      classItem: 'croatia',
      showItem: showCroatia,
      handleItem: handleClickCroatia,
    },
    { 
      country: 'Georgia',
      classItem: 'georgia',
      showItem: showGeorgia,
      handleItem: handleClickGeorgia,
    },
    {
      country: 'Kazakhstan',
      classItem: 'kazakhstan',
      showItem: showKazakhstan,
      handleItem: handleClickKazakhstan,
    },
    { 
      country: 'Kyrgyztan',
      classItem: 'kyrgyztan',
      showItem: showKyrgyztan,
      handleItem: handleClickKyrgyztan,
    },
    {
      country: 'North Macedonia',
      classItem: 'macedonia',
      showItem: showMacedonia,
      handleItem: handleClickMacedonia,
    },
    {
      country: 'Moldava',
      classItem: 'moldava',
      showItem: showMoldava,
      handleItem: handleClickMoldava,
    },
    {
      country: 'Montenegro',
      classItem: 'montenegro',
      showItem: showMontenegro,
      handleItem: handleClickMontenegro,
    },
    {
      country: 'Romania',
      classItem: 'romania',
      showItem: showRomania,
      handleItem: handleClickRomania,
    },
    {
      country: 'Servia',
      classItem: 'servia',
      showItem: showServia,
      handleItem: handleClickServia,
    },
    {
      country: 'Tajikistan',
      classItem: 'tajikistan',
      showItem: showTajikistan,
      handleItem: handleClickTajikistan,
    },
    {
      country: 'Turkey',
      classItem: 'turkey',
      showItem: showTurkey,
      handleItem: handleClickTurkey,
    },
    {
      country: 'Turkmenistan',
      classItem: 'turkmenistan',
      showItem: showTurkmenistan,
      handleItem: handleClickTurkmenistan,
    },
    {
      country: 'Ukraine',
      classItem: 'ukraine',
      showItem: showUkraine,
      handleItem: handleClickUkraine,
    },
    {
      country: 'Uzbekistan',
      classItem: 'uzbekistan',
      showItem: showUzbekistan,
      handleItem: handleClickUzbekistan,
    },
  ])

  const [showTotal, setShowTotal] = useState(true);
  const [showPrevalance, setShowPrevalence] = useState(true);
  const [showNumber, setShowNumber] = useState(true);
  const [showFemale, setShowFemale] = useState(true);
  const [showMale, setShowMale] = useState(true);
  
  // Set Handle Total
  const handleClickTotalT = () => {
    setShowTotal(true)
    allColumns.map(column => {
      if (column.render('Footer') === '28690') {
        if (showFemale) {
          column.toggleHidden(false)
        } 
      }
      if (column.render('Footer') === '30048') {
        if (showMale) {
          column.toggleHidden(false)
        } 
      }
    })
  }
  const handleClickTotalF = () => {
    setShowTotal(false)
    allColumns.map(column => {
      if (column.render('Footer') === '28690') {
        if (showFemale) {
          column.toggleHidden(true)
        } 
      }
      if (column.render('Footer') === '30048') {
        if (showMale) {
          column.toggleHidden(true)
        } 
      }
    })
  }
  // Set Handle Prevalance
  const handleClickPrevalanceT = () => {
    setShowPrevalence(true)
    allColumns.map(column => {
      if (column.render('Header') === 'Female' && column.render('Footer') === '-') {
        if (showFemale) {
          column.toggleHidden(false)
        } 
      }
      if (column.render('Header') === 'Male' && column.render('Footer') === '-') {
        if (showMale) {
          column.toggleHidden(false)
        } 
      }
    })
  }
  const handleClickPrevalanceF = () => {
    setShowPrevalence(false)
    allColumns.map(column => {
      if (column.render('Header') === 'Female' && column.render('Footer') === '-') {
        if (showFemale) {
          column.toggleHidden(true)
        } 
      }
      if (column.render('Header') === 'Male' && column.render('Footer') === '-') {
        if (showMale) {
          column.toggleHidden(true)
        } 
      }
    })
  }
  // Set Handle Number
  const handleClickNumberT = () => {
    setShowNumber(true)
    allColumns.map(column => {
      if (column.render('Footer') === '6180') {
        if (showFemale) {
          column.toggleHidden(false)
        } 
      }
      if (column.render('Footer') === '7330') {
        if (showMale) {
          column.toggleHidden(false)
        } 
      }
      if (column.render('Header') === 'Total') {
        column.toggleHidden(false)
      }
    })
  }
  const handleClickNumberF = () => {
    setShowNumber(false)
    allColumns.map(column => {
      if (column.render('Footer') === '6180') {
        if (showFemale) {
          column.toggleHidden(true)
        } 
      }
      if (column.render('Footer') === '7330') {
        if (showMale) {
          column.toggleHidden(true)
        } 
      }
      if (column.render('Header') === 'Total') {
        column.toggleHidden(true)
      }
    })
  }
  // Set Handle Female
  const handleClickFemaleT = () => { 
    setShowFemale(true)
    allColumns.map(column => {
      if (column.render('Header') === 'Female') {
        if (column.render('Footer') === '28690') {
          if (showTotal) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === '-') {
          if (showPrevalance) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === '6180') {
          if (showNumber) {
            column.toggleHidden(false)
          }
        }
      }
    })
  }
  const handleClickFemaleF = () => { 
    setShowFemale(false)
    allColumns.map(column => {
      if (column.render('Header') === 'Female') {
        if (column.render('Footer') === '28690') {
          if (showTotal) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === '-') {
          if (showPrevalance) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === '6180') {
          if (showNumber) {
            column.toggleHidden(true)
          }
        }
      }
    })
  }
  // Set Handle Male
  const handleClickMaleT = () => { 
    setShowMale(true)
    allColumns.map(column => {
      if (column.render('Header') === 'Male') {
        if (column.render('Footer') === '30048') {
          if (showTotal) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === '-') {
          if (showPrevalance) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === '7330') {
          if (showNumber) {
            column.toggleHidden(false)
          }
        }
      }
    })
  }
  const handleClickMaleF = () => { 
    setShowMale(false)
    allColumns.map(column => {
      if (column.render('Header') === 'Male') {
        if (column.render('Footer') === '30048') {
          if (showTotal) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === '-') {
          if (showPrevalance) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === '7330') {
          if (showNumber) {
            column.toggleHidden(true)
          }
        }
      }
    })
  }
  
  return (
    <>
      <div className="table-scroll relative min-h-550">
        {/* COUNTRIES */}
        <button
          onClick={() => setShowCountries(true)}
          className="
            absolute z-30 top-2 left-2 
            text-white text-xs
            hover:text-sec-blue ani03s"
        >
          Select countries
          <span className="icon-down pl-2"></span>
        </button>
        {/* COUNTRY SELECT LIST */}
        { showCountries &&
          <div className="
            absolute z-40 p-4 m-1 w-52
            flex flex-col
            bg-white border border-main_blue shadow
            text-sm
          ">
            <div className="flex justify-between pb-4 text-xs text-main_blue">
              <div className="">Select to sort</div>
              <button 
                className="icon-close hover:text-sec_blue ani03s"
                onClick={() => setShowCountries(false)}></button>
            </div>
            {checkItems.map(checkItem => (
              <div className="flex items-center justify-between pb-1">
                <div className="pr-2">{ checkItem.country }</div>
                <div className="round_checkbox_box">
                  <div className="center">
                      <input onClick={ checkItem.handleItem } defaultChecked={ checkItem.showItem } type="checkbox" id={ checkItem.classItem }/>
                    <label htmlFor={ checkItem.classItem }></label>
                  </div>
                </div>
              </div>
          ))}


          </div>
        }
        {/* TABLE */}
        <table {...getTableProps()} 
          className="dTable tableRightBorder tableTextLR
          tableStripes">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th 
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className=
                    {`
                      ${headerGroup.headers[0].render('Header') === 'Country' ? 'botHeader ani03s' : 'topHeader'}
                      ${column.render('Header') === 'Female' ? 'female' : ''}
                      ${column.render('Header') === 'Male' ? 'male' : ''}
                      ${column.render('Header') === 'Total' ? 'total' : ''}
                    `}
                  >
                    {column.render('Header') === 'Female' ? 
                    <span className="gender icon-female"></span> : '' }
                    {column.render('Header') === 'Male' ? 
                    <span className="gender icon-male"></span> : '' }
                    {column.render('Header')}
                    {headerGroup.headers[0].render('Header') === 'Country' ? 
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <span className="sort icon-sort-desc"></span>
                            : <span className="sort icon-sort-asc"></span>
                          : <span className="sort icon-sort"></span>}
                      </span>
                      : ''
                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
                if (row.cells[0].value === 'Albania' && showAlbania) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Armenia' && showArmenia) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Azerbaijan' && showAzerbaijan) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Belarus' && showBelarus) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Bosnia & Herzegovina' && showBosnia) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Bulgaria' && showBulgaria) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Croatia' && showCroatia) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Georgia' && showGeorgia) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Kazakhstan' && showKazakhstan) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Kyrgyztan' && showKyrgyztan) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'North Macedonia' && showMacedonia) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Moldava' && showMoldava) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Montenegro' && showMontenegro) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Romania' && showRomania) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Servia' && showServia) {
                  return (
                    <BaseRow row={ row } supTxt={'*'} />
                  )
                } else if (row.cells[0].value === 'Tajikistan' && showTajikistan) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Turkey' && showTurkey) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Turkmenistan' && showTurkmenistan) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Ukraine' && showUkraine) {
                  return (
                    <BaseRow row={ row } />
                  )
                } else if (row.cells[0].value === 'Uzbekistan' && showUzbekistan) {
                  return (
                    <BaseRow row={ row } />
                  )
                }
            })}
          </tbody>
          <tfoot>
            {footerGroups.map(group => (
              <>
              {group.headers[0].render('Footer') === 'Total' ?
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              : ''}
              </>
            ))}
          </tfoot>
        </table>
      </div>
      <div className="my-2 border border-solid border-txt_black_2 text-xs px-2 py-1">
        <span className="sup">*</span>Kosovo (UNSCR 1244) population figures are included in Serbia. No estimates of overweight or obesity were provided for Kosovo in any of the surveys reviewed.
      </div>
      {/* SORT CHECKBOXES */}
      <div className="flex text-main_blue text-sm">Select to sort</div>
      <div className="sortBox">
        {/* LEFT BOX */}
        <div className="left_box">
          {/* TOTAL CHECKBOX */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Total (10-24 years)</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showTotal && 
                  <input onClick={handleClickTotalF} defaultChecked={showTotal} type="checkbox" id="total"/>
                }
                {!showTotal && 
                  <input onClick={handleClickTotalT} defaultChecked={showTotal} type="checkbox" id="total"/>
                }
                <label htmlFor="total"></label>
              </div>
            </div>
          </div> 
          {/* CHECKED PREVALENCE */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Estimated prevalence</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showPrevalance && 
                  <input onClick={handleClickPrevalanceF} defaultChecked={showPrevalance} type="checkbox" id="prevalance"/>
                }
                {!showPrevalance && 
                  <input onClick={handleClickPrevalanceT} defaultChecked={showPrevalance} type="checkbox" id="prevalance"/>
                }
                <label htmlFor="prevalance"></label>
              </div>
            </div>
          </div> 
          {/* CHECKED NUMBER */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Estimated number</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showNumber && 
                  <input onClick={handleClickNumberF} defaultChecked={showNumber} type="checkbox" id="number"/>
                }
                {!showNumber && 
                  <input onClick={handleClickNumberT} defaultChecked={showNumber} type="checkbox" id="number"/>
                }
                <label htmlFor="number"></label>
              </div>
            </div>
          </div> 
        </div>
        {/* RIGHT BOX */}
        <div className="right_box">
          {/* CHECKBOX FEMALE */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Female</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showFemale &&
                  <input onClick={handleClickFemaleF} defaultChecked={showFemale} type="checkbox" id="female"/>
                }
                {!showFemale &&
                  <input onClick={handleClickFemaleT} defaultChecked={showFemale} type="checkbox" id="female"/>
                }
                <label htmlFor="female"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX MALE */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Male</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showMale &&
                  <input onClick={handleClickMaleF} defaultChecked={showMale} type="checkbox" id="male"/>
                }
                {!showMale &&
                  <input onClick={handleClickMaleT} defaultChecked={showMale} type="checkbox" id="male"/>
                }
                <label htmlFor="male"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Table04 