import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
// Import Logo
import Logo from '../images/logo_unicef.svg'
// Import Components
import BtnNav from './BtnNav'
import BtnNavClose from './BtnNavClose'
import NavBar from './NavBar'
// Component
const Header = () => {
  // CONSTANTS
  const node = useRef();
  const [showNav, setShowNav] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setShowNav(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
    document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const location = useLocation();
    
  // RETURNING
  return (
    <header className="flex flex-col">
      <div className="
        flex flex-row justify-between
        text-sm items-center text-white
        bg-header_top_bg
        pl-4 pr-6 py-2 h-8 ">
        <div className="flex flex-row align">
            <Link to="/"><img className="max-h-6" src={Logo} alt="Unicef logo" /></Link>
            <span className="hidden xs:inline pl-4 align-baseline leading-6">nutrition</span>
        </div>
        <div className="office self-center md:hidden text-xs">
            Europe and Central Asia Regional Office
        </div>
        <div className="slogan2 xs:hidden text-right">
            for every child, <span>nutrition</span>
        </div>
      </div>
      <div className="flex flex-col flex-nowrap px-4 py-2 bg-main_blue relative">
        {location.pathname === '/' ?
          <div className="h-full flex flex-col justify-center items-center p-1">
            <h1 className="font-light text-4xl lg:text-4xl md:text-3xl xs:text-2xl xxs:text-xl text-sec_blue text-center">
                for every child, <span className="font-black">nutrition</span>
            </h1>
          </div>
        :
          <div className="flex flex-row flex-nowrap justify-between">
            <div className="font-normal text-white text-xs flex-grow-0">
              <div className="font-bold">A REVIEW OF THE SCHOOL-AGED</div>
              <div className="font-bold">CHILDREN & ADOLESCENT NUTRITION</div>
              <div className="text-white bg-txt_black_1 text-xxs px-1 mt-1 inline-block">in UNICEF's Europe and Central Asia (ECA) Region</div>
            </div>
          </div>
        }
        <div className="absolute bottom-2 right-5">
          {showNav &&
            <BtnNav setShowNav = { setShowNav } />
          }
          {!showNav &&
            <BtnNavClose setShowNav = { setShowNav } />
          }
        </div>
      </div>
      
      <div ref={node} className="relative w-full h-0">
        { showNav && 
          <NavBar 
            showNav={ showNav }
            setShowNav={ setShowNav }
          /> 
        }
      </div>
    </header>
  )
}
// Export
export default Header
