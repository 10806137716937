import React, { useState } from 'react';
// Import Table Components
import LabelBox from '../../table_comp/LabelBox'
import Bar from '../../table_comp/Bar'

// Component
const Figure03_2 = () => {

  const topValue = 60;

  const [showGirls, setShowGirls] = useState(true);
  const handleClickGirls = () => setShowGirls(!showGirls)

  const [showBoys, setShowBoys] = useState(true);
  const handleClickBoys = () => setShowBoys(!showBoys)

  const [showFruits, setShowFruits] = useState(true);
  const handleClickFruits = () => setShowFruits(!showFruits)

  const [showVegetables, setShowVegetables] = useState(true);
  const handleClickVegetables = () => setShowVegetables(!showVegetables)

  const [showSweets, setShowSweets] = useState(true);
  const handleClickSweets = () => setShowSweets(!showSweets)

  const [showDrinks, setShowDrinks] = useState(true);
  const handleClickDrinks = () => setShowDrinks(!showDrinks)

  const [showExercises, setShowExercises] = useState(true);
  const handleClickExercises = () => setShowExercises(!showExercises)

  const labelFruits = 'Daily fruit\nconsumption (%)'
  const labelVegetables = 'Daily vegetable\nconsumption (%)'
  const labelSweets = 'Daily sweets\nconsumption (%)'
  const labelDrinks = 'Daily soft drink\nconsumption (%)'
  const labelExercises = 'Moderate\nexercise (%)*'

  const color1 = '#83d0f5'
  const color2 = '#00AEEF'
  const color3 = '#0b709a'
  const color4 = '#3b4395'
  
  return (
    <>
      <div className="barChartBox">
        <div className="barChart">
          {/* FRUITS */}
          {showFruits &&
            <div className="chartRow mb-3">
              <LabelBox 
                label={ labelFruits }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
                {/* GIRLS */}
                {showGirls &&
                  <div className="pb-1">
                    <Bar 
                      barColor = { color1 }
                      barWidth = { (46.9 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g02_01' }
                      tipLabel = { labelFruits }
                      tipSubLabel = { 'Girls 2002' }
                      tipValue = { 46.9 }
                    />
                    <Bar 
                      barColor = { color2 }
                      barWidth = { (47.0 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g06_01' }
                      tipLabel = { labelFruits }
                      tipSubLabel = { 'Girls 2006' }
                      tipValue = { 47.0 }
                    />
                    <Bar 
                      barColor = { color3 }
                      barWidth = { (47.8 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g10_01' }
                      tipLabel = { labelFruits }
                      tipSubLabel = { 'Girls 2010' }
                      tipValue = { 47.8 }
                    />
                    <Bar 
                      barColor = { color4 }
                      barWidth = { (44.8 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g14_01' }
                      tipLabel = { labelFruits }
                      tipSubLabel = { 'Girls 2014' }
                      tipValue = { 44.8 }
                    />
                  </div>
                }
                {/* BOYS */}
                {showBoys &&
                  <div>
                    <Bar 
                      barColor = { color1 }
                      barWidth = { (40.0 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b02_01' }
                      tipLabel = { labelFruits }
                      tipSubLabel = { 'Boys 2002' }
                      tipValue = { 40.0 }
                    />
                    <Bar 
                      barColor = { color2 }
                      barWidth = { (37.5 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b06_01' }
                      tipLabel = { labelFruits }
                      tipSubLabel = { 'Boys 2006' }
                      tipValue = { 37.5 }
                    />
                    <Bar 
                      barColor = { color3 }
                      barWidth = { (39.1 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b10_01' }
                      tipLabel = { labelFruits }
                      tipSubLabel = { 'Boys 2010' }
                      tipValue = { 39.1 }
                    />
                    <Bar 
                      barColor = { color4 }
                      barWidth = { (34.9 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b14_01' }
                      tipLabel = { labelFruits }
                      tipSubLabel = { 'Boys 2014' }
                      tipValue = { 34.9 }
                    />
                  </div>
                }
              </div>
            </div>
          }
          {/* VEGETABLES */}
          {showVegetables &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox
                label={ labelVegetables }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
                {/* GIRLS */}
                {showGirls &&
                  <div className="pb-1">
                    <Bar 
                      barColor = { color1 }
                      barWidth = { (35.7 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g02_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Girls 2002' }
                      tipValue = { 35.7 }
                    />
                    <Bar 
                      barColor = { color2 }
                      barWidth = { (41.2 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g06_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Girls 2006' }
                      tipValue = { 41.2 }
                    />
                    <Bar 
                      barColor = { color3 }
                      barWidth = { (41.3 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g10_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Girls 2010' }
                      tipValue = { 41.3 }
                    />
                    <Bar 
                      barColor = { color4 }
                      barWidth = { (46.7 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g14_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Girls 2014' }
                      tipValue = { 46.7 }
                    />
                  </div>
                }
                {showBoys &&
                  <div>
                    <Bar 
                      barColor = { color1 }
                      barWidth = { (30.2 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b02_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Boys 2002' }
                      tipValue = { 30.2 }
                    />
                    <Bar 
                      barColor = { color2 }
                      barWidth = { (31.5 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b06_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Boys 2006' }
                      tipValue = { 31.5 }
                    />
                    <Bar 
                      barColor = { color3 }
                      barWidth = { (31.8 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b10_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Boys 2010' }
                      tipValue = { 31.8 }
                    />
                    <Bar 
                      barColor = { color4 }
                      barWidth = { (35.4 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b14_02' }
                      tipLabel = { labelVegetables }
                      tipSubLabel = { 'Boys 2014' }
                      tipValue = { 35.4 }
                    />
                  </div>
                }
              </div>
            </div>
          }
          {/* SWEETS */}
          {showSweets &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox
                label={ labelSweets }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
                {/* GIRLS */}
                {showGirls &&
                  <div className="pb-1">
                    <Bar 
                      barColor = { color1 }
                      barWidth = { (44.2 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g02_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Girls 2002' }
                      tipValue = { 44.2 }
                    />
                    <Bar 
                      barColor = { color2 }
                      barWidth = { (49.2 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g06_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Girls 2006' }
                      tipValue = { 49.2 }
                    />
                    <Bar 
                      barColor = { color3 }
                      barWidth = { (42.6 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g10_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Girls 2010' }
                      tipValue = { 42.6 }
                    />
                    <Bar 
                      barColor = { color4 }
                      barWidth = { (38.3 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g14_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Girls 2014' }
                      tipValue = { 38.3 }
                    />
                  </div>
                }
                {showBoys &&
                  <div>
                    <Bar 
                      barColor = { color1 }
                      barWidth = { (40.0 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b02_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Boys 2002' }
                      tipValue = { 40.0 }
                    />
                    <Bar 
                      barColor = { color2 }
                      barWidth = { (41.9 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b06_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Boys 2006' }
                      tipValue = { 41.9 }
                    />
                    <Bar 
                      barColor = { color3 }
                      barWidth = { (35.4 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b10_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Boys 2010' }
                      tipValue = { 35.4 }
                    />
                    <Bar 
                      barColor = { color4 }
                      barWidth = { (32.3 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b14_03' }
                      tipLabel = { labelSweets }
                      tipSubLabel = { 'Boys 2014' }
                      tipValue = { 32.3 }
                    />
                  </div>
                }
              </div>
            </div>
          }
          {/* DRINKS */}
          {showDrinks &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox
                label={ labelDrinks }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
                {/* GIRLS */}
                {showGirls &&
                  <div className="pb-1">
                    <Bar 
                      barColor = { color1 }
                      barWidth = { (31.5 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g02_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Girls 2002' }
                      tipValue = { 31.5 }
                    />
                    <Bar 
                      barColor = { color2 }
                      barWidth = { (36.0 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g06_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Girls 2006' }
                      tipValue = { 36.0 }
                    />
                    <Bar 
                      barColor = { color3 }
                      barWidth = { (29.4 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g10_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Girls 2010' }
                      tipValue = { 29.4 }
                    />
                    <Bar 
                      barColor = { color4 }
                      barWidth = { (28.6 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g14_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Girls 2014' }
                      tipValue = { 28.6 }
                    />
                  </div>
                }
                {showBoys &&
                  <div>
                    <Bar 
                      barColor = { color1 }
                      barWidth = { (37.4 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b02_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Boys 2002' }
                      tipValue = { 37.4 }
                    />
                    <Bar 
                      barColor = { color2 }
                      barWidth = { (36.5 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b06_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Boys 2006' }
                      tipValue = { 36.5 }
                    />
                    <Bar 
                      barColor = { color3 }
                      barWidth = { (31.6 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b10_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Boys 2010' }
                      tipValue = { 31.6 }
                    />
                    <Bar 
                      barColor = { color4 }
                      barWidth = { (31.7 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b14_04' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Boys 2014' }
                      tipValue = { 31.7 }
                    />
                  </div>
                }
              </div>
            </div>
          }
          {/* EXERCISES */}
          {showExercises &&
            <div className="chartRow flex mb-3 items-stretch">
              <LabelBox
                label={ labelExercises }
                showGirls = { showGirls }
                showBoys = { showBoys }
              />
              <div className="bars">
                {/* GIRLS */}
                {showGirls &&
                  <div className="pb-1">
                    <Bar 
                      barColor = { color1 }
                      barWidth = { (14.4 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g02_05' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Girls 2002' }
                      tipValue = { 14.4 }
                    />
                    <Bar 
                      barColor = { color2 }
                      barWidth = { (18.6 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g06_05' }
                      tipLabel = { labelDrinks }
                      tipSubLabel = { 'Girls 2006' }
                      tipValue = { 18.6 }
                    />
                    <Bar 
                      barColor = { color3 }
                      barWidth = { (18.8 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g10_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Girls 2010' }
                      tipValue = { 18.8 }
                    />
                    <Bar 
                      barColor = { color4 }
                      barWidth = { (22.2 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'g14_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Girls 2014' }
                      tipValue = { 22.2 }
                    />
                  </div>
                }
                {showBoys &&
                  <div>
                    <Bar 
                      barColor = { color1 }
                      barWidth = { (20.3 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b02_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Boys 2002' }
                      tipValue = { 20.3 }
                    />
                    <Bar 
                      barColor = { color2 }
                      barWidth = { (26.9 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b06_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Boys 2006' }
                      tipValue = { 26.9 }
                    />
                    <Bar 
                      barColor = { color3 }
                      barWidth = { (27.4 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b06_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Boys 2006' }
                      tipValue = { 27.4 }
                    />
                    <Bar 
                      barColor = { color4 }
                      barWidth = { (31.9 * 100) / topValue }
                      barHeight = { '12px' }
                      dataTipId = { 'b10_05' }
                      tipLabel = { labelExercises }
                      tipSubLabel = { 'Boys 2010' }
                      tipValue = { 31.9 }
                    />
                  </div>
                }
              </div>
            </div>
          }
        </div>
        {/* BACK LINES */}
        <div className="lines" aria-hidden="true">
          <div className="labelBox solidLine"><span>0</span></div>
          <div className="dotLines">
            <div><span>10</span></div>
            <div><span>20</span></div>
            <div><span>30</span></div>
            <div><span>40</span></div>
            <div><span>50</span></div>
            <div><span>60</span></div>
          </div>
        </div>
      </div>
      <div className="infoBox">
        <span>*</span>Moderate exercise is defined as 60 minutes of moderate exercise or more daily
      </div>
      
      {/* SORT CHECKBOXES */}
      <div className="flex text-main_blue text-sm">Select to sort</div>
      <div className="sortBox">
        {/* LEFT BOX */}
        <div className="left_box sortBtnBox">
          {/* CHECKBOX FRUITS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelFruits }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickFruits} defaultChecked={showFruits} type="checkbox" id="fruits"/>
                <label htmlFor="fruits"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX VEGETABLES */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelVegetables }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickVegetables} defaultChecked={showFruits} type="checkbox" id="vegetables"/>
                <label htmlFor="vegetables"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX SWEETS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelSweets }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickSweets} defaultChecked={showFruits} type="checkbox" id="sweets"/>
                <label htmlFor="sweets"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX DRINKS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelDrinks }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickDrinks} defaultChecked={showFruits} type="checkbox" id="drinks"/>
                <label htmlFor="drinks"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX EXERCISES */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ labelExercises }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickExercises} defaultChecked={showFruits} type="checkbox" id="exercises"/>
                <label htmlFor="exercises"></label>
              </div>
            </div>
          </div>
        </div>
        {/* RIGHT BOX */}
        <div className="right_box">
          {/* CHECKBOX GIRLS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Girls</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickGirls} defaultChecked={showGirls} type="checkbox" id="girls"/>
                <label htmlFor="girls"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX BOYS */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Boys</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickBoys} defaultChecked={showBoys} type="checkbox" id="boys"/>
                <label htmlFor="boys"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Figure03_2 
