import React, { useState } from 'react';
import ImageMapper from 'react-image-mapper';
// Import Images
import Figure05_2_blank from '../../images/fig05_2_blank.png'
import Figure05_2_main from '../../images/fig05_2_main.svg'
import Figure05_2_green from '../../images/fig05_2_green.svg'
import Figure05_2_blue from '../../images/fig05_2_blue.svg'
import Figure05_2_dark_blue from '../../images/fig05_2_dark_blue.svg'
import Figure05_2_orange from '../../images/fig05_2_orange.svg'
import Figure05_2_purple from '../../images/fig05_2_purple.svg'
// Import Components
import TableTitle from '../../components/TableTitle'
// Component
const Figure05_2 = () => {

  const figureDesc = 'The Innocenti Framework on Food Systems for Children and Adolescents'
  const map = {
    name: "figure-5",
    areas: [
      { name: "Food Supply Chains", shape: "rect", coords: [35,310,305,510] },
      { name: "Personal Food Environments", shape: "rect", coords: [390,80,660,270] },
      { name: "External Food Environments", shape: "rect", coords: [390,560,660,750] },
      { name: "Food Provider Behavior", shape: "rect", coords: [700,310,970,510] },
      { name: "Diet of Child/Adolescent", shape: "rect", coords: [1060,310,1470,510],},
    ]
  }

  const [showBackMain, setShowBackMain] = useState(true);
  const [showBackGreen, setShowBackGreen] = useState(false);
  const [showBackBlue, setShowBackBlue] = useState(false);
  const [showBackDarkBlue, setShowBackDarkBlue] = useState(false);
  const [showBackOrange, setShowBackOrange] = useState(false);
  const [showBackPurple, setShowBackPurple] = useState(false);


  const [showGreen, setShowGreen] = useState(false);
  const closeModalGreenHandler = () => setShowGreen(!showGreen);

  const [showBlue, setShowBlue] = useState(false);
  const closeModalBlueHandler = () => setShowBlue(!showBlue);

  const [showDarkBlue, setShowDarkBlue] = useState(false);
  const closeModalDarkBlueHandler = () => setShowDarkBlue(!showDarkBlue);

  const [showOrange, setShowOrange] = useState(false);
  const closeModalOrangeHandler = () => setShowOrange(!showOrange);

  const [showPurple, setShowPurple] = useState(false);
  const closeModalPurpleHandler = () => setShowPurple(!showPurple);

  const enterArea = (area, index) => {
    setShowBackMain(false);
    switch (index) {
      case 0:
        setShowBackGreen(true);
        break;
      case 1:
        setShowBackBlue(true);
        break;
      case 2:
        setShowBackDarkBlue(true);
        break;
      case 3:
        setShowBackOrange(true);
        break;
      case 4:
        setShowBackPurple(true);
        break;
      default:
        setShowBackMain(true);
        break;
    }
  }

  const leaveArea = (area, index) => {
    setShowBackMain(true);
    switch (index) {
      case 0:
        setShowBackGreen(false);
        break;
      case 1:
        setShowBackBlue(false);
        break;
      case 2:
        setShowBackDarkBlue(false);
        break;
      case 3:
        setShowBackOrange(false);
        break;
      case 4:
        setShowBackPurple(false);
        break;
      default:
        setShowBackMain(true);
        break;
    }
  }

  const clicked = (area, index) => {
    switch (index) {
      case 0:
        closeModalGreenHandler();
        break;
      case 1:
        closeModalBlueHandler();
        break;
      case 2:
        closeModalDarkBlueHandler();
        break;
      case 3:
        closeModalOrangeHandler();
        break;
      case 4:
        closeModalPurpleHandler();
        break;
    }
  }

  const ImageMapComponent = React.useMemo(() => 
  <ImageMapper 
    src={ Figure05_2_blank }
    map={map} width={800} 
    imgWidth={1500}
    onClick={clicked}
    onMouseEnter= {enterArea}
    onMouseLeave= {leaveArea}
  />, [Figure05_2_blank, map]);

  return (
    <>
    <TableTitle
      title={'Figure 5'}
      description={ figureDesc }
    />
    <div
      className="w-full imgMapContainer"
      style={{backgroundImage: 'url(' + Figure05_2_main  + ')'}}
      >
      { showBackMain &&
        <div
          className="w-full block imgMapContainer"
          style={{backgroundImage: 'url(' + Figure05_2_main  + ')'}}
        > 
          { ImageMapComponent }
          
        </div>
      }
      { showBackGreen &&
        <div
          className="w-full block imgMapContainer"
          style={{backgroundImage: 'url(' + Figure05_2_green  + ')'}}
        > 
          { ImageMapComponent }
        </div>
      }
      { showBackBlue &&
        <div
          className="w-full block imgMapContainer"
          style={{backgroundImage: 'url(' + Figure05_2_blue  + ')'}}
        > 
          { ImageMapComponent }
        </div>
      }
      { showBackDarkBlue &&
        <div
          className="w-full block imgMapContainer"
          style={{backgroundImage: 'url(' + Figure05_2_dark_blue  + ')'}}
        > 
          { ImageMapComponent }
        </div>
      }
      { showBackOrange &&
        <div
          className="w-full block imgMapContainer"
          style={{backgroundImage: 'url(' + Figure05_2_orange  + ')'}}
        > 
          { ImageMapComponent }
        </div>
      }
      { showBackPurple &&
        <div
          className="w-full block imgMapContainer"
          style={{backgroundImage: 'url(' + Figure05_2_purple  + ')'}}
        > 
          { ImageMapComponent }
        </div>
      }   
    </div> 
    <div className="notes_box block">Source: 1.HLPE. 2017; 2. Turner, et. al, 2017.</div>
    
    { showGreen && 
      <div
        onClick={closeModalGreenHandler}
        style={{ opacity: showGreen ? 1 : 0 }}
        className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
        <div className="px-8 py-6 bg-white relative max-w-400">
          <div
            onClick={closeModalGreenHandler}
            className="icon-close absolute top-1 right-1 cursor-pointer">
          </div>
          <h1 className="font-bold">Food Supply Chains</h1>
          <ul className="bullets pb-0">
            <li>Imputs</li>
            <li>Post-harvest, processing and distribution</li>
            <li>Natural resources management</li>
            <li>Agricultural research and development</li>
          </ul>
        </div>
      </div>
    }

    { showBlue && 
      <div
        onClick={closeModalBlueHandler}
        style={{ opacity: showBlue ? 1 : 0 }}
        className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
        <div className="px-8 py-6 bg-white relative max-w-400">
          <div
            onClick={closeModalBlueHandler}
            className="icon-close absolute top-1 right-1 cursor-pointer">
          </div>
          <h1 className="font-bold">Personal Food Environments</h1>
          <ul className="bullets pb-0">
            <li>Accessibility of food</li>
            <li>Affordability of food</li>
            <li>Convenience</li>
          </ul>
        </div>
      </div>
    }

    { showDarkBlue && 
      <div
        onClick={closeModalDarkBlueHandler}
        style={{ opacity: showDarkBlue ? 1 : 0 }}
        className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
        <div className="px-8 py-6 bg-white relative max-w-400">
          <div
            onClick={closeModalDarkBlueHandler}
            className="icon-close absolute top-1 right-1 cursor-pointer">
          </div>
          <h1 className="font-bold">External Food Environments</h1>
          <ul className="bullets pb-0">
            <li>Availability and accessibility of food</li>
            <li>Price of food</li>
            <li>Food quality and safety</li>
            <li>Marketing and regulation</li>
          </ul>
        </div>
      </div>
    }

    { showOrange && 
      <div
        onClick={closeModalOrangeHandler}
        style={{ opacity: showOrange ? 1 : 0 }}
        className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
        <div className="px-8 py-6 bg-white relative max-w-400">
          <div
            onClick={closeModalOrangeHandler}
            className="icon-close absolute top-1 right-1 cursor-pointer">
          </div>
          <h1 className="font-bold">Food Provider Behavior</h1>
          <ul className="bullets pb-0">
            <li>Intra-household dynamics</li>
            <li>Food preparation</li>
            <li>Desirability and accetability of food</li>
            <li>Socio-economic characteristics</li>
          </ul>
        </div>
      </div>
    }

    { showPurple && 
      <div
        onClick={closeModalPurpleHandler}
        style={{ opacity: showPurple ? 1 : 0 }}
        className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
        <div className="px-8 py-6 bg-white relative max-w-400">
          <div
            onClick={closeModalPurpleHandler}
            className="icon-close absolute top-1 right-1 cursor-pointer">
          </div>
          <h1 className="font-bold">Diet of Child/Adolescent</h1>
          <ul className="bullets pb-0">
            <li>Eating patterns</li>
            <li>Taste preferences</li>
            <li>Appetite</li>
            <li>Psycho-social factors</li>
          </ul>
        </div>
      </div>
    }

  </>
             
  )
}
// Export
export default Figure05_2 
