import React, { useState } from 'react';
// Import Page Elements
import Table03 from '../../page_elements/tables/Table03';
// Import Components
import PageTitle from '../../components/PageTitle'
import TableTitle from '../../components/TableTitle'
// Component
const Overview = () => {
  return (
    <>
      <PageTitle title="Overview of key findings" />
      {/* ARTICLE CONTENT */}
      <div className="article_content">
        <TableTitle
          title={'Table 3'}
          description={'Overview of key outcome indicators for all countries reviewed'}
        />
        <Table03 />
      </div>
    </>
  ) 
}

export default Overview
