import { useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
// Import Pages
import Definitions from '../methods/Definitions'
import Methods2 from '../methods/Methods2'
import Limitations from '../methods/Limitations'
import Sources from '../methods/Sources'

// Component
const Methods = () => {

  const definitions = useRef();
  const methods = useRef();
  const limitations = useRef();
  const sources = useRef();

  const location = useLocation();

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   

  useEffect(() => {
    if(location.hash === '#limitations') {
      scrollToRef(limitations);
    }
    if(location.hash === '#methods') {
      scrollToRef(methods);
    }
    if(location.hash === '#data-sources') {
      scrollToRef(sources);
    }
  });

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="METHODS" />
      <article>
        <section id='definitions' ref={definitions} > 
          <Definitions />
        </section>
        <section id='methods' ref={methods} > 
          <Methods2 />
        </section>
        <section id='limitations' ref={limitations} > 
          <Limitations />
        </section>
        <section id='sources' ref={sources} > 
          <Sources />
        </section>
      </article>
    </div>
  ) 
}

export default Methods
