import PropTypes from 'prop-types'
// COMPONENT
const TitleButton = ({ showHandler, seeText, icon, description }) => {
  return (
    <button 
      onClick={ showHandler }
      className="mb-1 w-full"
    >
      <div className="
      ani03s flex cursor-pointer py-2 px-3 w-full
      bg-white hover:bg-main_blue 
      text-main_blue hover:text-white text-sm font-light
      border-2 border-solid border-main_blue
      focus:outline-none hover:outline-none
      outline-none">
        <span className={`${icon} pr-2 text-xl`}></span>
        <div className="text-justify">
            <span className="font-black">{ seeText }</span>
            { description }
        </div>
      </div>
    </button>
  )
}
// PROPTYPES
TitleButton.propTypes = {
  showHandler: PropTypes.func.isRequired,
  seeText: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
// EXPORT
export default TitleButton