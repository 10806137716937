import { useState } from 'react'
// Import Flag
import Flag from '../../images/kyrgyzstan.svg'
// Import Page Elements
import Table10_2 from '../../page_elements/tables/Table10_2'
import Table11_2 from '../../page_elements/tables/Table11_2'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TitleLink from '../../components/TitleLink'
import TableTitle from '../../components/TableTitle'
import Footnote from '../../components/Footnote'
// Component
const Kyrgyz = () => {

  const pageTitle = 'Kyrgyz Republic'
  const table1Desc = 'Status of the nutrition of school-aged children and adolescents in Kyrgyz Republic'
  const table2Desc = 'Policy and programming environment in Kyrgyz Republic'

  const [showText, setShowText] = useState(true);
  const [showTable1, setShowTable1] = useState(false);
  const [showTable2, setShowTable2] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
    setShowTable2(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowTable2(false)
    setShowText(false)
  }
  function showTable2Handler(e) {
    e.preventDefault();
    setShowTable2(!showTable2)
    setShowTable1(false)
    setShowText(false)
  }

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="COUNTRY PROFILES" />
      <article>
        <PageTitle title = { pageTitle }  flag = { Flag } flagAlt = { 'Kyrgyz Flag' }>
          <TitleLink 
            linkTo = { '/country-profiles' }
            seeText = { 'See Map: '}
            icon = {'icon-map-icon'}
            description = { 'Country Profiles' }
          />
          {!showText &&
            <TitleButton 
              showHandler = { showTextHandler }
              seeText = { 'See Text: '}
              icon = {'icon-text-icon'}
              description = { pageTitle }
            />
          }
          {!showTable1 &&
            <TitleButton 
              showHandler = { showTable1Handler }
              seeText = { 'See Table 10: '}
              icon = {'icon-tables-icon'}
              description = { table1Desc }
            />
          }
          {!showTable2 &&
            <TitleButton 
              showHandler = { showTable2Handler }
              seeText = { 'See Table 11: '}
              icon = {'icon-tables-icon'}
              description = { table2Desc }
            />
          }
        </PageTitle>
        {/* TEXT */}
        { showText && 
          <div className="article_content col2">
            <p>In Kyrgyz Republic, both stunting and thinness were low in girls aged 15-19 in the DHS of 2012 while the prevalence of overweight or obese girls aged 15-19 was 7.7 percent, which is low compared to other countries in the Region.</p>
            <p>Surveys using IOTF thresholds reported a higher prevalence of overweight and obesity in the 10-24-year age group: 13.1 percent in girls, while for boys the prevalence was almost three times higher, with a third of boys reported as overweight.</p>
            <p>Anaemia also affected around a third of boys and girls in the 10-24 year age group.</p>
            <p>The median urinary iodine concentration of school children aged 8-10 years in 2007 was 114 µg/L, which indicates an ‘adequate’ intake.</p>
            <p>A survey on Health and Behaviour Change in school-aged children (2017/18)<Footnote sup={'24'} text={'WHO 2019. Health Behaviour in school-aged children. Appendix 1: International standard of the HBSC 2017-2018 Mandatory questionnaire'}/> found that more girls considered themselves to be fat, whilst boys were more overweight than girls. Girls were more concerned with body image, ate more fruit, vegetables and sweets, skipped breakfast more often and were more engaged in weight-reducing behaviours. Boys were more physically active, drank more soft drinks and had less control over their diet.</p>
            <p>A cross-sectional survey on street food characteristics conducted in Bishkek in 2016<Footnote sup={'25'} text={'The FEED Cities Project by WHO and the Institute of Public Health, University of Porto 2017'}/>, reported excess caloric intake from sugary drinks, particularly for children, and recommended putting in place policies to restrict their availability, especially in the vicinity of schools.</p>
            <p>A 2015 survey on Knowledge, Attitudes and Practices on micronutrients<Footnote sup={'26'} text={'MOH 2015, with support of WFP, UNFPA and GIZ.'}/> in a sample of adolescents found that 12 percent of the girls aged 15-17 years old had been diagnosed with anaemia in the preceding three months, but only half of these had received information about causes or treatment. Just 12 percent of adolescents in the sample had received nutrition education in school and knowledge about micronutrients was particularly low. Two-thirds of the adolescents reported always drinking tea with a meal. Only 28 percent of adolescent girls were able to correctly name foods rich in iron, but consumed foods rich in iron frequently. Only a quarter of the adolescent girls in the sample were aware of the relationship between low iodine consumption and goitre. The media was found to be a major source of information on nutrition for adolescents, as well as school and family, whereas healthcare providers were the main source for pregnant and breastfeeding women. The authors suggest that health messages should be targeted beyond women of reproductive age to the wider public so that families can provide correct information and guidance to their children and support mothers to make good choices. Particular attention needs to be focused on improving the knowledge and dietary practices of adolescent girls. The report recommends the promotion of interventions targeting adolescents. These include integration of nutrition education in schools, development of simple and effective tools to be used by medical and non-medical personnel (e.g. teachers), which focus on building good nutrition practices, including the use of available food.</p>
            <h3 className="text-main_blue pb-3">Conclusion</h3>
            <p>The prevalence of overweight in girls aged 15-19 years was low compared to other countries in the region, but data looking at a wider age range of 10-24 years found higher prevalence alongside a very high prevalence of overweight in boys of one in three. The prevalence of anaemia was very high with almost a third of boys and girls affected. Awareness and knowledge on nutrition appears to be limited in this age group, which could be a contributor to poor diets and eating practices. The new Food Security and Nutrition Programme includes clear and measurable objectives to improve nutrition in this age group through enhancing access to supplementation with iron and folic acid for girls, improvement of school meals and mechanisms to improve knowledge of nutrition among school staff and students. This is supported by a package of training materials aimed at supporting community-focused efforts to improve nutrition and eating practices. These initiatives represent a good starting point from which to build cross-sector initiatives and a regulatory environment to address the nutrition challenges among school age children and adolescents in the Kyrgyz Republic. However, these could be further enhanced through greater inclusion of the food system (further enforcement of flour fortification and other targeted strategies to address anaemia) and consideration of regulatory measures to prevent marketing of unhealthy foods to children and adolescents alongside the promotion of healthy diets. The gender disparity in overweight would also merit better understanding, leading to tailored initiatives aimed at reducing both overweight and anaemia in boys as well as girls.</p>

          </div>
        }
        {/* TABLE */}
        { showTable1 && 
          <div className="article_content">
            <TableTitle
              title={'Table 10'}
              description={ table1Desc }
            />
            <Table10_2 />
          </div>
        }
        {/* TABLE */}
        { showTable2 && 
          <div className="article_content">
            <TableTitle
              title={'Table 11'}
              description={ table2Desc }
            />
            <Table11_2 />
          </div>
        }
      </article>
    </div>
  ) 
}
export default Kyrgyz
