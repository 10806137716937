const Table17_2 = () => {
  return (    
    <table className="allBorders table-auto w-full text-left border border-main_blue xs:text-xs">
      <thead>
        <tr className="text-white">
            <th className="bg-main_blue">Policy/programme document</th>
            <th className="bg-header1">Targets/indicators with specific mention of children aged 7-18 years</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Programmes /Strategies/ Plans</span></td>
        </tr>
        <tr>
          <td>Tajikistan School Meals Programme</td>
          <td>
            <p>World Food Programme (WFP) supports the government of Tajikistan to develop sustainable, nationally owned school meals, which includes policy dialogue and provision of technical assistance and pilot school feeding projects.</p>
            <p>WFP provides support with foodstuffs for school meals (fortified flour, vegetable oil, pulses, iodised salt). The Food and Agriculture Organisation (FAO) supports school gardening projects.</p>
            <p>WFP support reaches 50% of schools (1992 in total), for 452,013 pupils in grades 1-4 (ages 7-10).</p>
            <p>School gardening project targets 20 schools, providing greenhouses and irrigation systems, with the aim of diversifying school cafeteria menus (more fresh vegetables) and teaching children practical skills in vegetable growing and technology such as irrigation.</p>
          </td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Maternal, Infant and Child Nutrition in Rural Tajikistan, GIZ and MOHSPP (2016-2019)</td>
          <td>
            <p>A Project commissioned by German Federal Ministry for Economic Cooperation and Development (BMZ) implemented in Khatlon, districts of Temurmalik, Khovaling and Baljuvan with the Ministry of Health and Social Protection of the Population.</p>
            <p>The goal is to improve the nutritional situation of Women of Reproductive Age, infants and young children. It includes counselling in nutrition and basic hygiene in selected villages and schools.</p>
          </td>
        </tr>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Guidelines /Legislation</span></td>
        </tr>
        <tr>
          <td>Law on Food Fortification (2019)</td>
          <td>A new law has been passed by the Government of Tajikistan on the fortification of staple food products (including imports) in 2019.</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Law on School Meals (2015)</td>
          <td>In February 2015, the President of Tajikistan signed a law for the development of a national school feeding policy, establishing the framework for expanding the WFP-supported school feeding programme into a sustainable, country-owned programme with educational, social protection and nutritional objectives by 2021.</td>
        </tr>
        <tr>
          <td>Strategy for sustainable development of school meals (2017-2027)</td>
          <td>
            <p>To complement the national law on school feeding, the strategy was elaborated  by an inter-ministerial coordination council and approved in September 2017.</p>
            <p>There are 3 stages of implementation:</p>
            <p className="text-main_blue">First stage (2017-2018)</p>
            <ul className="bullets">
              <li>Development of legal and regulatory framework for the organization of school meals;</li>
              <li>Establishment of effective organizational and management infrastructure;</li>
              <li>Development of pilot projects to implement school meals models, determination of the conditions and cost of implementation;</li>
              <li>Human resource development.</li>
            </ul>
            <p className="text-main_blue">Second stage (2019-2020)</p>
            <ul className="bullets">
              <li>Implementation of school meals models based on pilot projects</li>
              <li>Selection of most promising school meal models and related solutions.</li>
            </ul>
            <p className="text-main_blue">Third stage (2021-2026)</p>
            <ul className="bullets">
              <li>Implementation of the activities of the National School Meals Programme;</li>
              <li>Extension of the school meals system to all regions of the Republic   of Tajikistan;</li>
              <li>Gradual transition to independent implementation of the school meals programs funded by the state budget and other sources.</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  ) 
}
export default Table17_2
