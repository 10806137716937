const Table05_2 = () => {
  return (    
    <table className="allBorders table-auto w-full text-left border border-main_blue xs:text-xs">
      <thead>
        <tr className="text-white">
            <th className="bg-main_blue">Policy/programme document</th>
            <th className="bg-header1">Targets/indicators with specific mention of children aged 7-18 years</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Policies / Strategies</span></td>
        </tr>
        <tr>
          <td>Draft Action Plan for healthy nutrition in the fight against non-communicable diseases in the Republic of Azerbaijan</td>
          <td>
            <p>This is a draft with 5 pillars and planned activities, but no targets, indicators or timeframe yet. Activities for this age group focus almost exclusively on schools and educational institutions.</p>
            <h3 className="text-main_blue pb-2">Under pillar 2:</h3>
            <p>Research and development on healthy nutrition practices among the population in Azerbaijan:</p>
            <ul className="bullets">
              <li>Conduct surveys among various strata of the population including school children – on nutrition practices and develop recommendations based on the findings</li>
            </ul>
            <h3 className="text-main_blue pb-2">Under pillar 3:</h3>
            <p>Taking necessary actions for healthy nutrition of students at education institutions:</p>
            <ul className="bullets">
              <li>Organising purposeful awareness raising meetings to teach healthy nutrition practices  at  secondary  schools,  lessons  on  healthy  nutrition,  taking  action     on increasing the availability of healthy foods in canteens and restriction / prohibition of selling unhealthy foods in schools</li>
              <li>Drafting methodological guidance on organisation of healthy nutrition at secondary schools, vocational educational facilities and universities.</li>
              <li>Draft teaching aids and clinical guidelines for teachers, parents and medical personnel to teach healthy nutrition practices to students</li>
              <li>Ensure children from disadvantaged households have breakfast and dinner in school</li>
              <li>Application of norms and standards for school meals</li>
            </ul>
            <p>Incorporating healthy nutrition issues into various curriculums:</p>
            <ul className="bullets">
              <li>Amendments to technical vocational and higher education curriculums to train healthy nutrition and food experts among physicians and mid-level medical personnel</li>
              <li>Draft guidance on healthy nutrition and physical activity for professionals in Primary Health Care (PHC)</li>
              <li>Additions / amendments to school curriculum in chef / vocational schools etc.</li>
              <li>Draft training materials on healthy nutrition principles</li>
              <li>Draft training package on food safety and health nutrition for professionals in PHC</li>
              <li>Draft training package on healthy nutrition for non-health care professional (e.g. politicians, teachers, social workers) – include e-training, workshops etc.</li>
            </ul>
            <h3 className="text-main_blue pb-2">Under pillar 5, M & E:</h3>
            <p>Conduct regular surveys among the population and school pupils in accordance with international standards e.g. STEP, HBSC</p>
          </td>
        </tr>
        <tr className="bg-pale_blue">
          <td>National Strategy on NCD Prevention and control in Azerbaijan 2013-2020 and Action Plan</td>
          <td>
            <p>National action-oriented policy document for the prevention and control of NCDs  in Azerbaijan, incorporating a Common Strategic Framework and  guidance.  Its goal is to reduce premature mortality from NCDs by 15% by 2020.</p>
            <h3 className="text-main_blue pb-2">Under Objective 2 of the strategy:</h3>
            <ul className="bullets">
              <li>Develop a national policy to reduce the impact on children of marketing of foods high in saturated fats, trans-fatty acids, free sugars or salt</li>
              <li>Increase public awareness of NCDs and associated risk factors – including among children</li>
              <li>Promote healthy nutrition starting from early childhood</li>
              <li>Develop a school nutrition programme including education curricula for secondary schools</li>
              <li><p>Enhance human resource capacity to strengthen prevention of NCD risk factors</p>
              <p>– including development of a training package for non-health professionals (policy makers, teachers, social workers) - indicator = proportion of teachers at secondary schools providing quality information on prevention of NCD risk factors</p></li>
              <li>Integrate promotion of healthy lifestyles into school education curriculum and develop resource packages – in line with WHO recommendations on NCD risk factors</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Guidelines /Legislation</span></td>
        </tr>
        <tr>
          <td>Rules and norms of arranging catering and health  services in the educational institutions. Cabinet of Ministers, 2010.</td>
          <td>
            <p>General provisions, arrangements for catering services, arrangements for health services, main duties of health workers providing services to educational institutions.</p>
            <p>Concerns hygiene standards, norms for school meal provision and for provision of health services within schools</p>
          </td>
        </tr>
      </tbody>
    </table>
  ) 
}
export default Table05_2
