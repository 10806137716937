import { useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
// Import Pages
import Conclusions1 from '../conclusions/Conclusions1'
import Conclusions2 from '../conclusions/Conclusions2'

// Component
const Conclusions = () => {

  const conclusions1 = useRef();
  const conclusions2 = useRef();

  const location = useLocation();

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   

  useEffect(() => {
    if(location.hash === '#vol2-conclusions') {
      scrollToRef(conclusions2);
    }
  });

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="CONCLUSIONS" />
      <article>
        <section id='conclusions1' ref={conclusions1} > 
          <Conclusions1 />
        </section>
        <section id='conclusions2' ref={conclusions2} > 
          <Conclusions2 />
        </section>
      </article>
    </div>
  ) 
}

export default Conclusions
