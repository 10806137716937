import { useRef, useEffect } from "react"
import { useLocation } from 'react-router-dom'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
// Import Pages
import Overview from '../findings/Overview'
import Overweight from '../findings/Overweight'
import Stunting from '../findings/Stunting'
import Micronutrient from '../findings/Micronutrient'
import Dietary from '../findings/Dietary'
import Programme from '../findings/Programme'

// Component
const Findings = () => {

  const overview = useRef();
  const overweight = useRef();
  const stunting = useRef();
  const micronutrient = useRef();
  const dietary = useRef();
  const programme = useRef();

  const location = useLocation();

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   

  useEffect(() => {
    if(location.hash === '#overweight-or-obesity') {
      scrollToRef(overweight);
    }
    if(location.hash === '#stunting') {
      scrollToRef(stunting);
    }
    if(location.hash === '#micronutrient-status') {
      scrollToRef(micronutrient);
    }
    if(location.hash === '#dietary-habits-and-physical-exercise') {
      scrollToRef(dietary);
    }
    if(location.hash === '#programme-and-policy-environment') {
      scrollToRef(programme);
    }
  });

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="FINDINGS" />
      <article>
        <section id='overview' ref={overview} > 
          <Overview />
        </section>
        <section id='overweight' ref={overweight} > 
          <Overweight />
        </section>
        <section id='stunting' ref={stunting} > 
          <Stunting />
        </section>
        <section id='micronutrient' ref={micronutrient} > 
          <Micronutrient />
        </section>
        <section id='dietary' ref={dietary} > 
          <Dietary />
        </section>
        <section id='programme' ref={programme} > 
          <Programme />
        </section>
      </article>
    </div>
  ) 
}

export default Findings
