import { useState } from 'react'
// Import Images
import Figure03 from '../../images/fig03.svg'
import Figure04 from '../../images/fig04.svg'
// Import Components
import PageTitle from '../../components/PageTitle'
// Component
import TitleButton from '../../components/TitleButton'
import TableTitle from '../../components/TableTitle'
const Dietary = () => {

  const pageTitle = 'Dietary habits and physical exercise'
  const figureDesc = 'Summary data for boys / girls from HBSC surveys 2014 (for Turkey 2010)'
  const figure3Desc = 'Summary data for girls from HBSC surveys 2014 (for Turkey 2010)'
  const figure4Desc = 'Summary data for boys from HBSC surveys 2014 (for Turkey 2010)'

  const [showText, setShowText] = useState(true);
  const [showFigure, setShowFigure] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowFigure(false)
  }
  function showFigureHandler(e) {
    e.preventDefault();
    setShowFigure(!showFigure)
    setShowText(false)
  }

  return (
    <>
      <PageTitle title = { pageTitle } >
        {!showText &&
          <TitleButton 
            showHandler = { showTextHandler }
            seeText = { 'See Text: '}
            icon = {'icon-text-icon'}
            description = { pageTitle }
          />
        }
        {!showFigure &&
          <TitleButton 
            showHandler = { showFigureHandler }
            seeText = { 'See Figure 3 / 4: '}
            icon = {'icon-chart-icon'}
            description = { figureDesc }
          />
        }
      </PageTitle>
      {/* TEXT */}
      { showText && 
        <div className="article_content col2">
          <p>Overall levels of physical exercise and daily fruit and vegetable consumption are low for many countries. Less than 25% of girls and less than 35% of boys are achieving the minimum recommended levels of exercise in most countries and in all countries fruit and vegetables are consumed daily by less than 50% of the population sampled. Physical exercise has increased across all four countries and in most countries by more than 5% between surveys. There are also some encouraging signs of reductions in daily sweets and soft drink consumption and some increasing daily vegetable consumption in Romania and North Macedonia. Ukraine and Armenia stand out as having particularly high levels of both fruit and vegetable consumption in the group surveyed, how and why these changes came about and any actions that could be mirrored by other countries in the region. Very broadly boys appear to eat less vegetables than girls and drink more soft drinks, which is noted in some of the literature.</p>
          <p>Breakfast consumption patterns:</p>
          <ul className="bullets">
            <li>Trends of daily breakfast consumption decreased for both boys and girls in all countries between 2002 and 2010, except in North Macedonia where there was an increase both for boys and girls.</li>
            <li>One study reported that television adverts had an important influence on the types of foods adolescents chose in Romania. In a sample of adolescent girls, most of them reported seeing unhealthy food adverts on television (66.0 percent), while only 48.0 percent saw healthy adverts.</li>
            <li>Those from urban areas reported less healthy eating behaviour, with higher consumption of soft drinks and snacks than those living in rural areas. Urban areas also reported higher levels of eating awareness compared with rural areas. However, study participants from rural areas reported eating more vegetables</li>
          </ul>
          <p className="bg-main_blue p-4 m-8 text-white text-sm">One school based intervention involving a small number (139) of 12 to 13 year olds in Croatia attempted to control eating disorder attitudes in adolescents (Pokrajac-Bulian, Zivcić-Becirević, Calugi, & Dalle Grave, 2006). Using education and practical sessions (2 hours per week), the intervention significantly reduced eating disorder attitudes and dieting behaviour in females. In males, there was also a significant and positive effect on eating disorders attitude and knowledge, but not on dietary habits. This school based pre and post intervention study shows some evidence of effect on dietary habits of female adolescents in a school intervention. However, larger trials are needed to provide more robust evidence.</p>
        </div>
      }
      {/* FIGURE */}
      { showFigure && 
        <div className="article_content flex flex-col">

          <TableTitle
            title={'Figure 3'}
            description={ figure3Desc }
          />
          <div className="w-full mb-6">
            <img className="w-full h-auto" src={Figure03} alt={figure3Desc} />
          </div>
          <TableTitle
            title={'Figure 4'}
            description={ figure4Desc }
          />
          <div className="w-full">
            <img className="w-full h-auto" src={Figure04} alt={figure4Desc} />
          </div>

        </div>
      } 
    </>
  ) 
}
// Export
export default Dietary
