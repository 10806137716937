import { useState } from 'react'
// Import Image
import Image from '../../images/methods_3.jpg'
// Import Components
import CoverImg from '../../components/CoverImg'
import TableTitle from '../../components/TableTitle'
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
// Import Page Elements
import Table02 from '../../page_elements/tables/Table02'
// Component
const Lifecycle = () => {

  const pageTitle = 'Data sources'
  const tableDesc = 'Literature review search results by country'
    
  const [showText, setShowText] = useState(true);
  const [showTable, setShowTable] = useState(false);
  
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable(false)
  }
  function showTableHandler(e) {
    e.preventDefault();
    setShowTable(!showTable)
    setShowText(false)
  }

  return (
    <>
      <PageTitle title = { pageTitle } >
        {!showText &&
          <TitleButton 
            showHandler = { showTextHandler }
            seeText={ 'See Text: '}
            icon={'icon-text-icon'}
            description={ pageTitle }
          />
        }
        {!showTable &&
          <TitleButton 
            showHandler = { showTableHandler }
            seeText={ 'See Table 2: '}
            icon={'icon-tables-icon'}
            description={ tableDesc }
          />
        }    
      </PageTitle>
      { showTable && 
        <div className="article_content">
          <div className="mx-auto max-w-700">
            <TableTitle
              title={'Table 2'}
              description={'Literature review search results by country'}
            />
            <Table02 />
          </div>
        </div>
      }
      { showText && 
        <>
          <CoverImg SrcImg = { Image } />
          <div className="article_content col2">
          <p>One hundred and thirty-four full texts were found to be eligible for inclusion based on our PICO framework (see Table 2 for breakdown of results by Country).</p>
          <ul className="list-disc">
            <li className="ml-6">Systematic review of national/international surveys</li>
            <li className="ml-6">Institute of Health Metrics and Evaluation</li>
            <li className="ml-6">Global Burden of Disease, 2013</li>
            <li className="ml-6">Demographic Health Surveys</li>
            <li className="ml-6">Childhood Obesity Surveillance Initiative – WHO European Region</li>
            <li className="ml-6">Health Behaviour in School Age Children (HBSC)</li>
            <li className="ml-6">Iodine Global Scorecard 2019</li>
          </ul>
        </div>
        </>
      }
    </>
  ) 
}

export default Lifecycle
