import React, { useState } from 'react';
import { useTable, useSortBy } from 'react-table'
import BaseRow from '../../components/BaseRow'
// Component
const Table03 = () => {

  const [showAlbania, setShowAlbania] = useState(true);
  const handleClickAlbania = () => setShowAlbania(!showAlbania)

  const [showArmenia, setShowArmenia] = useState(true);
  const handleClickArmenia = () => setShowArmenia(!showArmenia)

  const [showAzerbaijan, setShowAzerbaijan] = useState(true);
  const handleClickAzerbaijan = () => setShowAzerbaijan(!showAzerbaijan)

  const [showBelarus, setShowBelarus] = useState(true);
  const handleClickBelarus = () => setShowBelarus(!showBelarus)

  const [showBosnia, setShowBosnia] = useState(true);
  const handleClickBosnia = () => setShowBosnia(!showBosnia)

  const [showBulgaria, setShowBulgaria] = useState(true);
  const handleClickBulgaria = () => setShowBulgaria(!showBulgaria)

  const [showCroatia, setShowCroatia] = useState(true);
  const handleClickCroatia = () => setShowCroatia(!showCroatia)

  const [showGeorgia, setShowGeorgia] = useState(true);
  const handleClickGeorgia = () => setShowGeorgia(!showGeorgia)

  const [showKazakhstan, setShowKazakhstan] = useState(true);
  const handleClickKazakhstan = () => setShowKazakhstan(!showKazakhstan)

  const [showKyrgyztan, setShowKyrgyztan] = useState(true);
  const handleClickKyrgyztan = () => setShowKyrgyztan(!showKyrgyztan)

  const [showMacedonia, setShowMacedonia] = useState(true);
  const handleClickMacedonia = () => setShowMacedonia(!showMacedonia)

  const [showMoldava, setShowMoldava] = useState(true);
  const handleClickMoldava = () => setShowMoldava(!showMoldava)

  const [showMontenegro, setShowMontenegro] = useState(true);
  const handleClickMontenegro = () => setShowMontenegro(!showMontenegro)

  const [showRomania, setShowRomania] = useState(true);
  const handleClickRomania = () => setShowRomania(!showRomania)

  const [showServia, setShowServia] = useState(true);
  const handleClickServia = () => setShowServia(!showServia)

  const [showTajikistan, setShowTajikistan] = useState(true);
  const handleClickTajikistan = () => setShowTajikistan(!showTajikistan)

  const [showTurkey, setShowTurkey] = useState(true);
  const handleClickTurkey = () => setShowTurkey(!showTurkey)

  const [showTurkmenistan, setShowTurkmenistan] = useState(true);
  const handleClickTurkmenistan = () => setShowTurkmenistan(!showTurkmenistan)

  const [showUkraine, setShowUkraine] = useState(true);
  const handleClickUkraine = () => setShowUkraine(!showUkraine)

  const [showUzbekistan, setShowUzbekistan] = useState(true);
  const handleClickUzbekistan = () => setShowUzbekistan(!showUzbekistan)

  const [showCountries, setShowCountries] = useState(false);

  const data = React.useMemo( () => [
      { 
        country: 'Albania',
        female1: 19.0, cf1: 'transp', 
        male1: 28.2, cm1: 'orange',
        female2: 15.5, cf2: 'transp', 
        male2: 13.7, cm2: 'transp',
        female3: 56.6, cf3: 'transp',
        male3: 44.4, cm3: 'transp',
        female4: 41.4, cf4: 'transp', 
        male4: 34.9, cm4: 'orange',
        female5: 37.1, cf5: 'orange', 
        male5: 34.5, cm5: 'orange',
        female6: 24.5, cf6: 'transp',
        male6: 30.9, cm6: 'orange',
        female7: 21.7, cf7: 'orange',
        male7: 34.9, cm7: 'transp',
      },
      { 
        country: 'Armenia',
        female1: 20.8, cf1: 'yellow', 
        male1: 22.5, cm1: 'yellow',
        female2: 13.5, cf2: 'transp', 
        male2: 19.3, cm2: 'transp',
        female3: 62.4, cf3: 'transp',
        male3: 51.8, cm3: 'transp',
        female4: 62.4, cf4: 'transp', 
        male4: 51.8, cm4: 'transp',
        female5: 56.3, cf5: 'orange', 
        male5: 49.5, cm5: 'orange',
        female6: 21.9, cf6: 'transp',
        male6: 23.2, cm6: 'transp',
        female7: 17.9, cf7: 'orange',
        male7: 27.7, cm7: 'transp',
      },
      { 
        country: 'Azerbaijan',
        female1: 24.7, cf1: 'yellow', 
        male1: 22.7, cm1: 'yellow',
        female2: 28.4, cf2: 'orange', 
        male2: 20.7, cm2: 'orange',
        female3: '-', cf3: 'transp',
        male3: '-', cm3: 'transp',
        female4: '-', cf4: 'transp', 
        male4: '-', cm4: 'transp',
        female5: '-', cf5: 'transp', 
        male5: '-', cm5: 'transp',
        female6: '-', cf6: 'transp',
        male6: '-', cm6: 'transp',
        female7: '-', cf7: 'transp',
        male7: '-', cm7: 'transp',
      },
      { 
        country: 'Belarus',
        female1: 11.6, cf1: 'transp', 
        male1: 21.5, cm1: 'yellow',
        female2: 10.6, cf2: 'transp', 
        male2: 8.1, cm2: 'transp',
        female3: '-', cf3: 'transp',
        male3: '-', cm3: 'transp',
        female4: '-', cf4: 'transp', 
        male4: '-', cm4: 'transp',
        female5: '-', cf5: 'transp', 
        male5: '-', cm5: 'transp',
        female6: '-', cf6: 'transp',
        male6: '-', cm6: 'transp',
        female7: '-', cf7: 'transp',
        male7: '-', cm7: 'transp',
      },
      { 
        country: 'Bosnia & Herzegovina',
        female1: 17.7, cf1: 'transp', 
        male1: 22.5, cm1: 'yellow',
        female2: 15, cf2: 'transp', 
        male2: 18.5, cm2: 'transp',
        female3: '-', cf3: 'transp',
        male3: '-', cm3: 'transp',
        female4: '-', cf4: 'transp', 
        male4: '-', cm4: 'transp',
        female5: '-', cf5: 'transp', 
        male5: '-', cm5: 'transp',
        female6: '-', cf6: 'transp',
        male6: '-', cm6: 'transp',
        female7: '-', cf7: 'transp',
        male7: '-', cm7: 'transp',
      },
      { 
        country: 'Bulgaria',
        female1: 23.2, cf1: 'yellow', 
        male1: 27.6, cm1: 'orange',
        female2: 13.5, cf2: 'transp', 
        male2: 15.6, cm2: 'transp',
        female3: 38.2, cf3: 'orange',
        male3: 35.4, cm3: 'orange',
        female4: 46.3, cf4: 'transp', 
        male4: 36.4, cm4: 'orange',
        female5: 46.9, cf5: 'orange', 
        male5: 49.5, cm5: 'orange',
        female6: 31.4, cf6: 'orange',
        male6: 37.5, cm6: 'orange',
        female7: 22.4, cf7: 'orange',
        male7: 34.2, cm7: 'transp',
      },
      { 
        country: 'Croatia',
        female1: 20.9, cf1: 'yellow', 
        male1: 30.3, cm1: 'orange',
        female2: 7.2, cf2: 'transp', 
        male2: 11.6, cm2: 'transp',
        female3: 37.9, cf3: 'orange',
        male3: 35.2, cm3: 'orange',
        female4: 30.4, cf4: 'orange', 
        male4: 27.1, cm4: 'orange',
        female5: 33.5, cf5: 'orange', 
        male5: 27.9, cm5: 'orange',
        female6: 21.6, cf6: 'transp',
        male6: 25.0, cm6: 'transp',
        female7: 19.0, cf7: 'orange',
        male7: 32.5, cm7: 'transp',
      },
      { 
        country: 'Georgia',
        female1: 20.8, cf1: 'yellow', 
        male1: 33.6, cm1: 'orange',
        female2: 19.6, cf2: 'transp', 
        male2: 21.4, cm2: 'orange',
        female3: '-', cf3: 'transp',
        male3: '-', cm3: 'transp',
        female4: '-', cf4: 'transp', 
        male4: '-', cm4: 'transp',
        female5: '-', cf5: 'transp', 
        male5: '-', cm5: 'transp',
        female6: '-', cf6: 'transp',
        male6: '-', cm6: 'transp',
        female7: '-', cf7: 'transp',
        male7: '-', cm7: 'transp',
      },
      {
        country: 'Kazakhstan',
        female1: 15.3, cf1: 'transp', 
        male1: 17.4, cm1: 'transp',
        female2: 31.2, cf2: 'orange', 
        male2: 27.8, cm2: 'orange',
        female3: '-', cf3: 'transp',
        male3: '-', cm3: 'transp',
        female4: '-', cf4: 'transp', 
        male4: '-', cm4: 'transp',
        female5: '-', cf5: 'transp', 
        male5: '-', cm5: 'transp',
        female6: '-', cf6: 'transp',
        male6: '-', cm6: 'transp',
        female7: '-', cf7: 'transp',
        male7: '-', cm7: 'transp',
      },
      { 
        country: 'Kyrgyztan',
        female1: 13.1, cf1: 'transp', 
        male1: 11.6, cm1: 'transp',
        female2: 32.4, cf2: 'orange', 
        male2: 31.4, cm2: 'orange',
        female3: '-', cf3: 'transp',
        male3: '-', cm3: 'transp',
        female4: '-', cf4: 'transp', 
        male4: '-', cm4: 'transp',
        female5: '-', cf5: 'transp', 
        male5: '-', cm5: 'transp',
        female6: '-', cf6: 'transp',
        male6: '-', cm6: 'transp',
        female7: '-', cf7: 'transp',
        male7: '-', cm7: 'transp',
      },
      {
        country: 'North Macedonia',
        female1: 19.5, cf1: 'transp', 
        male1: 29.3, cm1: 'orange',
        female2: 15.5, cf2: 'transp', 
        male2: 19.2, cm2: 'transp',
        female3: 44.8, cf3: 'transp',
        male3: 34.9, cm3: 'orange',
        female4: 46.7, cf4: 'transp', 
        male4: 35.4, cm4: 'orange',
        female5: 38.3, cf5: 'orange', 
        male5: 32.3, cm5: 'orange',
        female6: 28.6, cf6: 'orange',
        male6: 31.7, cm6: 'orange',
        female7: 22.2, cf7: 'orange',
        male7: 31.9, cm7: 'transp',
      },
      {
        country: 'Moldava',
        female1: 17.1, cf1: 'transp', 
        male1: 25, cm1: 'orange',
        female2: 19.6, cf2: 'transp', 
        male2: 17.4, cm2: 'transp',
        female3: 37.3, cf3: 'orange',
        male3: 34.3, cm3: 'orange',
        female4: 37.3, cf4: 'orange', 
        male4: 37.4, cm4: 'orange',
        female5: 32.9, cf5: 'orange', 
        male5: 27.6, cm5: 'orange',
        female6: 9.7, cf6: 'transp',
        male6: 11.9, cm6: 'transp',
        female7: 25.2, cf7: 'transp',
        male7: 26.9, cm7: 'transp',
      },
      {
        country: 'Montenegro',
        female1: 22.9, cf1: 'yellow', 
        male1: 32.1, cm1: 'orange',
        female2: 12.5, cf2: 'transp', 
        male2: 15.1, cm2: 'transp',
        female3: '-', cf3: 'transp',
        male3: '-', cm3: 'transp',
        female4: '-', cf4: 'transp', 
        male4: '-', cm4: 'transp',
        female5: '-', cf5: 'transp', 
        male5: '-', cm5: 'transp',
        female6: '-', cf6: 'transp',
        male6: '-', cm6: 'transp',
        female7: '-', cf7: 'transp',
        male7: '-', cm7: 'transp',
      },
      {
        country: 'Romania',
        female1: 19.4, cf1: 'transp', 
        male1: 22.9, cm1: 'yellow',
        female2: 20.3, cf2: 'orange', 
        male2: 19.9, cm2: 'transp',
        female3: 41.6, cf3: 'transp',
        male3: 34.6, cm3: 'orange',
        female4: 36.8, cf4: 'orange', 
        male4: 31.9, cm4: 'orange',
        female5: 41.9, cf5: 'orange', 
        male5: 35.5, cm5: 'orange',
        female6: 23.1, cf6: 'transp',
        male6: 28.4, cm6: 'orange',
        female7: 16.5, cf7: 'orange',
        male7: 29.4, cm7: 'transp',
      },
      {
        country: 'Servia',
        female1: 20.9, cf1: 'yellow', 
        male1: 22.7, cm1: 'yellow',
        female2: 14.2, cf2: 'transp', 
        male2: 16.6, cm2: 'transp',
        female3: '-', cf3: 'transp',
        male3: '-', cm3: 'transp',
        female4: '-', cf4: 'transp', 
        male4: '-', cm4: 'transp',
        female5: '-', cf5: 'transp', 
        male5: '-', cm5: 'transp',
        female6: '-', cf6: 'transp',
        male6: '-', cm6: 'transp',
        female7: '-', cf7: 'transp',
        male7: '-', cm7: 'transp',
      },
      {
        country: 'Tajikistan',
        female1: 11.7, cf1: 'transp', 
        male1: 18.5, cm1: 'transp',
        female2: 25.8, cf2: 'orange', 
        male2: 21.3, cm2: 'orange',
        female3: '-', cf3: 'transp',
        male3: '-', cm3: 'transp',
        female4: '-', cf4: 'transp', 
        male4: '-', cm4: 'transp',
        female5: '-', cf5: 'transp', 
        male5: '-', cm5: 'transp',
        female6: '-', cf6: 'transp',
        male6: '-', cm6: 'transp',
        female7: '-', cf7: 'transp',
        male7: '-', cm7: 'transp',
      },
      {
        country: 'Turkey',
        female1: 25.5, cf1: 'orange', 
        male1: 28, cm1: 'orange',
        female2: 20.1, cf2: 'orange', 
        male2: 13.9, cm2: 'transp',
        female3: 40.9, cf3: 'transp',
        male3: 31.5, cm3: 'orange',
        female4: 40.9, cf4: 'transp', 
        male4: 24.6, cm4: 'orange',
        female5: 24.4, cf5: 'orange', 
        male5: 19.4, cm5: 'transp',
        female6: 16.7, cf6: 'transp',
        male6: 20.7, cm6: 'transp',
        female7: 13.2, cf7: 'orange',
        male7: 22.3, cm7: 'orange',
      },
      {
        country: 'Turkmenistan',
        female1: 27.5, cf1: 'orange', 
        male1: 23.8, cm1: 'yellow',
        female2: 21.2, cf2: 'orange', 
        male2: 18.8, cm2: 'transp',
        female3: '-', cf3: 'transp',
        male3: '-', cm3: 'transp',
        female4: '-', cf4: 'transp', 
        male4: '-', cm4: 'transp',
        female5: '-', cf5: 'transp', 
        male5: '-', cm5: 'transp',
        female6: '-', cf6: 'transp',
        male6: '-', cm6: 'transp',
        female7: '-', cf7: 'transp',
        male7: '-', cm7: 'transp',
      },
      {
        country: 'Ukraine',
        female1: 15.7, cf1: 'transp', 
        male1: 20.5, cm1: 'yellow',
        female2: 7.5, cf2: 'transp', 
        male2: 5.2, cm2: 'transp',
        female3: 52.9, cf3: 'transp',
        male3: 41.8, cm3: 'transp',
        female4: 58.3, cf4: 'transp', 
        male4: 48.3, cm4: 'transp',
        female5: 42.8, cf5: 'orange', 
        male5: 35.5, cm5: 'orange',
        female6: 12.2, cf6: 'transp',
        male6: 16.1, cm6: 'transp',
        female7: 23.1, cf7: 'orange',
        male7: 30.4, cm7: 'transp',
      },
      {
        country: 'Uzbekistan',
        female1: 16, cf1: 'orange', 
        male1: 26.3, cm1: 'orange',
        female2: 43.3, cf2: 'orange', 
        male2: 33.6, cm2: 'orange',
        female3: '-', cf3: 'transp',
        male3: '-', cm3: 'transp',
        female4: '-', cf4: 'transp', 
        male4: '-', cm4: 'transp',
        female5: '-', cf5: 'transp', 
        male5: '-', cm5: 'transp',
        female6: '-', cf6: 'transp',
        male6: '-', cm6: 'transp',
        female7: '-', cf7: 'transp',
        male7: '-', cm7: 'transp',
      },
    ],
    []
  )

  const columns = React.useMemo(
    () => [
      { Header: 'Country', Footer: 'Country', accessor: 'country'},
      { 
        Header: 'Overweight\nand Obesity % *',
        columns: [
          { 
            Header: 'Girls',
            Footer: 'female1',
            accessor: 'female1',
            Cell: props => props.value.toFixed(1)
          },
          { 
            Header: 'Boys',
            Footer: 'male1',
            accessor: 'male1',
            Cell: props => props.value.toFixed(1)
          },
        ]
      },
      { 
        Header: 'Anaemia % *',
        columns: [
          { 
            Header: 'Girls',
            Footer: 'female2',
            accessor: 'female2',
            Cell: props => props.value.toFixed(1)
          },
          { 
            Header: 'Boys',
            Footer: 'male2',
            accessor: 'male2',
            Cell: props => props.value.toFixed(1)
          },
        ]
      },
      { 
        Header: 'Daily fruit\nconsumption (%) **',
        columns: [
          { 
            Header: 'Girls',
            Footer: 'female3',
            accessor: 'female3'
          },
          { 
            Header: 'Boys',
            Footer: 'male3',
            accessor: 'male3'
          },
        ]
      },
      { 
        Header: 'Daily vegetable\nconsumption (%) **',
        columns: [
          { 
            Header: 'Girls',
            Footer: 'female4',
            accessor: 'female4'
          },
          { 
            Header: 'Boys', 
            Footer: 'male4', 
            accessor: 'male4'
          },
        ]
      },
      { 
        Header: 'Daily sweets\nconsumption (%) **',
        columns: [
          { 
            Header: 'Girls',
            Footer: 'female5',
            accessor: 'female5'
          },
          { 
            Header: 'Boys',
            Footer: 'male5',
            accessor: 'male5'
          },
        ]
      },
      { 
        Header: 'Daily soft drink\nconsumption (%) **',
        columns: [
          { 
            Header: 'Girls',
            Footer: 'female6',
            accessor: 'female6'
          },
          { 
            Header: 'Boys', 
            Footer: 'male6', 
            accessor: 'male6'
          },
        ]
      },
      { 
        Header: 'Moderate exercise\n(60 minutes or more daily) (%) **',
        columns: [
          { 
            Header: 'Girls',
            Footer: 'female7',
            accessor: 'female7'
          },
          { 
            Header: 'Boys',
            Footer: 'male7',
            accessor: 'male7'
          },
        ]
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    allColumns,
  } = useTable({ columns, data }, useSortBy)

  const checkItems = React.useMemo( () => [
    { 
      country: 'Albania',
      classItem: 'albania',
      showItem: showAlbania,
      handleItem: handleClickAlbania, 
    },
    { 
      country: 'Armenia',
      classItem: 'armenia',
      showItem: showArmenia,
      handleItem: handleClickArmenia,
    },
    { 
      country: 'Azerbaijan',
      classItem: 'azerbaijan',
      showItem: showAzerbaijan,
      handleItem: handleClickAzerbaijan,
    },
    { 
      country: 'Belarus',
      classItem: 'belarus',
      showItem: showBelarus,
      handleItem: handleClickBelarus,
    },
    { 
      country: 'Bosnia & Herzegovina',
      classItem: 'bosnia',
      showItem: showBosnia,
      handleItem: handleClickBosnia,
    },
    { 
      country: 'Bulgaria',
      classItem: 'bulgaria',
      showItem: showBulgaria,
      handleItem: handleClickBulgaria,
    },
    { 
      country: 'Croatia',
      classItem: 'croatia',
      showItem: showCroatia,
      handleItem: handleClickCroatia,
    },
    { 
      country: 'Georgia',
      classItem: 'georgia',
      showItem: showGeorgia,
      handleItem: handleClickGeorgia,
    },
    {
      country: 'Kazakhstan',
      classItem: 'kazakhstan',
      showItem: showKazakhstan,
      handleItem: handleClickKazakhstan,
    },
    { 
      country: 'Kyrgyztan',
      classItem: 'kyrgyztan',
      showItem: showKyrgyztan,
      handleItem: handleClickKyrgyztan,
    },
    {
      country: 'North Macedonia',
      classItem: 'macedonia',
      showItem: showMacedonia,
      handleItem: handleClickMacedonia,
    },
    {
      country: 'Moldava',
      classItem: 'moldava',
      showItem: showMoldava,
      handleItem: handleClickMoldava,
    },
    {
      country: 'Montenegro',
      classItem: 'montenegro',
      showItem: showMontenegro,
      handleItem: handleClickMontenegro,
    },
    {
      country: 'Romania',
      classItem: 'romania',
      showItem: showRomania,
      handleItem: handleClickRomania,
    },
    {
      country: 'Servia',
      classItem: 'servia',
      showItem: showServia,
      handleItem: handleClickServia,
    },
    {
      country: 'Tajikistan',
      classItem: 'tajikistan',
      showItem: showTajikistan,
      handleItem: handleClickTajikistan,
    },
    {
      country: 'Turkey',
      classItem: 'turkey',
      showItem: showTurkey,
      handleItem: handleClickTurkey,
    },
    {
      country: 'Turkmenistan',
      classItem: 'turkmenistan',
      showItem: showTurkmenistan,
      handleItem: handleClickTurkmenistan,
    },
    {
      country: 'Ukraine',
      classItem: 'ukraine',
      showItem: showUkraine,
      handleItem: handleClickUkraine,
    },
    {
      country: 'Uzbekistan',
      classItem: 'uzbekistan',
      showItem: showUzbekistan,
      handleItem: handleClickUzbekistan,
    },
  ])

  const [showOverweight, setShowOverweight] = useState(true);
  const [showAnaemia, setShowAnaemia] = useState(true);
  const [showFruit, setShowFruit] = useState(true);
  const [showVegetable, setShowVegetable] = useState(true);
  const [showSweet, setShowSweet] = useState(true);
  const [showDrink, setShowDrink] = useState(true);
  const [showExercise, setShowExercise] = useState(true);

  const [showFemale, setShowFemale] = useState(true);
  const [showMale, setShowMale] = useState(true);
  
  // Set Handle Overweight
  const handleClickOverweightT = () => {
    setShowOverweight(true)
    allColumns.map(column => {
      if (column.render('Footer') === 'female1') {
        if (showFemale) {
          column.toggleHidden(false)
        } 
      }
      if (column.render('Footer') === 'male1') {
        if (showMale) {
          column.toggleHidden(false)
        } 
      }
    })
  }
  const handleClickOverweightF = () => {
    setShowOverweight(false)
    allColumns.map(column => {
      if (column.render('Footer') === 'female1') {
        if (showFemale) {
          column.toggleHidden(true)
        } 
      }
      if (column.render('Footer') === 'male1') {
        if (showMale) {
          column.toggleHidden(true)
        } 
      }
    })
  }
  // Set Handle Anaemia
  const handleClickAnaemiaT = () => {
    setShowAnaemia(true)
    allColumns.map(column => {
      if (column.render('Footer') === 'female2') {
        if (showFemale) {
          console.log('asdasdasdahsjkhdjkhajkshjkdhjkahjshdjk')
          column.toggleHidden(false)
        } 
      }
      if (column.render('Footer') === 'male2') {
        if (showMale) {
          column.toggleHidden(false)
        } 
      }
    })
  }
  const handleClickAnaemiaF = () => {
    setShowAnaemia(false)
    allColumns.map(column => {
      if (column.render('Footer') === 'female2') {
        if (showFemale) {
          column.toggleHidden(true)
        } 
      }
      if (column.render('Footer') === 'male2') {
        if (showMale) {
          column.toggleHidden(true)
        } 
      }
    })
  }
  // Set Handle Fruit
  const handleClickFruitT = () => {
    setShowFruit(true)
    allColumns.map(column => {
      if (column.render('Footer') === 'female3') {
        if (showFemale) {
          column.toggleHidden(false)
        } 
      }
      if (column.render('Footer') === 'male3') {
        if (showMale) {
          column.toggleHidden(false)
        } 
      }
    })
  }
  const handleClickFruitF = () => {
    setShowFruit(false)
    allColumns.map(column => {
      if (column.render('Footer') === 'female3') {
        if (showFemale) {
          column.toggleHidden(true)
        } 
      }
      if (column.render('Footer') === 'male3') {
        if (showMale) {
          column.toggleHidden(true)
        } 
      }
    })
  }
  // Set Handle Vegetable
  const handleClickVegetableT = () => {
    setShowVegetable(true)
    allColumns.map(column => {
      if (column.render('Footer') === 'female4') {
        if (showFemale) {
          column.toggleHidden(false)
        } 
      }
      if (column.render('Footer') === 'male4') {
        if (showMale) {
          column.toggleHidden(false)
        } 
      }
    })
  }
  const handleClickVegetableF = () => {
    setShowVegetable(false)
    allColumns.map(column => {
      if (column.render('Footer') === 'female4') {
        if (showFemale) {
          column.toggleHidden(true)
        } 
      }
      if (column.render('Footer') === 'male4') {
        if (showMale) {
          column.toggleHidden(true)
        } 
      }
    })
  }
  // Set Handle Sweet
  const handleClickSweetT = () => {
    setShowSweet(true)
    allColumns.map(column => {
      if (column.render('Footer') === 'female5') {
        if (showFemale) {
          column.toggleHidden(false)
        } 
      }
      if (column.render('Footer') === 'male5') {
        if (showMale) {
          column.toggleHidden(false)
        } 
      }
    })
  }
  const handleClickSweetF = () => {
    setShowSweet(false)
    allColumns.map(column => {
      if (column.render('Footer') === 'female5') {
        if (showFemale) {
          column.toggleHidden(true)
        } 
      }
      if (column.render('Footer') === 'male5') {
        if (showMale) {
          column.toggleHidden(true)
        } 
      }
    })
  }
  // Set Handle Drink
  const handleClickDrinkT = () => {
    setShowDrink(true)
    allColumns.map(column => {
      if (column.render('Footer') === 'female6') {
        if (showFemale) {
          column.toggleHidden(false)
        } 
      }
      if (column.render('Footer') === 'male6') {
        if (showMale) {
          column.toggleHidden(false)
        } 
      }
    })
  }
  const handleClickDrinkF = () => {
    setShowDrink(false)
    allColumns.map(column => {
      if (column.render('Footer') === 'female6') {
        if (showFemale) {
          column.toggleHidden(true)
        } 
      }
      if (column.render('Footer') === 'male6') {
        if (showMale) {
          column.toggleHidden(true)
        } 
      }
    })
  }
  // Set Handle Exercise
  const handleClickExerciseT = () => {
    setShowExercise(true)
    allColumns.map(column => {
      if (column.render('Footer') === 'female7') {
        if (showFemale) {
          column.toggleHidden(false)
        } 
      }
      if (column.render('Footer') === 'male7') {
        if (showMale) {
          column.toggleHidden(false)
        } 
      }
    })
  }
  const handleClickExerciseF = () => {
    setShowExercise(false)
    allColumns.map(column => {
      if (column.render('Footer') === 'female7') {
        if (showFemale) {
          column.toggleHidden(true)
        } 
      }
      if (column.render('Footer') === 'male7') {
        if (showMale) {
          column.toggleHidden(true)
        } 
      }
    })
  }
  // Set Handle Female
  const handleClickFemaleT = () => { 
    setShowFemale(true)
    allColumns.map(column => {
      if (column.render('Header') === 'Girls') {
        if (column.render('Footer') === 'female1') {
          if (showOverweight) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === 'female2') {
          if (showAnaemia) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === 'female3') {
          if (showFruit) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === 'female4') {
          if (showVegetable) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === 'female5') {
          if (showSweet) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === 'female6') {
          if (showDrink) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === 'female7') {
          if (showExercise) {
            column.toggleHidden(false)
          }
        }
      }
    })
  }
  const handleClickFemaleF = () => { 
    setShowFemale(false)
    allColumns.map(column => {
      if (column.render('Header') === 'Girls') {
        if (column.render('Footer') === 'female1') {
          if (showOverweight) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === 'female2') {
          if (showAnaemia) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === 'female3') {
          if (showFruit) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === 'female4') {
          if (showVegetable) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === 'female5') {
          if (showSweet) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === 'female6') {
          if (showDrink) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === 'female7') {
          if (showExercise) {
            column.toggleHidden(true)
          }
        }
      }
    })
  }
  // Set Handle Male
  const handleClickMaleT = () => { 
    setShowMale(true)
    allColumns.map(column => {
      if (column.render('Header') === 'Boys') {
        if (column.render('Footer') === 'male1') {
          if (showOverweight) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === 'male2') {
          if (showAnaemia) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === 'male3') {
          if (showFruit) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === 'male4') {
          if (showVegetable) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === 'male5') {
          if (showSweet) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === 'male6') {
          if (showDrink) {
            column.toggleHidden(false)
          }
        }
        if (column.render('Footer') === 'male7') {
          if (showExercise) {
            column.toggleHidden(false)
          }
        }
      }
    })
  }
  const handleClickMaleF = () => { 
    setShowMale(false)
    allColumns.map(column => {
      if (column.render('Header') === 'Boys') {
        if (column.render('Footer') === 'male1') {
          if (showOverweight) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === 'male2') {
          if (showAnaemia) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === 'male3') {
          if (showFruit) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === 'male4') {
          if (showVegetable) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === 'male5') {
          if (showSweet) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === 'male6') {
          if (showDrink) {
            column.toggleHidden(true)
          }
        }
        if (column.render('Footer') === 'male7') {
          if (showExercise) {
            column.toggleHidden(true)
          }
        }
      }
    })
  }
  
  return (
    <>
      <div className="table-scroll relative min-h-550">
        {/* COUNTRIES */}
        <button
          onClick={() => setShowCountries(true)}
          className="
            absolute z-30 top-2 left-2 
            text-white text-xs
            hover:text-sec-blue ani03s"
        >
          Select countries
          <span className="icon-down pl-2"></span>
        </button>
        {/* COUNTRY SELECT LIST */}
        { showCountries &&
          <div className="
            absolute z-40 p-4 m-1 w-52
            flex flex-col
            bg-white border border-main_blue shadow
            text-sm
          ">
            <div className="flex justify-between pb-4 text-xs text-main_blue">
              <div className="">Select to sort</div>
              <button 
                className="icon-close hover:text-sec_blue ani03s"
                onClick={() => setShowCountries(false)}></button>
            </div>
            {checkItems.map(checkItem => (
              <div className="flex items-center justify-between pb-1">
                <div className="pr-2">{ checkItem.country }</div>
                <div className="round_checkbox_box">
                  <div className="center">
                      <input onClick={ checkItem.handleItem } defaultChecked={ checkItem.showItem } type="checkbox" id={ checkItem.classItem }/>
                    <label htmlFor={ checkItem.classItem }></label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
        {/* TABLE */}
        <table {...getTableProps()} 
          className="dTable notFixed tableRightBorder tableTextLR
          tableStripes">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th 
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className=
                    {`
                      ${headerGroup.headers[0].render('Header') === 'Country' ? 'botHeader ani03s' : 'topHeader'}
                      ${column.render('Header') === 'Girls' ? 'female w-40' : ''}
                      ${column.render('Header') === 'Boys' ? 'male w-40' : ''}
                    `}
                  >
                    {column.render('Header') === 'Girls' ? 
                    <span className="gender icon-female"></span> : '' }
                    {column.render('Header') === 'Boys' ? 
                    <span className="gender icon-male"></span> : '' }
                    {column.render('Header')}
                    {headerGroup.headers[0].render('Header') === 'Country' ? 
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <span className="sort icon-sort-desc"></span>
                            : <span className="sort icon-sort-asc"></span>
                          : <span className="sort icon-sort"></span>}
                      </span>
                      : ''
                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
                if (row.cells[0].value === 'Albania' && showAlbania) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Armenia' && showArmenia) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Azerbaijan' && showAzerbaijan) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Belarus' && showBelarus) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Bosnia & Herzegovina' && showBosnia) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Bulgaria' && showBulgaria) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Croatia' && showCroatia) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Georgia' && showGeorgia) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Kazakhstan' && showKazakhstan) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Kyrgyztan' && showKyrgyztan) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'North Macedonia' && showMacedonia) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Moldava' && showMoldava) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Montenegro' && showMontenegro) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Romania' && showRomania) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Servia' && showServia) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Tajikistan' && showTajikistan) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Turkey' && showTurkey) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Turkmenistan' && showTurkmenistan) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Ukraine' && showUkraine) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Uzbekistan' && showUzbekistan) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                }
            })}
          </tbody>
        </table>
      </div>
      <div className="my-2 border border-solid border-txt_black_2 text-xs px-2 py-1">
        <div><span className="sup">*</span>Azzoppardi et al 2019 using data from IHME - Institute for Health Metrics and Evaluation</div>
        <div><span className="sup">**</span>Health Behaviour in School age children (HBSC) 2014 studies apart from Turkey 2010 study</div>
      </div>
      {/* SORT CHECKBOXES */}
      <div className="flex text-main_blue text-sm">Select to sort</div>
      <div className="sortBox">
        {/* LEFT BOX */}
        <div className="left_box">
          {/* CHECKED OVERWEIGHT */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Overweight and Obesity %</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showOverweight && 
                  <input onClick={handleClickOverweightF} defaultChecked={showOverweight} type="checkbox" id="overweight"/>
                }
                {!showOverweight && 
                  <input onClick={handleClickOverweightT} defaultChecked={showOverweight} type="checkbox" id="overweight"/>
                }
                <label htmlFor="overweight"></label>
              </div>
            </div>
          </div> 
          {/* CHECKED ANAEMIA */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Anaemia %</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showAnaemia && 
                  <input onClick={handleClickAnaemiaF} defaultChecked={showAnaemia} type="checkbox" id="anaemia"/>
                }
                {!showAnaemia && 
                  <input onClick={handleClickAnaemiaT} defaultChecked={showAnaemia} type="checkbox" id="anaemia"/>
                }
                <label htmlFor="anaemia"></label>
              </div>
            </div>
          </div> 
          {/* CHECKED FRUIT */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Daily fruit consumption (%)</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showFruit && 
                  <input onClick={handleClickFruitF} defaultChecked={showFruit} type="checkbox" id="fruit"/>
                }
                {!showFruit && 
                  <input onClick={handleClickFruitT} defaultChecked={showFruit} type="checkbox" id="fruit"/>
                }
                <label htmlFor="fruit"></label>
              </div>
            </div>
          </div>
          {/* CHECKED VEGETABLE */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Daily vegetable consumption (%)</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showVegetable && 
                  <input onClick={handleClickVegetableF} defaultChecked={showVegetable} type="checkbox" id="vegetable"/>
                }
                {!showVegetable && 
                  <input onClick={handleClickVegetableT} defaultChecked={showVegetable} type="checkbox" id="vegetable"/>
                }
                <label htmlFor="vegetable"></label>
              </div>
            </div>
          </div> 
          {/* CHECKED SWEET */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Daily sweets consumption (%)</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showSweet && 
                  <input onClick={handleClickSweetF} defaultChecked={showSweet} type="checkbox" id="sweet"/>
                }
                {!showSweet && 
                  <input onClick={handleClickSweetT} defaultChecked={showSweet} type="checkbox" id="sweet"/>
                }
                <label htmlFor="sweet"></label>
              </div>
            </div>
          </div> 
          {/* CHECKED DRINK */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Daily soft drink consumption (%)</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showDrink && 
                  <input onClick={handleClickDrinkF} defaultChecked={showDrink} type="checkbox" id="drink"/>
                }
                {!showDrink && 
                  <input onClick={handleClickDrinkT} defaultChecked={showDrink} type="checkbox" id="drink"/>
                }
                <label htmlFor="drink"></label>
              </div>
            </div>
          </div>  
          {/* CHECKED EXERCISE */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Moderate exercise (60 minutes or more daily) (%)</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showExercise && 
                  <input onClick={handleClickExerciseF} defaultChecked={showExercise} type="checkbox" id="exercise"/>
                }
                {!showExercise && 
                  <input onClick={handleClickExerciseT} defaultChecked={showExercise} type="checkbox" id="exercise"/>
                }
                <label htmlFor="exercise"></label>
              </div>
            </div>
          </div> 
        </div>
        {/* RIGHT BOX */}
        <div className="right_box">
          {/* CHECKBOX FEMALE */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Female</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showFemale &&
                  <input onClick={handleClickFemaleF} defaultChecked={showFemale} type="checkbox" id="female"/>
                }
                {!showFemale &&
                  <input onClick={handleClickFemaleT} defaultChecked={showFemale} type="checkbox" id="female"/>
                }
                <label htmlFor="female"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX MALE */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Male</div>
            <div className="round_checkbox_box">
              <div className="center">
                {showMale &&
                  <input onClick={handleClickMaleF} defaultChecked={showMale} type="checkbox" id="male"/>
                }
                {!showMale &&
                  <input onClick={handleClickMaleT} defaultChecked={showMale} type="checkbox" id="male"/>
                }
                <label htmlFor="male"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* LEGEND */}
      <div className="mt-2 bg-main_blue p-4 col2 text-white text-sm">
        <p>Orange and yellow cells indicate thresholds developed by the report authors, as means of flagging countries in the overview that are doing relatively less well in comparison to others for each indicator. The thresholds used for anaemia are international and from WHO (WHO, 2018). Thresholds for orange/yellow flags used are:</p>
        <div className="list-disc pl-6 noColumnBreak">
          <p className="npPad">For overweight or obesity:</p>
          <div className="pl-1">
            <div className="flex relative">
              <span className="w-3 h-3 bg-orange mr-1 mt-1 rounded-full absolute"></span>
              <div className="pl-5">Orange a prevalence of {`>`} 25%</div>
            </div>
            <div className="flex relative">
              <span className="w-3 h-3 bg-yellow mr-1 mt-1 rounded-full absolute"></span>
              <div className="pl-5">Yellow a prevalence of {`>`} 20%</div>
            </div>
            <div className="flex relative">
              <span className="w-3 h-3 bg-orange mr-1 mt-1 rounded-full absolute"></span>
              <div className="pl-5">For anaemia prevalence of {`>`} 20%</div>
            </div>
            <div className="flex relative">
              <span className="w-3 h-3 bg-orange mr-1 mt-1 rounded-full absolute"></span>
              <div className="pl-5">For daily fruit and vegetable consumption a prevalence of {`<`} 40%</div>
            </div>
            <div className="flex relative">
              <span className="w-3 h-3 bg-orange mr-1 mt-1 rounded-full absolute"></span>
              <div className="pl-5">For daily soft drink consumption a prevalence of {`>`} 25%</div>
            </div>
            <div className="flex relative">
              <span className="w-3 h-3 bg-orange mr-1 mt-1 rounded-full absolute"></span>
              <div className="pl-5">For daily exercise a prevalence of {`<`} 25%</div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}
// Export
export default Table03 