// Import Components
import PageTitle from '../../components/PageTitle'
import Modal from '../../components/Modal'
// Component
const Conclusions1 = () => {
  return (
    <>
      <PageTitle title="Vol 1. Conclusions" />
      <div className="article_content col2">
        <p>The available data suggests that the most significant nutrition issue amongst children and adolescents aged 7 to 18 years throughout the region is overweight and obesity.</p>
        <ul className="bullets">
          <li>For boys, fourteen countries had an overweight and obesity prevalence of ≥ 20%, while three countries reached over 30% (Croatia, Montenegro and Georgia).</li>
          <li>Ten countries had at least one in five girls with a prevalence of overweight or obesity ≥20%.</li>
          <li>The largest burden of overweight or obese adolescents (39% of the total from 21 countries) live in Turkey.</li>
          <li>Just 3 out of the 21 countries, Turkey, Ukraine and Uzbekistan contribute 65% of the total burden of overweight or obesity.</li>
          <li>Consumption of fast foods, soft drinks and sweets is notably high in this age group, levels of breakfast consumption has been found by studies to be reducing.</li>
          <li>The majority are not reaching minimum standards for levels of daily physical exercise.</li>
          <li>Only four countries with any policy or regulation in place for marketing of ‘unhealthy’ foods to children (Armenia, Bosnia and Herzegovina, Kyrgyz Republic and Turkey).</li>
          <li>Nutrition education and healthy lifestyles promotion are largely limited to schools for this age group, with little focus on a family / household approach.</li>
        </ul>
        <p>Micronutrient deficiencies, particularly anaemia, emerge as a further nutrition challenge in this region. </p>
        <ul className="bullets">
          <li>for boys and girls aged 10 to 24 years reports anaemia prevalence ranging from 7.2 percent in girls in Croatia to 43.3 percent in Uzbekistan and 5.2% in boys in Ukraine to 33.6% in Uzbekistan.</li>
          <li>High rates of anaemia were noted in Uzbekistan, Kazakhstan, Kyrgyzstan, Georgia.</li>
          <li>Romania, Tajikistan, Turkey, Turkmenistan and Azerbaijan, where more than a fifth of adolescents of either, or both sexes, were affected.</li>
          <li>The absence of insufficient national strategies to address anaemia such as flour fortification in the region may be amongst the major factors for continuing high prevalence of anaemia.</li>
          <li>From much of the survey data reviewed boys appear to be more affected than girls.</li>
          <li>Further exploration of the factors behind these high rates is warranted.</li>
          <li>Vitamin D deficiency has been noted as a concern in Bosnia and Herzegovina and Turkey, while iodine deficiency appears to be decreasing as a problem as improvements in fortification of salt are realised.</li>
        </ul>
        <p>While a number of datasets exist for countries in the ECA Region, the data cannot be compared in any meaningful way because different age groups have been studied and different growth references have been used. More robust standardised data is required. Data for children and adolescents should be reported in 5-year age groups (5-9, 10-14 and 15-19) by sex, with sample sizes. The percentage of children and adolescents who are stunted, thin, overweight and obese should be reported using both the IOTF and the WHO growth references.</p>
        <h3 className="text-main_blue text-xl font-bold pb-4">Reflections on potential next steps</h3>
        <p className="text-main_blue font-bold">The findings of this review suggest that there are three critical areas that need immediate attention in the region: A) the promotion of healthy diets and physical activity to address high levels of overweight/obesity and micronutrient deficiencies; B) anaemia prevention efforts; C) and addressing the considerable data gaps and challenges for school-aged children and adolescent nutrition.</p>
        <p><strong>A) and B)</strong> To support <strong>the promotion of healthy diets, physical activity and anaemia prevention</strong></p>
        <ul className="bullets">
          <li><Modal textLink={'Create healthy food and drink environments.'}>
            <>
              <p>Design actions to promote and incentivise healthy food choices and better inform parents, adolescents and children on nutrition within both the school and home environments. Special attention may be needed to:</p>
              <ul className="bullets">
                <li>Promote intake of fruits and vegetables as well as foods that promote absorption of micronutrients such as iron. This could include fortification of maize flour and corn meal with iron and folic acid to prevent iron deficiency in vulnerable populations.</li>
                <li>Discourage intake of energy-dense micronutrient-poor foods and sugar-sweetened beverages as well as foods that inhibit micronutrient absorption.</li>
              </ul>
            </>
          </Modal> Limiting the exposure of adolescents to heavy marketing of unhealthy food choices and providing necessary information and skills to make healthy food choices</li>
          <li>Promote the gains of a healthy diet throughout life</li>
          <li>Reinforce health systems to promote healthy diets.</li>
          <li>Strengthen governance, alliances and networks to ensure a health-in-all-policies approach.</li>
        </ul>
        <p>Intersectoral collaboration is critical to ensure joined up thinking on tackling overweight in children and adolescents.</p>
        <p>Collaboration with the food industry by health, education and other government departments is an essential component of protecting children and adolescents from inappropriate marketing which is harmful to their health.</p>
        <p>The EU Action Plan on Childhood Obesity 2014-2020 proposes that each Member State develop, implement and/or evaluate its own national action plan on childhood overweight and obesity.</p>          
        <p><strong>C)	Addressing the considerable data gaps and challenges.</strong> In order to inform policies and interventions for middle childhood and adolescent nutrition, more robust standardised data is required. Data for children and adolescents should be reported in 5-year age groups (5-9, 10-14 and 15-19) by sex, with sample sizes. The percentage of children and adolescents who are stunted, thin, overweight and obese should be reported using both the IOTF and the WHO growth references.</p>
        <p>Finally, it’s recommended to have over-arching research strategies in the region / by country in order to understand more about nutritional deficiencies in this age group and tailor policy, strategy and programmes to address issues. In addition, data disaggregated at a level below national surveys and assessments focusing on deprived groups, out-of-school or marginalised groups needs to be straightened.</p>
      </div>
    </>
  ) 
}
export default Conclusions1
