const Table03_2 = () => {
  return (    
    <table className="allBorders table-auto w-full text-left border border-main_blue xs:text-xs">
      <thead>
        <tr className="text-white">
            <th className="bg-main_blue">Policy/programme documents</th>
            <th className="bg-header1">Targets/indicators with specific mention of children aged 7-18 years</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Policies / Strategies</span></td>
        </tr>
        <tr>
          <td>
            Child and Adolescent Health Strategy 2016-2020</td>
            <td>
            <p>One of the Strategy’s 7 strategic priorities focuses on this age group specifically:</p>
            <ul className="bullets">
              <li>School-age and adolescent children’s health and development</li>
            </ul>
            <p>One Strategic direction is specific to nutrition: Raising public awareness   on child health, nutrition, development, care, safety, disease prevention and management</p>
            <p>One performance indicator: Percentage of schoolchildren showing unhealthy eating habits</p>
          </td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Policy to promote health, nutrition and physical activity in schools. Ministry of Health, 2016</td>
          <td>
            <p>This policy highlights Non-Communicable Diseases (NCDs) as a major cause of mortality and morbidity and the focuses on the vital period of school-age and adolescence in the human health cycle.</p>
            <p>A key aspect of improving schoolchildren’s health identified is: Creating sustainable motivation for healthy lifestyles</p>
            <p>The Policy sets out measure schools need to undertake to promote healthy nutrition:</p>
            <ul className="bullets">
              <li>Provide access to drinking water</li>
              <li>Control the range of foods provided in school canteens</li>
              <li>Provide fresh fruit and vegetables for free/ at low price in school canteens</li>
              <li>Transition school feeding programme from juice  /  milk  and  biscuits  to hot breakfast</li>
              <li>Installation of unhealthy food dispensers not allowed</li>
              <li>Exclude advertisement of unhealthy food, including sweets and replace with health food posters</li>
              <li>Encourage a healthy diet with the help of regular seminars, trainings, campaigns and other activities amongst schools and parents</li>
              <li>Establishment of school vegetable gardens to supply school canteens</li>
              <li>Promote teeth-cleaning after breakfast and set up dental hygiene stations</li>
            </ul>
            <p>To promote health, nutrition, literacy, awareness, schools are to be governed by guidelines approved by MOH and developed by MOES within the framework of the ‘sustainable food programme’</p>
          </td>
        </tr>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Guidelines /Legislation</span></td>
        </tr>
        <tr>
          <td>Hygiene Requirements, School Feeding, 2014</td>
          <td>Provides guidelines on hygiene and food safety in the provision of school meals in Armenia.</td>
        </tr>
      </tbody>
    </table>
  ) 
}
export default Table03_2
