const Table01 = () => {
  return (    
    <table className="tableRightBorder table-fixed w-full">
      <tbody>
        <tr>
          <td>Albania</td>
          <td className="font-bold text-main_blue">North Macedonia (TFYROM)</td>
        </tr>
        <tr className="bg-pale_blue">
          <td className="font-bold text-main_blue">Armenia</td>
          <td>Moldova</td>
        </tr>
        <tr>
          <td className="font-bold text-main_blue">Azerbaijan</td>
          <td className="font-bold text-main_blue">Montenegro</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Belarus</td>
          <td>Romania</td>
        </tr>
        <tr>
          <td className="font-bold text-main_blue">Bosnia & Herzegovina</td>
          <td>Serbia</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Bulgaria</td>
          <td className="font-bold text-main_blue">Tajikistan</td>
        </tr>
        <tr>
          <td>Croatia</td>
          <td className="font-bold text-main_blue">Turkey</td>
        </tr>
        <tr className="bg-pale_blue">
          <td className="font-bold text-main_blue">Georgia</td>
          <td>Turkmenistan</td>
        </tr>
        <tr>
          <td>Kazakhstan</td>
          <td>Ukraine</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Kosovo (UNSCR 1244)</td>
          <td>Uzbekistan</td>
        </tr>
        <tr>
          <td className="font-bold text-main_blue">Kyrgyzstan</td>
          <td> - </td>
        </tr>
      </tbody>
    </table>
  ) 
}
export default Table01
