// Import Image
import Image from '../../images/methods_1.jpg'
// Import Components
import PageTitle from '../../components/PageTitle'
import CoverImg from '../../components/CoverImg'
// Component
const Definitions = () => {
  return (
    <>
      <PageTitle title="Definitions" />
      <CoverImg SrcImg = { Image } />
      <div className="article_content col2">
        <p>Adolescence is defined by the WHO as the period between the ages of 10 and 19 years. The Lancet Commission on Adolescents (2016) defined it as the period between the ages of 10 and 24 years. For the purpose of this review, the age range of interest was 5-19 years, although some data was included if the age range extended some years younger (e.g. 2-19 years for Global Burden of Disease (GBD) data) or older e.g. 10-24 years for Institute of Health Metrics and Evaluation (IHME) data.</p>
      </div>
    </>
  ) 
}
export default Definitions
