import { VectorMap } from "react-jvectormap";
import { useState } from 'react'
// Component
const Map01 = ({ RegionClick }) => {

  var mapData = {
    AM: 0, // Armenia
    AZ: 1, // Azerbaijan
    BA: 2, // Bosnia
    GE: 3, // Georgia
    KG: 4, // Kyrgyzstan
    MK: 5, // Macedonia
    ME: 6, // Montenegro
    TJ: 7, // Tajikistan
    TR: 8, // Turkey
  };

  var mapDataDetails = [
    {
      country: 'Armenia',
      v1: 'bg-map_progress', 
      v2: 'bg-map_available',
      v3: 'bg-map_available',
      v4: '',
    },
    {
      country: 'Azerbaijan',
      v1: 'bg-map_available', 
      v2: 'bg-map_available',
      v3: 'bg-map_progress',
      v4: '',
    },
    {
      country: 'Bosnia and Herzegovina',
      v1: 'bg-map_n_strategy', 
      v2: 'bg-map_progress',
      v3: 'bg-map_available',
      v4: '',
    },
    {
      country: 'Georgia',
      v1: 'bg-map_progress', 
      v2: 'bg-map_available',
      v3: 'bg-map_n_regulations',
      v4: '',
    },
    {
      country: 'Kyrgyz Republic',
      v1: 'bg-map_progress', 
      v2: 'bg-map_available',
      v3: 'bg-map_available',
      v4: '',
    },
    {
      country: 'North Macedonia',
      v1: 'bg-map_n_strategy', 
      v2: 'bg-map_progress',
      v3: 'bg-map_n_regulations',
      v4: '',
    },
    {
      country: 'Montenegro',
      v1: 'bg-map_available', 
      v2: 'bg-map_progress',
      v3: 'bg-map_available',
      v4: '*',
    },
    {
      country: 'Tajikistan',
      v1: 'bg-map_progress', 
      v2: 'bg-map_available',
      v3: 'bg-map_n_regulations',
      v4: '',
    },
    { 
      country: 'Turkey',
      v1: 'bg-map_available', 
      v2: 'bg-map_available',
      v3: 'bg-map_progress',
      v4: '',
    }
  ];
  
  const series = {
    regions: [
      {
        values: mapData,
        scale: ["#0b709a", "#0b709b"],
        normalizeFunction: "polynomial"
      }
    ]
  }
  
  const RegionTipShow = (e, el, code) => {
    if (mapData[code] >= 0) {
      el.html(
        '<h1>' + mapDataDetails[mapData[code]].country + '</h1>' +
        '<p><span class="' + mapDataDetails[mapData[code]].v1 + '"></span> Child and Adolescent Health Strategy</p>' +
        '<p><span class="' + mapDataDetails[mapData[code]].v2 + '"></span> Policy on the availability of unhealthy foods in schools</p>' +
        '<p><span class="' + mapDataDetails[mapData[code]].v3 + '"></span> Regulations restricting marketing to children' + mapDataDetails[mapData[code]].v4 + '</p></div>'
      );
    } else {
      el.html('<h4>' + el.html() + '</h4>');
    }
  }

  const [show, setShow] = useState(false);
  const closeModalHandler = () => setShow(false);

  return (
    <>
      <div className="w-full h-80 relative">
        <button className="
        absolute map_z_info top-2 right-2
        w-6 h-6 rounded-full ani03s
        bg-txt_black_1 text-white text-sm hover:bg-gray
        "
        onClick={() => setShow(true)}
        >
          <span className="relative icon-info top-0.5"></span>
        </button>
        <VectorMap map={'world_mill'}
          backgroundColor="#00AEEF"
          useRef="map"
          containerStyle={{
              width: '100%',
              height: '100%'
          }}
          containerClassName="map"
          regionStyle={{
            initial: {
              fill: "#ffffff",
              "stroke-width" : 0.1,
              "stroke-opacity" : 0.2,
            },
            hover: {
              fill: "#eaf6fe",
              "stroke-width" : 0.1,
              "stroke-opacity" : 0.2,
            }
          }}
          series={series}
          onRegionTipShow = {RegionTipShow}
          onRegionClick = { RegionClick }
          focusOn = {{scale: 2, x: 0.6, y: 0.4, animate: false}}
        />
      </div>
      <div className="notes_box">
        <p>Source: WHO 2018.</p>
        <p><span className="sup">*</span>N.B. Although stated as ‘available’ in the WHO report, the desk review did not find any national regulations in place in Montenegro.</p>
      </div>
      <div className="notes_box flex">
        <div className="pr-4">
          <span className="w-3 h-3 bg-greenMap mr-1 mt-1 rounded-full inline-block"></span>
            Available
        </div>
        <div className="pr-4">
          <span className="w-3 h-3 bg-orangeMap mr-1 mt-1 rounded-full inline-block"></span>
            In progress
        </div>
        <div className="pr-4">
          <span className="w-3 h-3 bg-gray mr-1 mt-1 rounded-full inline-block"></span>
            No strategy
        </div>
        <div>
          <span className="w-3 h-3 bg-txt_black_1 mr-1 mt-1 rounded-full inline-block"></span>
            No regulations
        </div>
      </div>
      { show && 
        <div
          onClick={closeModalHandler}
          style={{ opacity: show ? 1 : 0 }}
          className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
          <div className="px-8 py-6 bg-white relative max-w-400">
            <div
              onClick={closeModalHandler}
              className="icon-close absolute top-1 right-1 cursor-pointer">
            </div>
            These maps are stylized and not to scale, and do not reflect a position by UNICEF on the legal status of any country or territory or the delimitation of any frontiers.
          </div>
        </div>
      }
    </>
  ) 
}

export default Map01
