const Table13_2 = () => {
  return (    
    <table className="allBorders table-auto w-full text-left border border-main_blue xs:text-xs">
      <thead>
        <tr className="text-white">
            <th className="bg-main_blue">Policy/programme document</th>
            <th className="bg-header1">Targets/indicators with specific mention of children aged 7-18 years</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Programmes /Strategies/ Plans</span></td>
        </tr>
        <tr>
          <td>
            <p>National Youth Strategy 2016-2025</p>
            <p className="text-txt_black_1">Agency of Youth and Sports of Republic of North Macedonia, (Skopje, 2016)</p>
          </td>
          <td>
            <p>Objective 3 emphasizes the importance of establishing healthy eating habits among young people aged between 15 and 19 years. To achieve this, the Strategy foresees the following measures:</p>
            <ol className="list-decimal pl-8">
              <li>Regular general medical examinations for monitoring child development.</li>
              <li>Developing personal programmes for every child in secondary school.</li>
              <li>Promoting healthy life-styles.</li>
              <li>Introducing continuous medical education on topics in this area.</li>
              <li>Involving health workers in training for the prevention of obesity, anorexia and bulimia.</li>
            </ol>
          </td>
        </tr>
        <tr className="bg-pale_blue">
          <td>
            <p>Health Strategy of The Republic of North Macedonia, 2020 Safe, Efficient and Just Health Care System</p>
            <p className="text-txt_black_1">Skopje, 2007</p>
          </td>
          <td>
            <p>The achievement of the goals, objectives and guidelines in this strategy were to be ensured by adopting and implementing an Action plan.</p>
            <p>There is very little mention of nutrition, though one sentence about its relevance for children:</p>
            <p>Special attention will be paid to the health education for school children, which will contribute to better health for young people (target 4). This is especially important for the adoption of healthy lifestyles, such as proper nutrition, sufficient physical exercise, prevention of addiction to tobacco, alcohol and psychoactive substances (target 12).</p>
            <p>It refers to implementation of the National Food and Nutrition Action Plan 2007. An updated version of this plan has since been drafted with support of the UN agencies but has not been adopted.</p>
          </td>
        </tr>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Guidelines /Legislation</span></td>
        </tr>
        <tr>
          <td>
            <p>Draft guidance for the proper application of the Rule Book on standards for diet and meals in elementary school</p>
            <p className="text-txt_black_1">May 17, 2018</p>
          </td>
          <td>
            <p>The standards are aimed at children aged 6-14 years and are harmonised with WHO 2006 guidance on proper nutrition of school children.</p>
            <p>The Goal is to provide conditions for adequate psychophysical growth and development of children and the formation of healthy eating habits that will have an impact on their approach to nutrition throughout life. Standards also prevent malnutrition in children (micronutrient deficiency, malnutrition, obesity and chronic diseases).</p>
            <p>Practical guidelines for diet planning and menu making in primary schools are provided. Guidance is given on:</p>
            <ul className="bullets">
              <li>Reference values for energy and nutrient intake by different categories, macronutrients and micronutrients;</li>
              <li>Recommendations for the number, type and schedule of meals</li>
              <li>Recommended types of foods and dishes</li>
              <li>Foods to be avoided or rarely consumed</li>
            </ul>
            <p>The manual contains examples of seasonal menus with norms that can serve as a guideline for developing new, varied menus, with optimal adjustment to the overall economic situation and the purchasing power of the population</p>
          </td>
        </tr>
        <tr className="bg-pale_blue">
          <td>
            <p>Rulebook about Nutrition standards and meals in primary school</p>
            <p className="text-txt_black_1">May 17, 2018</p>
          </td>
          <td>
            <p>Prescribes nutrition standards and meals for primary schools for children aged 6-14 years. It aims to prevent irregular nutrition in children in all forms, such as: malnutrition, increased body weight and obesity, as well as prevention of chronic nutrition-related diseases.</p>
            <p>The standards refer to reference values for daily energy input, proteins, carbohydrates, dietary fibre, fats, minerals, vitamins and water, for children aged 6   to 14 years who are moderately physically active.</p>
          </td>
        </tr>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Promotional materials</span></td>
        </tr>
        <tr>
          <td>
            <p>Healthy Diet for Primary School Children</p>
            <p className="text-txt_black_1">Republic Institute for Health Protection 2007</p>
          </td>
          <td>
            <p>Healthy eating brochure/ health promotion tool aimed at students and intended to initiate a broad programme of promotion for primary school pupils and to educate children in the field of proper nutrition. It provides advice on healthy foods/meals for children aged 6-14 years with the aim of building good dietary habits.</p>
          </td>
        </tr>
      </tbody>
    </table>
  ) 
}
export default Table13_2
