// Import Image
import Image from "../../images/methods_4.jpg";
// Import Components
import PageTitle from "../../components/PageTitle";
import CoverImg from "../../components/CoverImg";
// Component
const Methods2 = () => {
    return (
        <>
            <PageTitle title="Methods" />
            <CoverImg SrcImg={Image} />
            <div className="article_content col2">
                <p>
                    This regional analysis was undertaken to fill this gap and
                    to ultimately make evidence informed advocacy, policy and
                    programing decisions for the nutrition of adolescent and
                    school age children in Europe and Central Asia region. The
                    analysis was composed of three major pillars;
                </p>
                <ul className="bullets">
                    <li>
                        <p>
                            The systematic literature review; the search for all
                            relevant articles was conducted on the 25th April
                            2019 in Pubmed, Cochrane, and ScienceDirect
                            databases using search terms linked to the research
                            question: What is the nutritional status of
                            adolescents and children (5-19 years old) in Europe
                            and Central Asia region, and what are successful
                            programmes / interventions for improving nutritional
                            status of this population?
                        </p>
                        <p>
                            Reference lists of eligible publications were also
                            searched for relevant titles, as well as referral of
                            publications from relevant experts.
                        </p>
                    </li>
                    <li>
                        For the survey review, surveys were identified with
                        nationally representative data that had been conducted
                        in any of ECA Region countries (n=21) from 2000-2019 and
                        which included anthropometric data on adolescents, or
                        data on their nutritional status or dietary habits.
                        Scientific papers reporting (and in some cases
                        re-analysing) nationally representative data provided
                        most of the information included in this review.
                    </li>
                    <li>
                        For the policy and program review in nine targeted
                        countries (Armenia, Azerbaijan, Bosnia and Herzegovina,
                        Georgia, Kyrgyzstan, Montenegro, North Macedonia,
                        Tajikistan, and Turkey) documents were gathered by
                        UNICEF country focal points, assisted by Ministry of
                        Health colleagues in several countries and from online
                        searches, including WHO extranet and EC resources. Each
                        document collected was considered for its relevance to
                        the review, and documents which included a specific
                        section, mention or focus on the nutritional status of
                        children within the 7-18 years age group were included
                        in the final report.
                    </li>
                </ul>
            </div>
        </>
    );
};
export default Methods2;
