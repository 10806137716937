import { useState } from 'react'
// Import Pages Elements
import Figure02 from '../../page_elements/figures/Figure02'
import Table04 from '../../page_elements/tables/Table04'
// Import Image
import Image from '../../images/findings_1.jpg'
// Import Components
import CoverImg from '../../components/CoverImg'
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TableTitle from '../../components/TableTitle'
// Component
const Overweight = () => {

  const pageTitle = 'Overweight or obesity'
  const figureDesc = 'Overweight or obesity prevalence according to Azzopardi, 2019 for 10 to 24-year olds (using IOTF thresholds)'
  const tableDesc = 'Countries in UNICEF ECA Region with population data, age 10-24 years (thousands), estimated prevalence of overweight or obesity (using IHME data as reported by Azzopardi, et al. 2019) and estimated number of overweight or obese 10 to 24-year olds (thousands).'

  const [showFigure, setShowFigure] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showText, setShowText] = useState(true);
  function showFigureHandler(e) {
    e.preventDefault();
    setShowFigure(!showFigure)
    setShowText(false)
    setShowTable(false)
  }
  function showTableHandler(e) {
    e.preventDefault();
    setShowTable(!showTable)
    setShowText(false)
    setShowFigure(false)
  }
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable(false)
    setShowFigure(false)
  }

  return (
    <>
      <PageTitle title = { pageTitle } >
        {!showText &&
          <TitleButton 
            showHandler = { showTextHandler }
            seeText = { 'See Text: '}
            icon = {'icon-text-icon'}
            description = { pageTitle }
          />
        }
        {!showFigure &&
          <TitleButton 
            showHandler = { showFigureHandler }
            seeText = { 'See Figure 2: '}
            icon = {'icon-chart-icon'}
            description = { figureDesc }
          />
        }
        {!showTable &&
          <TitleButton 
            showHandler = { showTableHandler }
            seeText = { 'See Table 4: '}
            icon = {'icon-tables-icon'}
            description = { tableDesc }
          />
        }
      </PageTitle>
      {/* TEXT */}
      { showText && 
        <>
          <CoverImg SrcImg = { Image } />
          <div className="article_content col2">
            <p>Boys were generally more overweight or obese than girls across many of the studies reviewed.</p>
            <p>Factors to predict the likelihood of being overweight and obese in childhood and adolescence.</p>
            <ul className="bullets">
              <li key='a1'>Individual factors</li>
              <ul>
                <li key='b1'>Gender</li>
                <li key='b2'>Age</li>
              </ul>
              <li key='a2'>Environmental factors</li>
              <li key='a3'>Family factors</li>
              <li key='a4'>Lifestyle factors</li>
              <ul>
                <li key='c1'>Energy intake</li>
                <li key='c2'>Physical activity</li>
              </ul>
            </ul>
          </div>
        </>
      }
      {/* TABLE */}
      { showTable && 
        <div className="article_content">
          <TableTitle
            title={'Table 4'}
            description={ tableDesc }
          />
          <Table04 />
        </div>
      }
      {/* FIGURE */}
      { showFigure && 
        <div className="article_content">
          <TableTitle
            title={'Figure 2'}
            description={ figureDesc }
          />
          <div className="
            flex flex-wrap justify-between
            border-b-2 border-main_blue
            pt-2 text-sm
          ">
            <div className="text-main_blue pr-10 pb-1">Prevalence of overweight or obesity (10-24 years)</div>
            <div className="pb-1">
              <span className="text-main_blue">Legend:</span>
              <span className="
                icon-female text-girls
                relative top-0.5 pl-2 
              "></span>
              <span>Girls 2019</span>
              <span className="
                icon-male text-boys
                relative top-0.5 pl-2 
              "></span>
              <span>Boys 2019</span>
            </div>
          </div>
          <Figure02 />
        </div>
      }
    </>
  )
}

export default Overweight 
