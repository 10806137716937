import { useState } from 'react'
// IMPORT IMAGES
import Figure01 from '../../images/fig01.svg'
// Import Components
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TableTitle from '../../components/TableTitle'

// Component
const Lifecycle = () => {

  const pageTitle = 'Adolescent & school age nutrition in the lifecycle'
  const figureDesc = 'Terminology and age brackets for children to youth'

  const [showFigure, setShowFigure] = useState(false);
  const [showText, setShowText] = useState(true);
  function showFigureHandler(e) {
    e.preventDefault();
    setShowFigure(!showFigure)
    setShowText(false)
  }
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowFigure(false)
  }

  return (
    <>
      <PageTitle title = { pageTitle } >
        {!showText &&
          <TitleButton 
            showHandler = { showTextHandler }
            seeText={ 'See Text: '}
            icon={'icon-text-icon'}
            description={ pageTitle }
          />
        }
        {!showFigure &&
          <TitleButton 
            showHandler = { showFigureHandler }
            seeText={ 'See Figure 1: '}
            icon={'icon-chart-icon'}
            description={ figureDesc }
          />
        }     
      </PageTitle>
      { showFigure && 
        <div className="article_content flex flex-col">
          <TableTitle
            title={'Figure 1'}
            description={ figureDesc }
          />
          <div className="mx-auto my-0">
            <img className="w-full h-auto" src={Figure01} alt="Figure 1 - Terminology and age brackets for children to youth" />
          </div>
          <div className="text-xs">Source: UNICEF internal communication</div>
        </div>
      }
      { showText && 
        <div className="article_content col2">
          <p>Across the lifecycle – from the womb until adulthood – a child’s nutritional needs, and the behaviours and influences on diet evolve and change. Whilst the first 1,000 days – from the point of conception to around the child’s second birthday – are crucial, middle childhood (age 5-9 years) and subsequent adolescence (defined by WHO as 10-19 years) are also critical times for growth, development and establishment of healthy eating habits.</p>
          <p>Despite all this, the periods of middle childhood and adolescence are regularly overlooked in international and national development priorities, policies and guidelines and there is relatively limited empirical evidence on the needs and best ways to support adolescent nutrition. There is a lack of comprehensive guidelines targeting adolescents as well as documented practical experience on what works in terms of programmatic approaches to support adolescents’ nutritional needs. This was reinforced by the Lancet Commission on adolescent health and wellbeing from 2016, which highlighted a number of gaps in our understanding of how to improve adolescent health and nutrition including a lack of disaggregated data by age groups and gender, lack of evidence in low and middle income countries on outcome data, the need for more adolescent-friendly, participatory, multi-sector, multi-component and multi-level interventions; and identifying key research priorities (catch-up growth, critical age groups and platforms for intervention) (Patton, et al. 2016).</p>
          <p>This review aims to collate and present data and information on the nutritional status (over- and under-nutrition and micronutrient deficiencies) and unhealthy dietary patterns and behaviours of children and adolescents (7-18 years) across the Europe and Central Asia (ECA) region in order to assist with evidence-informed advocacy, policy and programming decisions.</p>
        </div> 
      }
    </>
  ) 
}

export default Lifecycle
