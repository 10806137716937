const Table10_2 = () => {
  return (
    <>  
      <div className="table-scroll relative">
        <table className="tableRightBorder table-auto w-full text-left xs:text-xs">
          <thead>
            <tr className="text-white bg-main_blue">
              <th>
                <div className="bg-main_blue min-w-80">Survey</div>
              </th>
              <th>
                <div className="min-w-80">Age group</div>
              </th>
              <th>
                <div className="min-w-80">Boys/Girls (non-pregnant)</div>
              </th>
              <th>
                <div className="min-w-80">{`Thin % (BMI for age <-2SD)`}</div>
              </th>
              <th>
                <div className="min-w-80">{`HAZ% <-2SD`}</div>
              </th>
              <th>
                <div className="min-w-80">Overweight or obese % (95% C.I.)</div>
              </th>
              <th>
                <div className="min-w-80">Anaemia % (95% C.I.)</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-main_blue bg-white">DHS 2012*</td>
              <td>15-19 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td>
                <p className="text-right">2.6</p>
                <p className="text-right">(1.6, 4.3)</p>
              </td>
              <td>
                <p className="text-right">3.7</p>
                <p className="text-right">(2.5, 5.2)</p>
              </td>
              <td>
                <p className="text-right">7.7</p>
                <p className="text-right">6.2, 9.5)</p>
              </td>
              <td>
                <p className="text-right">34.4</p>
                <p className="text-right">(31.2, 37.7)</p>
              </td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="text-main_blue bg-pale_blue">IHME</td>
              <td>10-24 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td>-</td>
              <td>-</td>
              <td className="text-right">13.1</td>
              <td className="text-right">32.4</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="bg-pale_blue">
                <p>2016</p>
                <p>(modelled data from 1990-2016)</p>
                <p>(IOTF) (Azzopardi 2018)</p>
              </td>
              <td>10-24 y</td>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td>-</td>
              <td>-</td>
              <td className="text-right">33.6</td>
              <td className="text-right">31.4</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="infoBox">
        <span>*</span>DHS data is as reported in Benedict 2018, reanalysed using BMI-for-age
      </div>
    </>
  ) 
}
export default Table10_2
