const Table02_2 = () => {
  return (
    <>  
      <div className="table-scroll relative">
        <table className="tableRightBorder table-auto w-full text-left xs:text-xs">
          <thead>
            <tr className="text-white bg-main_blue">
              <th className="bg-main_blue">
                <div className="min-w-80">Survey</div>
              </th>
              <th>
                <div className="min-w-80">Age group</div>
              </th>
              <th>
                <div className="min-w-80">Boys/Girls (non-pregnant)</div>
              </th>
              <th>
                <div className="min-w-100">{`Thin % (BMI for age <-2SD)`}</div>
              </th>
              <th>
                <div className="min-w-100">{`HAZ% <-2SD`}</div>
              </th>
              <th>
                <div className="min-w-100">Overweight or obese % (95% C.I.)</div>
              </th>
              <th>
                <div className="min-w-100">Anaemia % (95% C.I.)</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-main_blue bg-white">DHS 2000*</td>
              <td>15-19 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">0.6 (0.2, 2.0)</td>
              <td className="text-right">6.1 (4.5, 8.3)</td>
              <td className="text-right">19.0 (16.4, 22.0)</td>
              <td className="text-right">8.0 (6.4, 10.0)</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="text-main_blue bg-pale_blue">DHS 2005 *</td>
              <td>15-19 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">1.4 (0.6, 3.3)</td>
              <td className="text-right">6.0 (4.6, 7.8)</td>
              <td className="text-right">13.6 (11.4, 16.3)</td>
              <td className="text-right">19.6 (16.5, 23.0)</td>
            </tr>
            <tr>
              <td className="text-main_blue bg-white">DHS 2015/16 *</td>
              <td>15-19 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">0.7 (0.3, 1.7)</td>
              <td className="text-right">10.6 (8.2, 13.6)</td>
              <td className="text-right">11.1 (8.4, 14.7)</td>
              <td className="text-right">17.1 (13.8, 21.1)</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="text-main_blue bg-pale_blue">IHME</td>
              <td>10-24 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">20.8</td>
              <td className="text-right">13.5</td>
            </tr>
            <tr>
              <td className="bg-pale_blue">
                <p>2016</p>
                <p>(modelled data from 1990-2016)</p>
                <p>(IOTF) (Azzopardi 2018)</p>
              </td>
              <td>10-24 y</td>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">22.5</td>
              <td className="text-right">19.3</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="infoBox">
        <span>*</span>DHS data is as reported in Benedict 2018, reanalysed using BMI-for-age
      </div>
    </>
  ) 
}
export default Table02_2
