import { useState } from 'react'
// Import Flag
import Flag from '../../images/azerbaijan.svg'
// Import Page Elements
import Table04_2 from '../../page_elements/tables/Table04_2'
import Table05_2 from '../../page_elements/tables/Table05_2'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TitleLink from '../../components/TitleLink'
import TableTitle from '../../components/TableTitle'
import Footnote from '../../components/Footnote'
// Component
const Azerbaijan = () => {

  const pageTitle = 'Azerbaijan'
  const table1Desc = 'Status of the nutrition of school-aged children and adolescents'
  const table2Desc = 'Policy and programming environment in Azerbaijan'

  const [showText, setShowText] = useState(true);
  const [showTable1, setShowTable1] = useState(false);
  const [showTable2, setShowTable2] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
    setShowTable2(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowTable2(false)
    setShowText(false)
  }
  function showTable2Handler(e) {
    e.preventDefault();
    setShowTable2(!showTable2)
    setShowTable1(false)
    setShowText(false)
  }

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="COUNTRY PROFILES" />
      <article>
        <PageTitle title = { pageTitle }  flag = { Flag } flagAlt = { 'Azerbaijan Flag' }>
          <TitleLink 
            linkTo = { '/country-profiles' }
            seeText = { 'See Map: '}
            icon = {'icon-map-icon'}
            description = { 'Country Profiles' }
          />
          {!showText &&
            <TitleButton 
              showHandler = { showTextHandler }
              seeText = { 'See Text: '}
              icon = {'icon-text-icon'}
              description = { pageTitle }
            />
          }
          {!showTable1 &&
            <TitleButton 
              showHandler = { showTable1Handler }
              seeText = { 'See Table 4: '}
              icon = {'icon-tables-icon'}
              description = { table1Desc }
            />
          }
          {!showTable2 &&
            <TitleButton 
              showHandler = { showTable2Handler }
              seeText = { 'See Table 5: '}
              icon = {'icon-tables-icon'}
              description = { table2Desc }
            />
          }
        </PageTitle>
        {/* TEXT */}
        { showText && 
          <div className="article_content col2">
            <p>In Azerbaijan, there was a low prevalence of thinness amongst adolescent boys and girls aged 15-19.  The prevalence of overweight and obese adolescents in the same age group was higher in boys (19.5 percent) than girls (11.3 percent), using DHS 2006 data.</p>
            <p>Surveys using IOTF reference values reported higher prevalence of overweight and obesity across the age group 10-24 years, showing a prevalence of 24.7 percent in girls and 22.7 percent in boys.</p>
            <p>The prevalence of anaemia in girls aged 15-19 years was 36.5 percent in the 2013 National Nutrition Survey (NNS). IHME data recorded the prevalence of anaemia at 20.7 percent for boys aged 10-24 years.</p>
            <p>The median UIC of school children aged 8-10 years in 2007 was 204 µg/L, which indicates an ‘adequate’ intake.</p>
            <p>A study conducted by Ministry of Health in 2015 in Baku city<Footnote sup={'14'} text={'Preliminary Evaluation of children’s compulsory prophylactic medical examination and study of schoolchildren’s health status, nutrition and physical activity. MOH 2015'}/> found very high rates of between 35 and 40 percent of boys and girls aged 10-15 to be overweight and approximately 10-12 percent obese, whilst 10 percent were underweight. Ten percent of boys and girls in the 16-18 age group were found to be overweight and 10 percent were underweight.</p>
            <p>A 2008 multi-country study<Footnote sup={'15'} text={'Tazhibayev et al 2008'}/> found that salt and wheat flour fortification has been instrumental in significantly improving the micronutrient status of children and women in Azerbaijan since 2000, reducing the prevalence of both iodine deficiency and anaemia. Although, this refers to a pilot project in Azerbaijan and as yet the universal mandatory flour fortification is neither legislated for, nor implemented in the country.</p>
            <h3 className="text-main_blue pb-3">Conclusion</h3>
            <p>The high prevalence of overweight and anaemia in this age group are of concern and overweight appears to be on the rise. Small scale pilots on food fortification alongside raising awareness of the population have proven successful in reducing micronutrient deficiencies and the latest health policies to tackle NCDs appear to be firmly embedding nutrition alongside a focus on school-aged children and adolescents. In addition to a focus on schools and educational establishments, the Action Plan and NCD Strategy include whole-of-society approaches to raise awareness of healthy eating, an equity focus on disadvantaged children, as well as specific provision for a national policy on restricting marketing of unhealthy foods to children. There is also a commitment to knowledge generation through conducting surveys to guide more context-specific recommendations. The legislation and operationalizing a universal flour fortification will greatly contribute in the prevention of micronutrient deficiencies, specifically anaemia. These are all promising approaches to begin to tackle the nutrition challenges in Azerbaijani school-aged children and adolescents.</p>
          </div>
        }
        {/* TABLE */}
        { showTable1 && 
          <div className="article_content">
            <TableTitle
              title={'Table 4'}
              description={ table1Desc }
            />
            <Table04_2 />
          </div>
        }
        {/* TABLE */}
        { showTable2 && 
          <div className="article_content">
            <TableTitle
              title={'Table 5'}
              description={ table2Desc }
            />
            <Table05_2 />
          </div>
        }
      </article>
    </div>
  ) 
}
export default Azerbaijan
