import './App.css';
// IMPORT ROUTER
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// IMPORT BACKGROUND
import Background from './images/back.jpg'
// IMPORT COMPONENTES
import Header from './components/Header'
import Social from './components/Social'
import GoTop from './components/GoTop'
// IMPORT PAGES
// HOME
import Home from './pages/Home'
// PAGES
import Introduction from './pages/introduction/Introduction'
import Methods from './pages/methods/Methods'
// FINDINGS
import Findings from './pages/findings/Findings'
// REGIONAL
import Regional from './pages/regional/Regional'
// COUNTRY
import Country from './pages/country/Country'
import Armenia from './pages/country/Armenia'
import Azerbaijan from './pages/country/Azerbaijan'
import Bosnia from './pages/country/Bosnia'
import Georgia from './pages/country/Georgia'
import Kyrgyz from './pages/country/Kyrgyz'
import Macedonia from './pages/country/Macedonia'
import Montenegro from './pages/country/Montenegro'
import Tajikistan from './pages/country/Tajikistan'
import Turkey from './pages/country/Turkey'
// CONCLUSIONS
import Conclusions from './pages/conclusions/Conclusions'
// APP
function App() {
  
  return (
    <Router>
      <div 
        className="
          w-screen h-screen
          flex flex-col
          bg-no-repeat bg-cover bg-right-top"
        style={{backgroundImage: 'url(' + Background + ')'}}
      >
        <Header />
        <div className={`h-full w-full mt-24 md:mt-20 sm:mt-16`}>
          <Switch>
            {/* HOME */}
            <Route path="/" exact>
              <Home />
            </Route>
            {/* INTRODUCTION */}
            <Route path="/introduction">
              <Introduction />
              <GoTop />
            </Route>
            {/* METHODS */}
            <Route path="/methods">
              <Methods />
              <GoTop />
            </Route>
            {/* FINDINGS */}
            <Route path="/findings">
              <Findings />
              <GoTop />
            </Route>
            {/* REGIONAL FRAMEWORKS & STRATEGIES ON NUTRITION */}
            <Route path="/regional-frameworks-strategies-on-nutrition">
              <Regional />
              <GoTop />
            </Route>
            {/* COUNTRY PROFILES */}
            <Route path="/country-profiles" exact>
              <Country />
              <GoTop />
            </Route>
            <Route path="/country-profiles/armenia">
              <Armenia />
              <GoTop />
            </Route>
            <Route path="/country-profiles/azerbaijan">
              <Azerbaijan />
              <GoTop />
            </Route>
            <Route path="/country-profiles/bosnia-and-erzegovina">
              <Bosnia />
              <GoTop />
            </Route>
            <Route path="/country-profiles/georgia">
              <Georgia />
              <GoTop />
            </Route>
            <Route path="/country-profiles/kyrgyz-republic">
              <Kyrgyz />
              <GoTop />
            </Route>
            <Route path="/country-profiles/republic-of-north-macedonia">
              <Macedonia />
              <GoTop />
            </Route>
            <Route path="/country-profiles/montenegro">
              <Montenegro />
              <GoTop />
            </Route>
            <Route path="/country-profiles/tajikistan">
              <Tajikistan />
              <GoTop />
            </Route>
            <Route path="/country-profiles/turkey">
              <Turkey />
              <GoTop />
            </Route>
            {/* CONCLUSIONS */}
            <Route path="/conclusions">
              <Conclusions />
              <GoTop />
            </Route>
          </Switch>
        </div>
        <Social />
      </div>
    </Router>
  );
}

export default App;
