const Table14_2 = () => {
  return (    
    <div className="table-scroll relative">
      <table className="tableRightBorder table-auto w-full text-left xs:text-xs">
      <thead>
        <tr className="text-white bg-main_blue">
          <th className="bg-main_blue w:52 sm:w-40">Survey</th>
          <th>Age group</th>
          <th>Boys/Girls (non-pregnant)</th>
          <th>Overweight or obese % (95% C.I.)</th>
          <th>Anaemia % (95% C.I.)</th>
        </tr>
      </thead>
      <tbody>
        <tr className="bg-pale_blue">
          <td rowSpan="2" className="text-main_blue bg-pale_blue">COSI-4 2015-2017</td>
          <td>7 y</td>
          <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
          <td className="text-right">38</td>
          <td className="text-right">-</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>7 y</td>
          <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
          <td className="text-right">56</td>
          <td className="text-right">-</td>
        </tr>
        <tr>
          <td className="bg-white">
            <p className="text-main_blue">IHME</p>
          </td>
          <td>10-24 y</td>
          <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
          <td className="text-right">22.9</td>
          <td className="text-right">12.5</td>
        </tr>
        <tr>
          <td>
            <p>2016</p>
            <p>(modelled data from 1990-2016)</p>
            <p>(IOTF) (Azzopardi 2018)</p>
          </td>
          <td>10-24 y</td>
          <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
          <td className="text-right">32.1</td>
          <td className="text-right">15.1</td>
        </tr>
      </tbody>
    </table>
    </div>
  ) 
}
export default Table14_2
