// Component
const Table06 = () => {
  return (
    <div className="table-scroll">
      {/* TABLE */}
      <table className="dTable tableRightBorder tableTextLR
        tableStripes w-full">
        <thead>
          <tr className="bg-header2 text-white">
            <td className="bg-main_blue">Country</td>
            <td>Date of survey</td>
            <td>Age group</td>
            <td>Median UIC (µg/L)</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Albania</td>
            <td>2012</td>
            <td>SAC 6-13</td>
            <td>100</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Armenia</td>
            <td>2017</td>
            <td>SAC 10-12</td>
            <td>242</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Azerbaijan</td>
            <td>2007</td>
            <td>SAC 8-10</td>
            <td>204</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Belarus</td>
            <td>2018</td>
            <td>SAC 9-13</td>
            <td>191</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>{`Bosnia & Herzegovina`}</td>
            <td>2005</td>
            <td>SAC 8-10</td>
            <td>157</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Bulgaria</td>
            <td>2008</td>
            <td>SAC 7-11</td>
            <td>182</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Croatia</td>
            <td>2009</td>
            <td>SAC 7-11</td>
            <td>248</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Georgia</td>
            <td>2017</td>
            <td>SAC 8-10</td>
            <td>298</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Kazakhstan</td>
            <td>2011</td>
            <td>WRA 15-49</td>
            <td>183</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Kyrgyzstan</td>
            <td>2007</td>
            <td>SAC 8-10</td>
            <td>114</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>North Macedonia (FYR)</td>
            <td>2007</td>
            <td>SAC</td>
            <td>241</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Moldova</td>
            <td>2012</td>
            <td>SAC 9-11</td>
            <td>204</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Montenegro</td>
            <td>2007</td>
            <td>SAC 6-11</td>
            <td>174</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Romania</td>
            <td>2004/5</td>
            <td>SAC 6-7</td>
            <td>102</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Serbia</td>
            <td>2007</td>
            <td>SAC 6-14</td>
            <td>195</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Tajikistan</td>
            <td>2009</td>
            <td>WRA 15-49</td>
            <td>108</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Turkey</td>
            <td>2007</td>
            <td>SAC 6-14</td>
            <td>107</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Turkmenistan</td>
            <td>2004</td>
            <td>SAC 8-10</td>
            <td>170</td>
            <td>Adequate</td>
          </tr>
          <tr>
            <td>Ukraine</td>
            <td>2002</td>
            <td>WRA 15-49</td>
            <td>90</td>
            <td>Insufficient</td>
          </tr>
          <tr>
            <td>Uzbekistan</td>
            <td>2005</td>
            <td>SAC 6-12</td>
            <td>141</td>
            <td>Adequate</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
export default Table06 