import { useState } from 'react'
// Import Images
import Figure01_2 from '../../images/fig01_2.jpg'
// Import Components
import PageTitle from '../../components/PageTitle'
import Footnote from '../../components/Footnote'
import TitleButton from '../../components/TitleButton'
import TableTitle from '../../components/TableTitle'
// Component
const Regional = () => {

  const pageTitle = 'Regional frameworks and strategies on nutrition'
  const figureDesc = 'Countries in Europe with a Child and Adolescent Health Plan'

  const [showFigure, setShowFigure] = useState(false);
  const [showText, setShowText] = useState(true);
  function showFigureHandler(e) {
    e.preventDefault();
    setShowFigure(!showFigure)
    setShowText(false)
  }
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowFigure(false)
  }

  return (
    <div className="pages">
      {/* <Breadcrumbs breadcrumb="REGIONAL " /> */}
      <article>
        <PageTitle title = { pageTitle } >
          {!showText &&
            <TitleButton 
              showHandler = { showTextHandler }
              seeText={ 'See Text: '}
              icon={'icon-text-icon'}
              description={ pageTitle }
            />
          }
          {!showFigure &&
            <TitleButton 
              showHandler = { showFigureHandler }
              seeText={ 'See Figure 1: '}
              icon={'icon-map-icon'}
              description={ figureDesc }
            />
          }     
        </PageTitle>
        { showText &&
          <div className="article_content col2">
            <p><strong>The Vienna Declaration on Nutrition and Noncommunicable Diseases in the Context of Health 2020 <Footnote sup={'8'} text={'Vienna Declaration on Nutrition and Noncommunicable Diseases in the Context of Health, WHO Ministerial Conference on Nutrition and Noncommunicable Diseases in the Context of Health, Vienna 4-5 July 2013. WHO, 2013.'}/>,</strong>  launched at the World Health Organization Regional Office for Europe ministerial conference on non-communicable diseases in the context of Health 2020 in July 2013.</p>
            <ol className="list-decimal pl-8">
            <li>To create healthy food and drink environments and encourage physical activity for all population groups:</li>
            <ul className="bullets">
              <li>taking decisive action to reduce food marketing pressure to children;</li>
              <li>facilitate healthier food choices by taking into account socioeconomic inequality in settings such as schools, kindergartens, nurseries, hospitals and workplaces, for example, school fruit/meal schemes;</li>
              <li>implementing effective programmes at various levels of administration, with a focus on communities and the role of local governments, to promote healthy diets, encourage physical activity and prevent childhood obesity.</li> 
            </ul>
            <li>To promote the health gains of a healthy diet throughout the life-course, especially for the most vulnerable by:</li>
            <ul className="bullets">
              <li>investing in nutrition from the first stages of life, starting from before and during pregnancy, protecting, promoting and supporting of adequate breastfeeding, providing appropriate complementary feeding, followed by healthy eating in the family and school environments during childhood and adolescence;</li>
              <li>improving the ability of the citizen to make informed choices, taking into account different population groups (age, gender, education);</li>
              <li>encouraging the use of social media and new techniques to promote healthy food choices and healthier lifestyles.</li>
            </ul>
          </ol>
            <p><strong>Scale Up Nutrition (SUN)</strong></p>
            <p>Two countries in the ECA region, Kyrgyzstan and Tajikistan, have made firm commitments to prioritising nutrition through their engagement in the SUN Movement.</p>
            <div className="noColumnBreak">
              <p><strong>Regional Nutrition Partnership Platform (RNPP)</strong></p>
              <p>Armenia, Azerbaijan, Georgia, Kazakhstan, Kyrgyzstan, Tajikistan Turkmenistan and Uzbekistan participate in the Regional Nutrition Partnership Platform (RNPP) which was launched in July 2018 as the sub-regional inter-country governmental and UN partnership oriented mechanism.</p>
            </div>
            <p>Armenia, Azerbaijan, Georgia, Kazakhstan, Kyrgyzstan, Tajikistan Turkmenistan and Uzbekistan participate in the Regional Nutrition Partnership Platform (RNPP) which was launched in July 2018 as the sub-regional inter-country governmental and UN partnership oriented mechanism.</p>
            <ul className="bullets">
              <li>To raise the profile of nutrition within relevant national and regional strategies, policies, actions plans</li>
              <li>Effective and efficient generation of evidence for policy making in nutrition to minimise inequities</li>
              <li>To enhance the inter-sectoral collaboration in nutrition security</li>
              <li>To train specialized workforce on community nutrition, public health in nutrition and nutrition policy</li>
              <li>To encourage the innovation for nutrition results for children in the region</li>
            </ul>
            <p>A regional strategy, <strong>Investing in children: the European child and adolescent health strategy 2015–2020</strong><Footnote sup={'9'} text={'Investing in Children: The European and Child Health Strategy 2015-2020. WHO 2014.'}/>, developed by the WHO Regional Office for Europe, was adopted by all 53 European member states in 2014.</p>
            <p>The <strong>European Food and Nutrition Action Plan 2015-2020</strong><Footnote sup={'10'} text={'European Food and Nutrition Action Plan 2015-2020. WHO 2014.'}/> seeks integrated, comprehensive action in a range of policy areas through a “whole-of-government, health-in-all-policies approach”. Its objectives are to:</p>
            <ol className="list-decimal pl-8">
              <li>Create healthy food and drink environments.</li>
              <li>Promote the gains of a healthy diet throughout life, especially for the most vulnerable groups.</li>
              <li>Reinforce health systems to promote healthy diets.</li>
              <li>Support surveillance, monitoring, evaluation and research.</li>
              <li>Strengthen governance, alliances and networks to ensure a health-in-all-policies approach. </li>
            </ol>
            <p>A monitoring report<Footnote sup={'11'} text={'Situation of Child and Adolescent Health in Europe. WHO Regional Office for Europe. WHO 2018.'}/> using data collected in 2017 found that three-quarters of the 53 European member states have either adopted or plan to develop a national child and adolescent health strategy.</p>
          </div>
        }
        { showFigure && 
          <div className="article_content flex flex-col">
            <TableTitle
              title={'Figure 1'}
              description={ figureDesc }
            />
            <div className="
              flex flex-wrap justify-between
              border-b-2 border-main_blue
              pt-2
            ">
              <div className="
                text-main_blue font-bold pr-10 pb-1 
                text-lg sm:text-sm xs:text-xs">2017</div>
              <div className="pb-1 text-lg sm:text-sm xs:text-xs">
                
                <div className="flex flex-wrap">
                  <div className="flex flex-nowrap">
                    <div className="w-3 h-3 bg-green ml-2 mr-1 mt-2 sm:mt-1"></div>
                    <div>Yes</div>
                  </div>
                  <div className="flex flex-nowrap">
                    <div className="w-3 h-3 bg-yellow ml-3 mr-1 mt-2 sm:mt-1"></div>
                    <div>In preparation</div>
                  </div>
                  <div className="flex flex-nowrap">
                    <div className="w-3 h-3 bg-red ml-3 mr-1 mt-2 sm:mt-1"></div>
                    <div>No</div>
                  </div>
                  <div className="flex flex-nowrap">
                    <div className="w-3 h-3 bg-gray ml-2 mr-1 mt-2 sm:mt-1"></div>
                    <div>Data not available</div>
                  </div>
                </div>
                  
              </div>
            </div>

            <div className="w-full block">
              <img className="w-full" src={Figure01_2} alt={`Figure 1 - ${figureDesc}`} />
            </div>
            <div className="notes_box block">Source: WHO 2018.</div>

          </div>
        }
      </article>
    </div>
  ) 
}

export default Regional
