const Table09_2 = () => {
  return (    
    <table className="allBorders table-auto w-full text-left border border-main_blue xs:text-xs">
      <thead>
        <tr className="text-white">
            <th className="bg-main_blue">Policy/programme documents</th>
            <th className="bg-header1">Targets/indicators with specific mention of children aged 7-18 years</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Guidelines /Legislation</span></td>
        </tr>
        <tr>
          <td>Healthy and Safe Feeding in Schools (2015)</td>
          <td>Developed by NCDC. Not a regulatory document, provides recommendations on healthy school feeding in Georgian schools. No translation available</td>
        </tr>
        <tr>
          <td className="text-center bg-header2" colspan="2"><span className="text-white">Promotional materials</span></td>
        </tr>
        <tr>
          <td>Funny lessons on Nutrition</td>
          <td>Materials for 2<span className="sup text-xs">nd</span> and 3<span className="sup text-xs">rd</span> grade pupils in secondary schools. Documents not available</td>
        </tr>
      </tbody>
    </table>
  ) 
}
export default Table09_2
