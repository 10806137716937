import React from 'react'
import PropTypes from 'prop-types'
// COMPONENT
const PageTitle = ({ flag, flagAlt, col2, title, children }) => {
	const nChildren = React.Children.count(children);
	return (
		<div className="py-1 flex flex-col">
			<div className="pb-2">
				{flag && 
					<img className="w-14 h-auto pr-4 self-start" src={flag} alt={ flagAlt } />
				}
				<h1 className="text-main_blue text-black text-4xl md:pb-4 xs:text-3xl noColumnBreak font-bold flex-grow">
					{ title }
				</h1>
			</div>
			{nChildren > 0 ? <div className="charts flex flex-col">{ children }</div>: ''}
		</div>
	)
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default PageTitle
