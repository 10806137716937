import { useState } from 'react'
// Import Page Elements
import Table05 from '../../page_elements/tables/Table05'
import Table06 from '../../page_elements/tables/Table06'
// Import Components
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TableTitle from '../../components/TableTitle'
// Component
const Micronutrient = () => {

	const table05Desc = 'Anaemia prevalence (WHO/UNICEF/UNU 2001) for ECA Region *'
  const table06Desc = 'Median Urinary Iodine Concentration (UIC) for countries in ECA region, Global Iodine Scorecard 2019'

  const [showText, setShowText] = useState(true);
	const [showTable1, setShowTable1] = useState(false);
  const [showTable2, setShowTable2] = useState(false);

  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
    setShowTable2(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowText(false)
    setShowTable2(false)
  }
  function showTable2Handler(e) {
    e.preventDefault();
    setShowTable2(!showTable2)
    setShowText(false)
    setShowTable1(false)
  }
	// Return
	return (
    <>
      <PageTitle title="Micronutrient status" col2= { 'col2' }>
        {!showText &&
          <TitleButton 
            showHandler = { showTextHandler }
            seeText={ 'See Text: '}
            icon={'icon-text-icon'}
            description={ 'Micronutrient status' }
          />
        }
        {!showTable1 &&
          <TitleButton 
            showHandler = { showTable1Handler }
            seeText={ 'See Table 5: '}
            icon={'icon-tables-icon'}
            description={ table05Desc }
          />
        }
        {!showTable2 &&
          <TitleButton 
            showHandler = { showTable2Handler }
            seeText={ 'See Table 6: '}
            icon={'icon-tables-icon'}
            description={ table06Desc }
          />
        }
      </PageTitle>
      { showText &&
        <div className="article_content col2">
          <p>While there is a wide range of anaemia prevalence across the region, more than 12 million people aged 10 to 24-years old were estimated to be affected. Among girls aged 10-24 years, the country reporting the lowest prevalence was Ukraine at 7.5% and the highest was Uzbekistan at 43.3%.</p>
          <p>In boys aged 10-24 years there was also a wide range across the Region and the same countries reported the lowest (Ukraine 5.2%) and highest (Uzbekistan 31.4%) levels. In the Balkan countries, the prevalence of anaemia among boys tended to be higher than for girls. Whilst the reasons for this are not very clear one study from Turkey reported that low socio-economic status (SES) boys were more likely to take iron inhibitors (tea) and less likely to eat enhancers (citrus fruits) whilst girls were less likely to take iron inhibitors.</p>
          <p>All countries had a median UIC concentration indicating an ‘adequate’ intake of iodine, except for one (Ukraine) which was insufficient in 2002 but has had no recent survey. </p>
          <p>Vitamin D deficiency was examined by a study in Turkey in 2015 at the end of winter and summer in children and adolescents (3-17 years). The end of winter estimates of deficiency were reported to be very high across both sexes at 80 percent, while “end of summer” levels of the vitamin were significantly lower in girls. Sunlight exposure was significantly higher in boys, which may explain some of the difference between the sexes. The group with sufficient dairy product consumption had significantly higher vitamin D levels in both “end of summer” and “end of winter” samples.</p>
        </div>
      }
      { showTable1 && 
        <div className="article_content">
          <TableTitle
            title={'Table 5'}
            description={ table05Desc }
          />
          <Table05 />
        </div>
      }
      { showTable2 && 
      <div className="article_content">
        <TableTitle
            title={'Table 6'}
            description={ table06Desc }
          />
          <Table06 />
        </div>
      }
    </>
	) 
}
export default Micronutrient
