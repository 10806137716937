const Table02 = () => {
  return (
    <table className="tableTextLR tableRightBorder table-fixed w-full">
      <thead>
        <tr className="bg-main_blue text-white">
          <th>Country</th>
          <th>Full texts inaccessible [n (%)]</th>
          <th>Included studies [n (%)]</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Albania</td>
          <td>2 (3.8)</td>
          <td>1 (0.7)</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Armenia</td>
          <td>0 (0)</td>
          <td>0 (0)</td>
        </tr>
        <tr>
          <td>Azerbaijan</td>
          <td>0 (0)</td>
          <td>2 (1.5)</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Belarus</td>
          <td>3 (5.8)</td>
          <td>0 (0)</td>
        </tr>
        <tr>
          <td>Bosnia & Herzegovina</td>
          <td>5 (9.6)</td>
          <td>4 (3.0)</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Bulgaria</td>
          <td>0 (0)</td>
          <td>3 (2.2)</td>
        </tr>
        <tr>
          <td>Croatia</td>
          <td>2 (3.8)</td>
          <td>9 (6.7)</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Georgia</td>
          <td>5 (9.6)</td>
          <td>1 (0.7)</td>
        </tr>
        <tr>
          <td>Kazakhstan</td>
          <td>3 (5.8)</td>
          <td>4 (3.0)</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Kosovo (UNSCR 1244)</td>
          <td>0 (0)</td>
          <td>1 (0.7)</td>
        </tr>
        <tr>
          <td>Kyrgyzstan</td>
          <td>0 (0)</td>
          <td>0 (0)</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>North Macedonia (TFYROM)</td>
          <td>0 (0)</td>
          <td>4 (3.1)</td>
        </tr>
        <tr>
          <td>Moldova</td>
          <td>0 (0)</td>
          <td>0 (0)</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Montenegro</td>
          <td>0 (0)</td>
          <td>1 (0.7)</td>
        </tr>
        <tr>
          <td>Romania</td>
          <td>13 (25)</td>
          <td>8 (6.0)</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Serbia</td>
          <td>2 (3.8)</td>
          <td>9 (6.7)</td>
        </tr>
        <tr>
          <td>Tajikistan</td>
          <td>2 (3.8)</td>
          <td>0 (0)</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Turkey</td>
          <td>10 (19.2)</td>
          <td>75 (56.0)</td>
        </tr>
        <tr>
          <td>Turkmenistan</td>
          <td>0 (0)</td>
          <td>0 (0)</td>
        </tr>
        <tr className="bg-pale_blue">
          <td>Ukraine</td>
          <td>4 (7.7)</td>
          <td>0 (0)</td>
        </tr>
        <tr>
          <td>Uzbekistan</td>
          <td>0 (0)</td>
          <td>0 (0)</td>
        </tr>
        <tr className="bg-pale2_blue">
          <td>Multi-country</td>
          <td>1 (1.9)</td>
          <td>12 (9.0)</td>
        </tr>
      </tbody>
      <tfoot className="bg-pale3_blue">
        <tr>
          <td className="font-bold">Total</td>
          <td className="font-bold">52 (100)</td>
          <td className="font-bold">134 (100)</td>
        </tr>
      </tfoot>
    </table>
  ) 
}
export default Table02
