const Table04_2 = () => {
  return (
    <>  
      <div className="table-scroll relative">
        <table className="tableRightBorder table-auto w-full text-left xs:text-xs">
          <thead>
            <tr className="text-white bg-main_blue">
              <th className="bg-main_blue">
                <div className="min-w-80">Survey</div>
              </th>
              <th>
                <div className="min-w-80">Age group</div>
              </th>
              <th>
                <div className="min-w-80">Boys/Girls (non-pregnant)</div>
              </th>
              <th>
                <div className="min-w-100">{`Thin % (BMI for age <-2SD)`}</div>
              </th>
              <th>
                <div className="min-w-100">{`HAZ% <- 2SD`}</div>
              </th>
              <th>
                <div className="min-w-100">Overweight or obese % (95% C.I.)</div>
              </th>
              <th>
                <div className="min-w-100">Anaemia % (95% C.I.)</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-pale_blue">
              <td className="text-main_blue bg-pale_blue">DHS 2006*</td>
              <td>15-19 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">1.8 (1.1, 3.1)</td>
              <td className="text-right">6.1 (4.5, 8.3)</td>
              <td className="text-right">11.3 (9.1, 13.9)</td>
              <td className="text-right">29.3 (26.1, 32.6)</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="text-main_blue bg-pale_blue"></td>
              <td>15-19 y</td>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">4.3 (2.0, 8.8)</td>
              <td className="text-right">-</td>
              <td className="text-right">19.5 (14.5, 25.6)</td>
              <td className="text-right">-</td>
            </tr>
            <tr>
              <td className="text-main_blue bg-white">NNS 2013</td>
              <td>15-19 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">36.5 (30.0, 43.4)</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="text-main_blue bg-pale_blue">IHME 2016</td>
              <td>10-24 y</td>
              <td><span className="icon-female text-main_blue pr-1 text-xl"></span>Girls</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">24.7</td>
              <td className="text-right">28.4</td>
            </tr>
            <tr className="bg-pale_blue">
              <td className="bg-pale_blue">
                <p>(modelled data from 1990-2016)</p>
                <p>(IOTF) (Azzopardi 2018)</p>
              </td>
              <td>10-24 y</td>
              <td><span className="icon-male text-main_blue pr-1 text-xl"></span>Boys</td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right">22.7</td>
              <td className="text-right">20.7</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="infoBox">
        <span>*</span>DHS data is as reported in Benedict 2018, reanalysed using BMI-for-age
      </div>
    </>
  ) 
}
export default Table04_2
